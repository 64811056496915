import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

import ReactGA from 'react-ga';

ReactGA.initialize('G-4NEWLFH0S2');

Sentry.init({
    dsn: "https://667b7eea6bec4341a4ad61138079d315@o4504537557630976.ingest.sentry.io/4504537559859200",
    integrations: [new BrowserTracing()],
    
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

import configureStore from './redux/store';

const store = configureStore();

const handleButtonClick = () => {
    // Perform the desired action when the button is clicked
    // For example, close the toast notification
    toast.dismiss(); // Close the toast
};

ReactDOM.render(
    <React.StrictMode>        
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
            style={{zIndex: '999999'}}
        />
        <App store={store} />
    </React.StrictMode>
    , 
    document.getElementById('root')
);

let auxReg = null;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {

        console.log(registration);
        console.log(registration.waiting);

        if (!auxReg && registration && registration.waiting) {
            
            auxReg = registration.waiting;
            toast("Nueva versión disponible! Click para actualizar", {
                autoClose: false,
                closeOnClick: true,
                onClose: () => {
                    if (auxReg) {
                        console.log('SKIP_WAITING');                    
                        auxReg.postMessage({ type: 'SKIP_WAITING' });
                        window.location.reload();
                    }                    
                    auxReg = null;
                }
            });

        }
    },
    onSuccess: (registration) => {

        console.log(registration);

        toast("Nueva versión disponible! Click para actualizar", {
            autoClose: false,
            closeOnClick: true,
            onClose: () => {
                if (auxReg) {
                    console.log('SKIP_WAITING');                    
                    auxReg.postMessage({ type: 'SKIP_WAITING' });
                    window.location.reload();
                }                    
                auxReg = null;
            }
        });
    }
});
