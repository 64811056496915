import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams }             from "react-router-dom";
import { useDispatch, useSelector }           from "react-redux";
import Switch                                 from "react-switch";
import Select                                 from 'react-select';
import { toast }                              from 'react-toastify';

import { createPrescription } from "../redux/actions/prescriptions";
import { apiCall }            from "../redux/api";

import session from "../utils/session";

import convertToDni             from '../middlewares/convertToDni';
import sendSwal                 from '../middlewares/sendSwal'
import sendSwalWithCancel       from '../middlewares/swalWithCancel'
import sendPrescriptionWhatsapp from '../middlewares/sendPrescriptionWhatsapp'
import sendPrescriptionEmail    from '../middlewares/sendPrescriptionEmail'

import { MedicineSelected } from "../components/MedicineSelected";
import { usePrescriotion }  from "../components/PrescriptionProvider";

import Swal from 'sweetalert2'
import '../swal.css';

const NewPrescription = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { prescription } = usePrescriotion();
  
  const params = useParams();

  const [prescriptionData, setPrescriptionData] = useState({
    days: 2,
    hours: 8,
    patient_id: null,
    description: null,
    medical_security: null,
    medical_security_number: null,
    diagnosis: null,
    drugs: null,
    cie10: null,
    psychotropic: null,
    office_id: null,
  });

  const [userId, setUserId]                                         = useState(null);
  const [patientId, setPatientId]                                   = useState(null);
  const [socialSecurity, setSocialSecurity]                         = useState([]);  
  const [inputName, setInputName]                                   = useState("");
  const [inputSurame, setInputSurname]                              = useState("");
  const [inputIdNumber, setInputIdNumber]                           = useState("");
  const [inputMedicalSecurity, setInputMedicalSecurity]             = useState("");
  const [medicalSecurityName, setMedicalSecurityName]               = useState("");
  const [medicalSecurityNumber, setMedicalSecurityNumber]           = useState("");
  const [inputPsychotropic, setInputPsychotropic]                   = useState(0);
  const [optionsMedicines, setOptionsMedicines]                     = useState([]);
  const [optionsCIE10, setOptionsCIE10]                             = useState([]);
  const [ showErrors, setShowErrors ]                               = useState(false);
  const [ searchMed, setSearchMed ]                                 = useState('');
  const [ timeInputMedicine, setTimeInputMedicine ]                 = useState(0);
  const [ searchCIE10, setSearchCIE10 ]                             = useState('');
  const [ timeInputCIE10, setTimeInputCIE10 ]                       = useState(0);
  const [ hasWhatsApp , setHasWhatsApp ]                            = useState(true);  
  const [ existsAddons, setExistsAddons ]                           = useState(true);
  const [ farmacySend, setFarmacySend ]                             = useState(null);

  const previosPage = JSON.parse(localStorage.CurrentUrl);

  const selectInputRefMedicines = useRef();
  const selectInputRefCIE10     = useRef();

  const createPrescriptionResult = useSelector((state) => state.prescriptions.onCreatePrescriptionResult);
  const loading                  = useSelector((state) => state.prescriptions.isLoading);
  const id                       = useSelector((state) => state.prescriptions.id);
  
  const { professional: professionalData, secretary: secretaryData, offices } = session.getUser();
  const userData                                                              = session.getUser();
  
  useEffect(() => {
    if (professionalData) {
      setUserId(professionalData.id)
    } else if (secretaryData) {
      setUserId(secretaryData.id)
    }
    const data = {
      userId,
    };

    for (let j=0 ; j < offices.length ; j++) {

      if (offices[j].addons == undefined) {
        setExistsAddons(false)
      } else if (offices[j].addons.whatsapp_notifications == 1) {
        setHasWhatsApp(true)
      } else if (offices[j].addons.whatsapp_notifications == 0) {
        setHasWhatsApp(false)
      }

    }

    fromPatient();
    getOOSS();

    setTimeout(() => {
      // Modificación de estado tipo objeto en useEffect inicial 
      // sin el setTimeout no tenía efecto
      onlyOneOffice();
    }, 1);

  }, []);

  useEffect(() => {
    console.log('params', params);
    if (params.id) {
      setPatientId(params.id);
    }
  }, [params]);

  useEffect(() => {
    //TODO Nano: revisar el objeto del storage que no está bien construido
    let storage = JSON.parse(localStorage.CurrentUrl)

    if (createPrescriptionResult == 1 && previosPage.section == "") {
    
      let mainText = '';

      if (hasWhatsApp) {
        mainText = "¿Enviar esta receta por Whatsapp al paciente?"
      } else {
        mainText = "¿Enviar esta receta por Email al paciente?"
      }
      
      sendSwalWithCancel(mainText, "Si", "No", () => handleConfirmSend('/prescriptions/', id, hasWhatsApp), () => handleCancelSend('/prescriptions/'), true);
    
    } else if (createPrescriptionResult == 1 && (previosPage.id.section == "prescriptions" || previosPage.id.section == "info" || previosPage.id.section == "events" || previosPage.id.section == "hc" )) { 
      let mainText = "¿Enviar esta receta por Whatsapp al paciente?"

      sendSwalWithCancel(mainText, "Si", "No", () => handleConfirmSend(`/patients/${storage.id.id}/prescriptions`, id, hasWhatsApp), () => handleCancelSend(`/patients/${storage.id.id}/prescriptions`), true);

    } else  if (createPrescriptionResult == 0) {

      let mainText = "No se puedo crear la receta. Por favor, completa todos los campos."
      let textConfirmButtom = "Completar los datos"
      sendSwal(mainText, textConfirmButtom, "texto cancel", keepInCreatePrescription, false);
    
    } else if (!createPrescriptionResult || createPrescriptionResult == null) {
      return
    }

  }, [createPrescriptionResult]);

  const handleChangePsycho = (checked) => {
    setInputPsychotropic(checked);
    setPrescriptionData({
      ...prescriptionData,
      psychotropic: checked ? 1 : 0,
    })
  }

  const handleChange = (e) => {
    setPrescriptionData({
      ...prescriptionData,
      [e.target.name]: e.target.value,
    });  
    
    if (e.target.name == 'office_id') {
      const idx = userData.offices.findIndex((el) => (el.id == e.target.value));
      if (idx >= 0) {
        console.log(idx);
        setFarmacySend({
          email: userData.offices[idx].prescription_data.farmacy_email,
          type: userData.offices[idx].prescription_data.farmacy_send_type ? userData.offices[idx].prescription_data.farmacy_send_type : 'always'
        });
      }
    }
    
  };

  const onlyOneOffice = () => {
    if (userData.offices.length == 1) {
      setPrescriptionData({ ...prescriptionData, office_id: userData.offices[0].id });
      if (userData.offices[0].prescription_data?.farmacy_email) {
        setFarmacySend({
          email: userData.offices[0].prescription_data.farmacy_email,
          type: userData.offices[0].prescription_data.farmacy_send_type ? userData.offices[0].prescription_data.farmacy_send_type : 'always'
        });
      }
      
    }
  }

  const handleClick = async () => {
    // console.log(prescriptionData);
    // Valido datos  
    
    if (!prescriptionData.office_id) {
      setShowErrors(true);
    } else if (prescriptionData.office_id && (prescriptionData.drugs || prescriptionData.description)) {
      
      let auxData = prescriptionData;
      auxData.patient_id = patientId;
      auxData.cie10 = [auxData.cie10];

      auxData.medical_security = medicalSecurityName;
      auxData.medical_security_number = medicalSecurityNumber;

      // Verifico si el consultorio tiene configurado el envío automático
      // a la farmacia
      delete(auxData.farmacy_email);
      delete(auxData.farmacy_send);

      if (prescriptionData.drugs) {
        if (farmacySend.email) {
          if (farmacySend.type == 'ask') {
            const alert = await Swal.fire({
              html: `¿Enviar esta receta a la farmacia?<br /><br />Email: ${farmacySend.email}`,
              showCancelButton: true,
              confirmButtonColor: '#3388FF',
              cancelButtonColor: '#A29EB6',
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'custom-button-si',
                cancelButton: 'custom-button-no',
                htmlContainer:'text'
              },
            });
            if (alert.value === true) {
              auxData.farmacy_email = farmacySend.email;
              auxData.farmacy_send = 1;
            }
          } else {
            auxData.farmacy_email = farmacySend.email;
            auxData.farmacy_send = 1;
          }
        }
      }

      dispatch(createPrescription(auxData));

    } else {
      let mainText = "Debes elegir al menos un medicamento o completar la descripción."
      let textConfirmButtom = "Completar los datos"

      sendSwal(mainText, textConfirmButtom, "texto cancel", keepInCreatePrescription, false);

    }
    
  }

  const handleClose = () => {

    let storage = JSON.parse(localStorage.CurrentUrl)

    if (storage.id == "") {
      navigate('/prescriptions/');
    } else {
      navigate(`/patients/${storage.id.id}/${storage.id.section}`);
    }
    
  };

  const fromPatient = () => {

    setInputName(prescription.patient.name)
    setInputSurname(prescription.patient.surname)
    setInputIdNumber(prescription.patient.id_number)
    if (prescription.patient.medical_security && prescription.patient.medical_security != null) {
      {prescription.patient.medical_security.medical_security_id &&
        setInputMedicalSecurity(prescription.patient.medical_security.medical_security_id)
      }
      {prescription.patient.medical_security.medical_security_name &&
        setMedicalSecurityName(prescription.patient.medical_security.medical_security_name)
      }
      {prescription.patient.medical_security_number
        setMedicalSecurityNumber(prescription.patient.medical_security_number)
      }
    }

    // TODO_G: Ver si lo anterior es necesario o se puede simplificar
  }

  const getOOSS = async () => {
    let auxOOSS = [];
    const auxRes = await apiCall(`/api/medical-securities`, null, null, 'GET');
    if (auxRes?.status == 200) {
      auxOOSS = auxRes.data.data;
    } else {
      toast.error("No se pudo obtener la lista de obras sociales. ERROR: " + auxRes.statusText);
    }
    setSocialSecurity(auxOOSS);
  }
 

  const keepInCreatePrescription = () => {
    console.log("keep")
  }

  const handleInputChangeMedicines = (newValue, actionMeta) => {

    if (timeInputMedicine) clearTimeout(timeInputMedicine);
    setTimeInputMedicine(setTimeout(() => {
      getDataMedicines(newValue);      
    }, 250));

    setSearchMed(newValue);    
  }
  const handleChangeMedicines = (el) => {
    if (!el) { return }

    let auxCurrentMed = (prescriptionData.drugs) ? [ ... prescriptionData.drugs ] : [];
    
    let auxObj = {
      id: el.data.id,
      name: el.label,
      desc: '',
      hours: prescriptionData.hours,
      days: prescriptionData.days,
      data: el.data
    };

    if (!auxCurrentMed) {
      auxCurrentMed = [];
    }
    auxCurrentMed.push(auxObj);

    setPrescriptionData({ ...prescriptionData, drugs: auxCurrentMed });

    setTimeout(() => {
      selectInputRefMedicines.current.clearValue();   
    }, 250);
    
  }

  const getDataMedicines = async (input) => {
    let auxMed = [];
    if (input) {
      const auxRes = await apiCall(`/api/vademecum?q=${input}`, null, null, 'GET');
      if (auxRes?.status == 200) {
        if (auxRes.data.data) {
          auxRes.data.data.map((el) => {
            auxMed.push({
              label: `${el.drug} ${el.power ? ' - ' + el.power : ''} (${el.content} ${el.drug_type ? ' - ' + el.drug_type : ''})\r\n${el.commercial_name} ${el.laboratory ? ' - ' + el.laboratory : ''}`,
              value: el.id,
              data: el
            });
          });
        }
      } else {
        toast.error("No se pudo obtener el listado de medicamentos. ERROR: " + auxRes.statusText);
      }
    }    
    setOptionsMedicines(auxMed);
  }

  const handleMedicineRemove = (id) => {
    if (!prescriptionData.drugs) { return; }

    const idx = prescriptionData.drugs.findIndex((el) => (el.id == id));
    if (idx >= 0) {
        let auxArr = [ ...prescriptionData.drugs ];
        auxArr.splice(idx, 1);
        setPrescriptionData({ ...prescriptionData, drugs: auxArr });
    }
  }

  const handleInputChangeCIE10 = (newValue, actionMeta) => {

    if (timeInputCIE10) clearTimeout(timeInputCIE10);
    setTimeInputCIE10(setTimeout(() => {
      getDataCIE10(newValue);      
    }, 250));

    setSearchCIE10(newValue);    
  }
  const handleChangeCIE10 = (el) => {
    if (!el) { return }

    setPrescriptionData({ ...prescriptionData, cie10: el.data });
    
  }

  const getDataCIE10 = async (input) => {
    let auxCIE10 = [];
    if (input) {
      const auxRes = await apiCall(`/api/cie10?q=${input}`, null, null, 'GET');
      if (auxRes?.status == 200) {
        if (auxRes.data.data) {
          auxRes.data.data.map((el) => {
            auxCIE10.push({
              label: el.text,
              value: el.id,
              data: el
            });
          });
        }
      } else {
        toast.error("No se pudo obtener el listado de diagnósticos. ERROR: " + auxRes.statusText);
      }
    }    
    setOptionsCIE10(auxCIE10);
  }

  const handleConfirmSend = (url, id, hasWhatsApp) => {
    if (hasWhatsApp) {
      sendPrescriptionWhatsapp(id);
    } else {
      sendPrescriptionEmail(id);
    }
    
    navigate(url);

  }

  const handleCancelSend = (url, id, hasWhatsApp) => {
    navigate(url);
  }

  return (
    
    <div className="z-[10000] bg-[#1C124350] absolute top-0 h-screen w-screen font-sans">
      <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white'}} className="mt-[40px] sm:mt-0 overflow-x-auto h-full">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="flex justify-between px-4 sm:px-0">
              <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900"></h3>
              <h3 className="p-4 text-lg text-center font-bold leading-6 text-[#1C1243]">Nueva Receta</h3>
                <h3 onClick={ () => handleClose() } className="mt-2 p-4 text-lg text-center font-medium leading-6 text-gray-900">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                  </svg>              
                </h3>                
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6 overflow-y-auto pb-6" style={{ maxHeight:'calc(100vh - 240px)' }}>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="block text-base font-semibold text-[#1C1243] capitalize">{inputSurame.toLowerCase()}, {inputName.toLowerCase()}</div>
                    <div className="block text-base font-semibold text-gray-400">DNI: {convertToDni(inputIdNumber)}</div>
                    <div>{medicalSecurityName}</div>
                    <div>N° de Afiliado: {medicalSecurityNumber}</div>
                  </div>

                  { userData.offices.length == 1 &&
                    <div className="col-span-6 sm:col-span-3">
                      <div className="text-base font-semibold text-[#1C1243] capitalize">Centro de Salud: {userData.offices[0].name} </div>
                    </div>
                  }

                  { userData.offices.length > 1 && 
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="office_id" className="block text-base font-semibold text-[#1C1243]">
                      <span>Consultorio</span>
                      </label>
                      <select 
                        id="office_id" 
                        name="office_id" 
                        autoComplete="office_id" 
                        className="input-style"
                        required
                        onChange={handleChange}
                      >
                        <option  className="" value="-1">Selecciona el centro de salud</option>
                        {
                            userData && userData.offices.map((el) => (
                                <option key={el.id} value={ el.id }>{ el.name }</option>
                            ))
                        }
                      </select>
                      { 
                        (showErrors && !prescriptionData.office_id) && (
                          <span className="text-red-600 ml-2">Debe seleccionar el consultorio</span>
                        )
                      }
                    </div>
                  }


                  <div className="col-span-6 sm:col-span-3">
                    <div className="flex h-[48px] block w-full items-center w-full">
                        <div className="text-base font-semibold text-[#1C1243]">Receta duplicada (Psicofármacos)</div>
                        <div className="pt-2 ml-4"><Switch name="aaa" width={46} height={27} onChange={ handleChangePsycho } checked={inputPsychotropic} onColor={"#3388FF"} uncheckedIcon/></div>  
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="office_id" className="block text-base font-semibold text-[#1C1243]">
                      <span>Medicamentos</span>
                      </label>

                      <div className="flex justify-between gap-1">
                      <div>
                          <div className="flex mt-4 text-sm h-[40px] block rounded-lg border border-gray-300 bg-white py-1 px-1 focus:outline-none">
                            <div className="px-1 py-1.5 font-semibold text-[#1C1243]">
                              Cant.
                            </div>
                            <select 
                            id="count" 
                            name="count" 
                            className="bg-white"
                            onChange={handleChange}
                            >
                              <option selected value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>     
                            </select>
                          </div>                          
                        </div>
                        
                        <div>
                          <div className="flex mt-4 text-sm h-[40px] block rounded-lg border border-gray-300 bg-white py-1 px-1 focus:outline-none">
                            <div className="px-1 py-1.5 font-semibold text-[#1C1243]">
                              Dur.
                            </div>
                            <select 
                            id="days" 
                            name="days" 
                            className="bg-white"
                            onChange={handleChange}
                            >
                              <option value='1'>1 día</option>
                              <option selected value='2'>2 días</option>
                              <option value='3'>3 días</option>
                              <option value='4'>4 días</option>
                              <option value='5'>5 días</option>
                              <option value='6'>6 días</option>
                              <option value='7'>7 días</option>
                              <option value='8'>8 días</option>
                              <option value='9'>9 días</option>
                              <option value='10'>10 días</option>
                              <option value='11'>11 días</option>
                              <option value='12'>12 días</option>
                              <option value='13'>13 días</option>
                              <option value='14'>14 días</option>
                              <option value='15'>15 días</option>
                              <option value='16'>16 días</option>
                              <option value='17'>17 días</option>
                              <option value='18'>18 días</option>
                              <option value='19'>19 días</option>
                              <option value='20'>20 días</option>
                              <option value='21'>21 días</option>
                              <option value='22'>22 días</option>
                              <option value='23'>23 días</option>
                              <option value='24'>24 días</option>
                              <option value='25'>25 días</option>
                              <option value='26'>26 días</option>
                              <option value='27'>27 días</option>
                              <option value='28'>28 días</option>
                              <option value='29'>29 días</option>
                              <option value='30'>30 días</option>          
                            </select>
                          </div>                          
                        </div>

                        <div>
                          <div className="flex mt-4 text-sm h-[40px] block rounded-lg border border-gray-300 bg-white py-1 px-1 focus:outline-none">
                            <div className="px-1 py-1.5 font-semibold text-[#1C1243]">
                              Cada
                            </div>
                            <select 
                              id="hours" 
                              name="hours" 
                              className="bg-white"
                              onChange={handleChange}
                            >
                              <option value='2'>2 hs.</option>
                              <option value='3'>3 hs.</option>
                              <option value='4'>4 hs.</option>
                              <option value='6'>6 hs.</option>
                              <option selected value='8'>8 hs.</option>
                              <option value='12'>12 hs.</option>
                              <option value='24'>24 hs.</option>
                            </select>
                          </div>                          
                        </div>
                    </div> 
                    <div className="mt-4 w-full">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: 'red',
                            borderRadius: '8px',
                            height: '48px',
                            borderColor: '#d1d5db',
                            fontWeight: '400',
                            fontSize: '16px'
                          }),
                        }}
                        ref={ selectInputRefMedicines }
                        placeholder={`Buscar...`}
                        onInputChange={ handleInputChangeMedicines } 
                        onChange={ handleChangeMedicines }
                        options={optionsMedicines}
                        loadingMessage={`Cargando...`}
                        noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese búsqueda' : (searchMed && timeInputMedicine ? 'Buscando'  : 'Sin resultados para la búsqueda')}
                      />
                    </div>

                    {/* { 
                      (showErrors && !prescriptionData.drugs) && (
                        <span className="text-red-600 ml-2">Debe agregar al menos un medicamento</span>
                      )
                    } */}

                    <div className="flex-col my-2">
                      {
                        prescriptionData.drugs && prescriptionData.drugs.map((el, idx) => (
                          <MedicineSelected key={`med-${idx}-${el.value}`} item={el} idx={idx} remove={ handleMedicineRemove } />
                        ))
                      }
                    </div>
                  </div>

                  

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="description" className="block text-base font-semibold text-[#1C1243]">
                      <span>RP.</span>
                    </label>
                    <input 
                      type="text" 
                      id="description" 
                      className="input-style"
                      placeholder="Escribe aquí"
                      name="description"
                      onChange={handleChange}
                      disabled={false}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="diagnosis" className="block text-base font-semibold text-[#1C1243]">
                      <span>Diagnóstico</span>
                    </label>
                    <input 
                      type="text" 
                      id="diagnosis" 
                      className="input-style"
                      placeholder="Escribe aquí el diagnóstico"
                      name="diagnosis"
                      onChange={handleChange}
                      disabled={false}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="diagnosis" className="block text-base font-semibold text-[#1C1243]">
                      <span>CIE10</span>
                    </label>
                    <div className="mt-4 w-full">
                    <Select
                      styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'red',
                        borderRadius: '8px',
                        height: '48px',
                        borderColor: '#d1d5db',
                        fontWeight: '400',
                        fontSize: '16px'
                      }),
                      }}
                      ref={ selectInputRefCIE10 }
                      placeholder={`Buscar...`}
                      onInputChange={ handleInputChangeCIE10 } 
                      onChange={ handleChangeCIE10 }
                      options={optionsCIE10}
                      loadingMessage={`Cargando...`}
                      menuPlacement={"top"}
                      noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese búsqueda' : (searchCIE10 && timeInputCIE10 ? 'Buscando'  : 'Sin resultados para la búsqueda')}
                    />
                    </div>

                    {/* { 
                      (showErrors && !prescriptionData.cie10) && (
                        <span className="text-red-600 ml-2">Debe seleccionar el diagnóstico CIE10</span>
                      )
                    }  */}
                  </div>

                  

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="" className="block text-base font-semibold text-[#1C1243]">
                      <span>Plantillas</span>
                      </label>
                      <select 
                        id="" 
                        name="" 
                        autoComplete="" 
                        className="mt-4 text-base h-[48px] block w-full rounded-2xl border border-gray-300 bg-white py-2 px-3 focus:outline-none"
                        onChange={handleChange}
                      >
                        <option selected value=''>
                          Laboratorio
                        </option>
                        <option selected value=''>
                          Medicamento
                        </option>
                        <option selected value=''>
                          Imagen
                        </option>
                      </select>
                    </div> */}
                </div>
              </div>
                <div className="fixed bottom-0 w-full px-4 py-3 text-right sm:px-6">
                  <button onClick={handleClick} className="submit-button">{(loading) ? "Guardando..." : 'Generar Receta'}</button>
                </div>
              </div>
          </div>
        </div>
      </div> 
  </div>
    

    

  );
};

export default NewPrescription;