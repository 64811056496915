const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const getFullDate = (addDays = 0, returnYear = false) => {
    const date = new Date();
    date.setDate(date.getDate() + addDays);
    const days = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (returnYear) {
        return (`${days} de ${month}. ${year}`)
    } else return (`${days} de ${month}`)
    

}


export const getDate = (addDays = 0) => {
    let date = new Date();
    date.setDate(date.getDate() + addDays);

    let day = date.getDate();
    if (day < 10) {
        day.toString();
        day = '0' + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month.toString();
        month = '0' + month;
    }
    const year = date.getFullYear();
    return (`${year}-${month}-${day}`);
}


export const convertDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const fullDate = `${year}-${month}-${day}`;
    return fullDate;
}

//2021-01-01

//`${year}-${month}-${day}`