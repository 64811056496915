import { combineReducers } from 'redux';
import patients from './patients';
import register from './register';
import login from './login';
import layout from './layout';
import offices from './offices';
import prescriptions from './prescriptions';

const rootReducer = combineReducers({
	patients,
	register,
	login,
	layout,
	offices,
	prescriptions
});

export default rootReducer;
