import { put, call, takeLatest } from 'redux-saga/effects'; 
import { REGISTER_START, REGISTER_ERROR, REGISTER_COMPLETE} from '../../consts/actionTypes';

import { apiCall } from '../api'


//TODO: REVISAR TODO!!

export function* registerUser({payload}) {
    try {
        const params = new URLSearchParams();
        params.append('name', payload.name);
        params.append('surname', payload.surname);
        params.append('username', payload.username);
        params.append('password', payload.password);
        params.append('speciality_id', payload.speciality);
        params.append('country_id', payload.country);
        const header = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }  
        const results = yield call(apiCall, `/api/auth/register`, params, header, 'POST');
        yield put({ type: REGISTER_COMPLETE, results});
    } catch (error) {
        yield put({ type: REGISTER_ERROR, error});
    }
}

export default function* register() {
    yield takeLatest(REGISTER_START, registerUser);
}