import { OPEN_SIDEBAR_START, SET_CURRENT_PAGE, SET_PAGE_TITLE, SET_BACK_BUTTON, SET_SHOW_HEADER, SHOW_FOOTER_MENU } from '../../consts/actionTypes';

export const setCurrentPage = payload => ({
    type: SET_CURRENT_PAGE,
    payload
});

export const setPageTitle = payload => ({
    type: SET_PAGE_TITLE,
    payload
});

export const openSidebar = payload => ({
    type: OPEN_SIDEBAR_START,
    payload
});

export const setBackButton = payload => ({
    type: SET_BACK_BUTTON,
    payload
});

export const setShowHeader = payload => ({
    type: SET_SHOW_HEADER,
    payload
});

export const setShowFooterMenu = payload => ({
    type: SHOW_FOOTER_MENU,
    payload
});