const convertToDni = (str) => {
    
    let DNI='';

    if (str != null) {

      if (str.length == 8) {
        DNI = str[0]+str[1]+'.'+str[2]+str[3]+str[4]+'.'+str[5]+str[6]+str[7];
      } else if (str.length == 7) {
        DNI = str[0]+'.'+str[1]+str[2]+str[3]+'.'+str[4]+str[5]+str[6];
      } else {
        DNI = str;
      }

    }

    return DNI;
  }

  export default convertToDni;