import React, { useState } from "react";
import { usePersistentState } from "../hooks/usePersistentState";

const AudioContext = React.createContext(null);

export const useAudio = () => {
    return React.useContext(AudioContext);
};

const AudioProvider = ({ children }) => {

    const [ audioRecord, setAudioRecord ] = usePersistentState('audioRecord', null);

    const handleStoreAudio = (audioRecord) => {
        
        setAudioRecord({audioRecord});

    };
  
    const value = {
        handleStoreAudio,
        audioRecord,
        setAudioRecord
    };
  
    return (
      <AudioContext.Provider value={value}>
        {children}
      </AudioContext.Provider>
    );
};

export default AudioProvider;