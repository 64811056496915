import { 
    GET_PATIENTS_START, 
    GET_INFO_PATIENT_START, 
    CLEAN_INFO_PATIENT,
    USE_EDIT_BUTTON, 
    UPDATE_PATIENT_START, 
    CREATE_NEW_PATIENT_START, 
    CLEAN_PATIENTS, 
    GET_HC_START,
    CHANGE_PATIENT_TAGS_START
} from '../../consts/actionTypes';

export const getPatients = payload => ({
    type: GET_PATIENTS_START,
    payload
});

export const createPatient = payload => ({
    type: CREATE_NEW_PATIENT_START,
    payload
});


export const updatePatient = payload => ({
    type: UPDATE_PATIENT_START,
    payload
});


export const getInfoPatient = payload => ({
    type: GET_INFO_PATIENT_START,
    payload
});

export const cleanInfoPatient = payload => ({
    type: CLEAN_INFO_PATIENT,
    payload
});

export const cleanPatients = payload => ({
    type: CLEAN_PATIENTS,
    payload
});


export const useEditButton = payload => ({
    type: USE_EDIT_BUTTON,
    payload
});

export const getPatientHC = payload => ({
    type: GET_HC_START,
    payload
});

export const changePatientTags = payload => ({
    type: CHANGE_PATIENT_TAGS_START,
    payload
});



