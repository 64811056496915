import { useState , useEffect } from "react";

import { useNavigate } from "react-router-dom";

import session from '../utils/session';

import { useAuth } from "../components/AuthProvider";
import congratsImage from '../img-3.png'

import { apiCall } from "../redux/api";

import { toast } from 'react-toastify';

export const OnboardingSix = () => {
    const { token, wizardData } = useAuth();

    const navigate = useNavigate();

    const [isSubmiting , setIsSubmiting] = useState(false);

    useEffect(() => {
        if (!token) {
            window.location.href = '/login';
        }
    }, []);

    const goToPatients= (data) => {
        navigate(`/patients`)
    }

    const handleSubmit = async (data) => {
        
        // console.log(wizardData);

        setIsSubmiting(true);
        
        try {
            const auxRes = await apiCall(`/api/save-wizard`, wizardData, null, 'POST');
            if (auxRes?.status == 200) {
                // toast.success("Registración exitosa.");

                // console.log(auxRes.data.data);
                session.setUser(JSON.stringify(auxRes.data.data));
                
                if (auxRes?.data?.data?.token)
                    session.setToken(auxRes.data.data.token);
    
                goToPatients();
                      
            } else {
                toast.error("No se pudo finalizar la registración. ERROR: " + auxRes.statusText);
            }
        } catch (error) {
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("No se pudo finalizar la registración.");
            }            
        }

        setIsSubmiting(false);
  
    };
    

  return (
    <>
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 font-sans">
        <div className={`w-full max-w-md space-y-8`}>

            <div className="mt-[137px] grid justify-items-center"><img src={congratsImage} /></div>

            <div className="">
                <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-[#3388FF]">¡Felicitaciones!</h2>
                <h4 className="mt-4 text-center text-sm font-semibold tracking-tight text-gray-400">Ya configuraste tu</h4>
                <h4 className="text-center text-sm font-semibold tracking-tight text-gray-400">centro de salud en Meducar</h4>
            </div> 

            <div className="w-full px-[20px] py-2 bg-white">
                <button 
                    onClick={ () => handleSubmit() } 
                    type="submit" 
                    className="w-full justify-center rounded-2xl border border-transparent bg-[#3388FF] py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={`${ isSubmiting ? 'disabled' : '' }`}
                >
                    Finalizar
                </button>
            </div>
        </div>
    </div>
    </>
  )
}