import React from 'react';

export default function CoverLoading({ loading, text }) {
    return (
        <>
            {
                (loading) && (
                    <div className="z-50 h-screen w-screen absolute bg-white opacity-50 pt-[calc(50vh-50px)]">
                        <div className="flex items-center justify-center">
                            <div className="w-16 h-16 border-b-4 border-blue-400 rounded-full animate-spin"></div>
                        </div>
                    </div>
                )
            }
        </>        
    );
}