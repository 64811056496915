import Swal from 'sweetalert2'
import '../swal.css';

const sendSwalWithCancel = (mainText, textConfirmButtom, textCancelButtom, isConfirmed, dismiss, withCancel) => {

    Swal.fire({
        text: mainText,
        showCancelButton: withCancel,
        confirmButtonColor: '#3388FF',
        cancelButtonColor: '#A29EB6',
        confirmButtonText: textConfirmButtom,
        cancelButtonText: textCancelButtom,
        reverseButtons: false,
        customClass: {
            confirmButton: 'custom-button-si',
            cancelButton: 'custom-button-no',
            htmlContainer:'text'
          },
        }).then((result) => {
        if (result.isConfirmed) {
            isConfirmed();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            dismiss();
        }
        })

}

export default sendSwalWithCancel;