import { useState, useEffect } from "react";

export const MedicineSelected = ({item, idx, remove}) => {   
  return (
    <div className="flex items-center py-2 border-b leading-none">
      <div className="pr-2">
        <small>
          {`${item.days} días - c/${item.hours}hs.`}
          <br />
          <b>{item.name}</b>
        </small>
      </div>
      <div onClick={ () => remove(item.id) } className="flex justify-center items-center border border-[#3388FF] border-dashed bg-white rounded-full min-w-[32px] h-[32px]">
        <div className="text-[#3388FF] text-lg font-bold leading-none p-0">x</div>
      </div>       
    </div>
  )
}