const obtainScheduleData = (schedules) => {
    
    let schedulesData = {
        days: [],
        mainHours: [],
        hours: [],
        hasHours: []
    }
    if (schedules != null) {
        for (let i=0 ; i < schedules.length ; i++) {
            schedulesData.days.push(schedules[i].day)
            if (schedules[i].items.length >0) {
                for (let j=0 ; j < schedules[i].items.length ; j++) {
                    
                    schedulesData.hours.push(schedules[i].items)
                    
                    if (schedulesData.mainHours.indexOf(schedules[i].items[j].split(':')[0]) == -1) {
                        schedulesData.mainHours.push(schedules[i].items[j].split(':')[0])
                    }
                }
                schedulesData.hasHours.push(true)
                
            } else {
                schedulesData.hasHours.push(false)
            }
        }
    }
    return schedulesData;
  }
  export default obtainScheduleData;