import { TrendingUpRounded } from '@material-ui/icons';
import { LOGIN_START, LOGIN_ERROR, LOGIN_COMPLETE, LOGOUT, SAVE_USERDATA, UPDATE_TOKEN} from '../../consts/actionTypes';

const initialState = {
    isLoading: false,
    userData: null,
    userToken: null,
    msgError: '',
};

import session from '../../utils/session';




export default function(state = initialState, action) {
    switch(action.type) {
        case LOGIN_START:
            return {...state, isLoading: true, msgError: ''}
            break;
        case LOGIN_ERROR:
            return {...state, isLoading: true, msgError: action.error}
            break;
        case LOGIN_COMPLETE:
            session.setUser(JSON.stringify(action.results.data.data.data));
            session.setToken(action.results.data.data.token);
            return {...state, isLoading: false, userData: action.results.data.data.data, userToken: action.results.data.data.token, msgError: ''}
            break;

        case LOGOUT:
            session.logout();
            return {...state, isLoading: false, userData: null, userToken: null}
            break;

        case SAVE_USERDATA:
            return {...state, userData: action.payload.userData, userToken: action.payload.userToken}
            break;

        case UPDATE_TOKEN:
            return {...state, userToken: action.payload.userToken}
            break;
        default:
            return {...state}
    }
}