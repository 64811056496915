import React, { useEffect, useState, useRef }  from "react";
import { useDispatch, useSelector }    from "react-redux";
import { useNavigate }      from "react-router-dom";
import moment                          from 'moment';
import SectionLoading from '../components/SectionLoading';


import { getEventOptions, newAppointment, resetAppointmentOptions } from "../redux/actions/offices";

import { getDate } from "../utils/dates";
import session     from "../utils/session";
import { useAuth } from "../components/AuthProvider";

const NewAppointment = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataAppointment, handleSaveAppoinmentData }  = useAuth();

  const { professional: professionalData, secretary: secretaryData, offices } = session.getUser();

  const Storage = JSON.parse(localStorage.CurrentUrl);
  
  const [confirmschedule, setConfirmschedule]         = useState(null);
  const [error, setError]                             = useState(false);
  const [appointmentSelected, setAppointmentSelected] = useState(false);

  const options = useSelector((state) => state.offices.eventOptions);
  const schedules = useSelector((state) => state.offices.scheduleOptions);

  const inputProfessionalRef = useRef();
  const inputOfficeRef = useRef();
  const inputConsultationTypeRef = useRef();

  useEffect(() => {
    dispatch(getEventOptions());

    // Limpio objeto en memoria
    let aux = {};
    if (professionalData) {
      aux.professional = professionalData.id;
    }
    if (secretaryData) {
      aux.office = offices[0].id;
    } 
    handleSaveAppoinmentData(aux); 

  }, []);

  useEffect(() => {

    //--- Si existen datos los cargo -->
    if (inputConsultationTypeRef?.current) {
      if (dataAppointment?.consultation) {
        console.log('EXISTEN DATOS dataAppointment -> cargo consultationId', dataAppointment);
        inputConsultationTypeRef.current.value = dataAppointment.consultation;        
        handleSaveAppoinmentData(dataAppointment);
      }
    }    

  }, [inputConsultationTypeRef])

  useEffect(() => {
    if (options?.data) {
      // console.log(options);

      if (professionalData) {
        if (Object.keys(options.data.offices).length == 1) {
          const idx = Object.keys(options.data.offices)[0];
          const value = Object.values(options.data.offices)[0];
          const aux = {
            ...dataAppointment,
            office: idx
          };
          handleSaveAppoinmentData(aux);
          if (inputOfficeRef?.current) {
            inputOfficeRef.current.value = idx;
          }
        } else {
          //--- Si existen datos los cargo -->
          if (inputOfficeRef?.current) {
            if (dataAppointment?.office && (options?.data)) {
              console.log('EXISTEN DATOS dataAppointment -> cargo office', dataAppointment);
              const isOffice = (element) => element == dataAppointment.office;
              const idx1 = Object.keys(options.data.offices).findIndex(isOffice);
              if (idx1 >= 0) {
                const idx = Object.keys(options.data.offices)[idx1];
                inputOfficeRef.current.value = idx;
                handleSaveAppoinmentData(dataAppointment);
              }
            }
          }
          //<-- Si existen datos los cargo ---
        }
      }

      if (secretaryData) {
        if (Object.keys(options.data.consultation_types).length == 1) {
          // const idx = Object.keys(options.data.consultation_types)[0];
          const value = Object.values(options.data.consultation_types)[0];
          const idx = value.prof.id;
          const aux = {
            ...dataAppointment,
            office: offices[0].id,
            professional: idx, 
            professionalName: value.prof.name + ' ' + value.prof.surname
          };
          handleSaveAppoinmentData(aux);
          if (inputProfessionalRef?.current) {
            inputProfessionalRef.current.value = idx;
          }
        } else if (Object.keys(options.data.consultation_types).length == 0) {

          console.log("consultation_types empty")
             
        } else {
          //--- Si existen datos los cargo -->
          if (inputProfessionalRef?.current) {
            if (dataAppointment?.professional && (options?.data)) {
              console.log('EXISTEN DATOS dataAppointment -> cargo profesional', dataAppointment);
              const isProf = (element) => element == dataAppointment.professional;
              const idx1 = Object.keys(options.data.consultation_types).findIndex(isProf);
              if (idx1 >= 0) {
                const idx = Object.keys(options.data.consultation_types)[idx1];
                inputProfessionalRef.current.value = idx;
                handleSaveAppoinmentData(dataAppointment);
              }
            }
          }
          //<-- Si existen datos los cargo ---
        }
      }
      
    }
  }, [options]);

  const handleChange = (e) => {

    if (e.target.name == 'professional') {
      if (e.target.value < 0) {
        const aux = {
          ...dataAppointment,
          professional: null,
          professionalName: null
        };
        handleSaveAppoinmentData(aux); 
      } else {
        const aux = {
          ...dataAppointment,
          professional: e.target.value,
          professionalName: inputProfessionalRef.current.options[inputProfessionalRef.current.options.selectedIndex].text
        };
        handleSaveAppoinmentData(aux); 
      }
    } else {
      const aux = {
        ...dataAppointment,
        [e.target.name]: e.target.value,
      };

      if (e.target.name == 'office') {
        // Limpio posibles horarios ya seleccionados en posteriores pantallas
        if (aux.optionDate) {
          aux.firstDate = null,
          aux.lastDate = null,
          aux.optionDate = null;
        }
      }

      handleSaveAppoinmentData(aux); 
    }
   
  };

  const handleClick = () => {

    if (professionalData) {
      console.log('dataAppointment', dataAppointment);
      if (
        !dataAppointment.consultation ||
        !dataAppointment.office
      ) {
        setError('Debes seleccionar el consultorio y tipo de consulta.');
        return;
      }
    }
    
    if (secretaryData) {
      if (
        !dataAppointment.consultation ||
        !dataAppointment.professional
      ) {
        setError('Debes seleccionar el profesional y tipo de consulta.');
        return;
      }
    }
    
    setError(null);

    let data = Object.assign({}, dataAppointment);   
    // Busco por defecto en la semana próxima (sólo si no existen las fechas en el objeto)
    if (!data.firstDate) {
      data.firstDate = moment().format('Y-MM-DD');
      data.lastDate = moment(data.firstDate).add(7, 'd').format('Y-MM-DD'); // getDate(parseInt(appointmentData.date));
      handleSaveAppoinmentData(data);
    }

    navigate(`/appointments/day-and-hour`);    
  };

  const handleClose = () => {

    if (Storage?.id) {
      if (Storage.id.section == 'hc') {
        navigate(`/patients/${Storage.id.id}/hc`);
      } else if (Storage.id.section == 'prescriptions') {
        navigate(`/patients/${Storage.id.id}/prescriptions`)
      } else if (Storage.id.section == 'info') {
        navigate(`/patients/${Storage.id.id}/info`);
      } else if (Storage.id.section == 'events') {
        navigate(`/patients/${Storage.id.id}/events`);
      } else {
        navigate('/appointments');
      }
    } else {
      navigate('/appointments');
    }   

  }

  if (!options) {
    return (
      <div className="h-full flex justify-center">
        <SectionLoading loading={ true } smaller={ true }/>
      </div>
    )
  }


  return (
    <>
      <div className="pb-5 h-screen px-2">
        <div className="max-h-full max-w-md bg-white overflow-scroll md:max-w-lg font-sans">
          {/* {!schedules && ( */}
          {/* TODO_Nano: ver porque !schedules da false al querer volver desde day selector */}
          {true && (
            <>
              <div className="sticky top-0 bg-white pt-6 pb-2">
                <div className="mt-4 px-2 flex items-start justify-between pb-4">
                  {/* <h3 className="text-lg text-center font-medium text-gray-900"></h3> */}
                  <h3 className="text-xl text-[#1C1243]"><span className="font-bold capitalize">Nuevo Turno</span></h3> 
                  <button className="px-1 pt-2" onClick={() => handleClose()}>                
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                    </svg>
                  </button> 
                </div>
              </div>

              <div className="md:flex justify-center items-center p-2">                
                {
                  professionalData && (
                    <>
                      <div className="pt-4">
                        <label className="text-base font-semibold">Consultorio</label>
                      </div>
                      <select
                        className="select-style"
                        onChange={handleChange}
                        name="office"
                        placeholder="Consultorio"
                        ref={ inputOfficeRef }
                      >
                        <option value="-1">
                          Seleccione el consultorio
                        </option>
                        {Object.entries(options.data.offices).map(
                          ([index, office]) => (
                            <option key={index} value={index}>
                              {office.name}
                            </option>
                          )
                        )}
                      </select>

                      <div className="pt-4">
                        <label className="text-base font-semibold">Tipo de Consulta</label>
                      </div>
                      <select
                        className="select-style"
                        onChange={handleChange}
                        name="consultation"
                        disabled={ !dataAppointment?.office }
                        placeholder="Tipo de Consulta"
                        ref={ inputConsultationTypeRef }
                      >
                        <option value="-1">
                          Seleccione el tipo de consulta
                        </option>
                        {Object.entries(options.data.consultation_types[professionalData.id].ctypes).map(
                          ([index, value]) => (
                            <option key={`ctype_${index}`} value={index}>
                              {value}
                            </option>
                          )
                        )}
                      </select>
                    </>
                  )
                }

                {
                  secretaryData && (
                    <>
                      <div className="pt-4">
                        <label className="text-base font-semibold">Profesional</label>
                      </div>
                      <select
                        className="select-style"
                        onChange={handleChange}
                        name="professional"
                        placeholder="Profesional"
                        ref={ inputProfessionalRef }         
                      >
                        <option value="-1">
                          Seleccione el profesional
                        </option>
                        {options.data.consultation_types && Object.entries(options.data.consultation_types).map(
                            ([index, value]) => (
                              (value.prof) &&
                                <option key={`prof_${index}`} value={value.prof.id}>
                                  {value.prof.name} {value.prof.surname}
                                </option>
                            )                            
                          )
                        }
                      </select>

                      <div className="pt-4">
                        <label className="text-base font-semibold">Tipo de Consulta</label>
                      </div>                      
                      <select
                        className="select-style"
                        onChange={handleChange}
                        name="consultation"
                        disabled={ !dataAppointment?.professional }
                        placeholder="Tipo de Consulta"
                        ref={ inputConsultationTypeRef }
                      >
                        <option value="-1">
                          Seleccione el tipo de consulta
                        </option>
                        {
                          dataAppointment?.professional && (
                            Object.entries(options.data.consultation_types[dataAppointment.professional].ctypes).map(
                              ([index, value]) => (
                                <option key={`ctype_${index}`} value={index}>
                                  {value}
                                </option>
                              )
                            )
                          )
                        }
                        
                      </select>
                    </>
                  )
                }
                
              </div>

              <div className="mt-10 px-2 text-center">
                <button
                  className="submit-button"
                  onClick={() => handleClick()}
                >
                  Ver Disponibilidad
                </button>
              </div>
            </>
          )}
        </div>

        {error ? (
          <div className="px-2 text-red-600 leading-tight">{ error }</div>
        ) : null}

      </div>
    </>
  );
};

export default NewAppointment;
