import {put, call, takeLatest} from 'redux-saga/effects';

import {GET_OFFICES_START, GET_OFFICES_ERROR, GET_OFFICES_COMPLETE, GET_SCHEDULE_ERROR, GET_SCHEDULE_COMPLETE, GET_SCHEDULE_START, GET_EVENT_OPTIONS_COMPLETE, GET_EVENT_OPTIONS_ERROR, GET_EVENT_OPTIONS_START, CREATE_APPOINTMENT_ERROR, CREATE_APPOINTMENT_COMPLETE, CREATE_APPOINTMENT_START, NEW_APPOINTMENT_COMPLETE, NEW_APPOINTMENT_START, GET_TAGS_COMPLETE, GET_TAGS_ERROR, GET_TAGS_START, CHANGE_TAGS_COMPLETE, CHANGE_TAGS_ERROR, CHANGE_TAGS_START} from '../../consts/actionTypes';

import {apiCall} from '../api';

import md5 from 'md5';
// import NewAppointment from '../../pages/NewAppointment';


export function* getOffices({payload}) {
    try {
        const results = yield call(apiCall, `/api/offices`, null, null, 'GET');
        yield put({ type: GET_OFFICES_COMPLETE, results});
    } catch (error) {
        yield put({ type: GET_OFFICES_ERROR, error});
    }
}


export function* getSchedule({payload}) {
    try {
        const results = yield call(apiCall, `/api/events?date_from=${payload.initialDate}&date_to=${payload.lastDate}`,  null, null, 'GET');

        yield put ({ type: GET_SCHEDULE_COMPLETE, results});
    } catch (error) {
        yield put({type: GET_SCHEDULE_ERROR});
    }
}

export function* getEventOptions({payload}) {
    try {
        const results = yield call(apiCall, `/api/newEventOptions`,  null, null, 'GET');
        yield put({ type: GET_EVENT_OPTIONS_COMPLETE, results});
    } catch (error) {
        yield put({type: GET_EVENT_OPTIONS_ERROR});
    }
}

export function* getTags() {
    try {
        const results = yield call(apiCall, `/api/tags`, null, null, 'GET');
        yield put({ type: GET_TAGS_COMPLETE, results});
    } catch (error) {
        yield put({type: GET_TAGS_ERROR, error});
    }
}

export function* changeTags({payload}) {
    try {
        let formData = new FormData;
        formData.append('type', payload.type);
        formData.append('tag_id', payload.tagId);
        formData.append('operation', payload.operation);
        formData.append(`${payload.type}_id`, payload.typeValue);

        const results = yield call(apiCall, `/api/offices/${payload.officeId}/tags`, formData, null, 'POST');

        yield put({ type: CHANGE_TAGS_COMPLETE, results});

    } catch(error) {
        yield put({type: CHANGE_TAGS_ERROR});
    }
}

export function* createAppointment({payload}) {
    try {
        let args = {
            s: payload.firstDate,
            e: payload.lastDate,
            hash: md5('api_' + payload.office + '_events_hash')
        };
        if (payload.professional) {
            args.p = payload.professional;
        }
        
        const results = yield call(apiCall, `/api/offices/${payload.office}/schedule`, args, null, 'POST');
        yield put({ type: CREATE_APPOINTMENT_COMPLETE, results});
    } catch (error) {
        yield put({type: CREATE_APPOINTMENT_ERROR});
    }
}

export function* newAppointment({payload}) {
    try {

        let formData = new FormData();
        formData.append('office_id', payload.office_id);
        formData.append('consultation_type_id', payload.consultation_type_id);
        formData.append('date', payload.date);
        formData.append('patient_id', payload.patient_id);
        if (payload.observations != undefined && payload.observations != null) {
            formData.append('observations', payload.observations);
        }
        if (payload.overschedule != undefined && payload.overschedule != null) {
            formData.append('overschedule', payload.overschedule);
        }
        if (payload.professional_id != undefined && payload.professional_id != null) {
            formData.append('professional_id', payload.professional_id);
        }
        if (!payload.patient_id && payload.patient_data != undefined && payload.patient_data != null) {
            formData.append('patient_name', payload.patient_data?.name);
            formData.append('patient_id_number', payload.patient_data?.dni);
        }

        const results = yield call(apiCall, `/api/events`, formData, null, 'POST');
        yield put({type: NEW_APPOINTMENT_COMPLETE, results});

    } catch(error) {
        yield put({type: NEW_APPOINTMENT_ERROR});
    }
}



export default function* office() {
    yield takeLatest(GET_OFFICES_START, getOffices);
    yield takeLatest(GET_SCHEDULE_START, getSchedule);
    yield takeLatest(GET_EVENT_OPTIONS_START, getEventOptions);
    yield takeLatest(CREATE_APPOINTMENT_START, createAppointment);
    yield takeLatest(NEW_APPOINTMENT_START, newAppointment);
    yield takeLatest(GET_TAGS_START, getTags);
    yield takeLatest(CHANGE_TAGS_START, changeTags);
}
