import { GET_PRESCRIPTIONS_START, 
    GET_PRESCRIPTIONS_ERROR, 
    GET_PRESCRIPTIONS_COMPLETE,
    CREATE_NEW_PRESCRIPTION_COMPLETE,
    CREATE_NEW_PRESCRIPTION_ERROR,
    CREATE_NEW_PRESCRIPTION_START
} from '../../consts/actionTypes';

const initialState = {
    editButton: true,
    totalPrescriptions: 0,
    isLoading: 0
};


export default function(state = initialState, action) {
    switch(action.type) {
        case GET_PRESCRIPTIONS_COMPLETE:
            return {
                ...state, 
                isLoading: false, 
                error: false,  
                prescriptionResults: (state.prescriptionName == action.name && !action.reset) ? [ ...state.prescriptionResults, action.results.data.data.data] : [...[], action.results.data.data.data],  
                current_page: action.results.data.data.current_page, 
                last_page: action.results.data.data.last_page, 
                prescriptionName: state.prescriptionName == action.name ? state.prescriptionName : action.name, 
                totalPrescriptions: action.results.data.data.total
            }
            break;

        case GET_PRESCRIPTIONS_ERROR:
            return {
                ...state, 
                isLoading: false, 
                error: true,
                prescriptionResults: null
            }
            break;
        case GET_PRESCRIPTIONS_START:
            return {
                ...state, 
                isLoading: true, 
                error: false, 
                prescriptionResults: state.prescriptionResults ? state.prescriptionResults : [],
                onCreatePrescriptionResult: null
                
            }
            break;

        case CREATE_NEW_PRESCRIPTION_START:
            return {...state, onCreatePrescriptionResult: null, isLoading: true, error: false}
            break;
        case CREATE_NEW_PRESCRIPTION_ERROR:
            return {...state, onCreatePrescriptionResult: 0, isLoading: false, error: action.error}
            break;
        case CREATE_NEW_PRESCRIPTION_COMPLETE:
            return {...state, onCreatePrescriptionResult: 1, isLoading: false, error: false, prescriptionResults: null, id: action.results.data.data.data.id}
            break;

        default:
            return {...state}
    }
}