import React, {useState, useEffect, useMemo, useRef} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, ErrorMessage } from "formik";
import clsx from 'clsx';
import logo from '../logo-meducar-app.png'

import { useStylesApp } from '../appStyles';

import { useLocation, useParams } from "react-router-dom";

import {login as userLogin} from '../redux/actions/login';

import session from '../utils/session';

import { toast } from 'react-toastify';

import recoverLogo from '../assets/img-4.png';
import changePassLogo from '../assets/img-5.png';
import EmailSVG from '../assets/email.svg';

import CryptoJS from 'crypto-js';
import aes from 'crypto-js/aes';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
import enc from 'crypto-js/enc-utf8';
import Base64 from 'crypto-js/enc-base64';
import hex from 'crypto-js/enc-hex';
import pbkdf2 from 'crypto-js/pbkdf2';
import sha512 from 'crypto-js/sha512';

import { apiCall } from "../redux/api";

import { useAuth } from "../components/AuthProvider";

// http://localhost:3000/recordar-contrasena/eyJjaXBoZXJ0ZXh0IjoiMVpVaW94b29XOGpBdG0xR1hPNHVJMGxIZG9ScXlLSFlEWUo1a2hDaTdjZz0iLCJpdiI6IjE3ZjgyNDllZWJlYTYyNjdmOTgzOGE3YTg5NWI0NjdhIiwic2FsdCI6ImNhZDI1YmM4NDllZDM1MDI2NWU0MzZmZTRkN2ZiNTVhY2E1NDZlMTgxZGU0MTExNWQ4N2RjMWFiNmU1OTFhYWY5M2E4MGNmNzJhZWM0MzUxOTM2ZTY0YWNhNDkxMTI5ZmVmMjViMDk4OTJiNmI3NTg2Mzc4ODNhYWE5Y2E1OTQ4MDFjMmFhNGQ3NjI1ZmE3ZGFhZGFmNDQ0ODgwYmFlNzlhNzIwZTU3YzkyNDBlMGE3YjFiZjYyMzIyYzc2YTg3MzgxYjA1NTUyMTdlOGIxODRmMTE0MDAzMDc2N2VhMzYzMjBlYzlmYjhmZDI5NzYzODkwMTczYTRlYTM3ZGNjNTkzMzQwYmMxYjlmMTE1ZTRlMmZjMGFmNWUyNmI2OTY4ZjE4Mjc5OGRiMjE5MTVlZjhkMDcwNTk0NWY2ZTk1MDhlNGVlYTFlZGY5ODlkNjJlNDdmNjM3OTZjZDIzZTBkOWRmODAzOTYxZmJmZTY4M2YzZDEyZGVmZmY2Y2RhMGNkMDU5YmYyNDdhMmU0NjZkOGZlZWZmNjZlYTkzODExYjAzZmZmZDg4ODM0YzdjZjM3YTc1OGU3NTdlMzNkZTQxYzBmY2Y1OTlhYTI3M2FiZDg5NTkwMmU2NmY2YTY5NWJmMWZiYmNhYzg0YmYwODMxM2ViZmMwNzgzYjM5NzJiMjU5IiwiaXRlcmF0aW9ucyI6OTk5fQ==


export default function Login() {
    const { handleLogin } = useAuth();

    // console.log('handleLogin', handleLogin)

    let params = useParams();
    const location = useLocation();
    
    // let history = useHistory();

    const dispatch = useDispatch();
    // const [values, setValues] = React.useState({
    //     username: '',
    //     username_error: '',
    //     password: '',
    //     password_error: '',
    //     showPassword: false,
    // });
    const [ changePassUserId, setChangePassUserId ] = useState(null);
    
    const user = useSelector(state => state.login.userData);
    const loginLoading = useSelector(state => state.login.isLoading);
    const msgError = useSelector(state => state.login.msgError);

    const [isSubmitting, setIsSubmitting] = useState(false);

    //const user = session.getUser();
    const [openSnackbarOk, setOpenSnackbarOk] = React.useState(false);
    const [ mode, setMode ] = useState('login');
    const [ isError, setIsError ] = useState(false);
    const [ messageError, setMessageError ] = useState("");


    const classes = useStylesApp();
    const pageClasses = useStylesLogin();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight, pageClasses.paperLogin);

    useEffect(() => {
        // dispatch(setShowHeader(false));
        // dispatch(setShowFooterMenu(false));
        console.log("mes",msgError)
    }, []);

    useEffect(() => {
        if (params?.key) {
            
            //Verifico si se trata de un recuperar contraseña
            if (params.key && location.pathname.startsWith('/recordar-contrasena/')) {
                setMode('change');

                let pData = decodeParam(params.key);
                if (pData?.id) {
                    setMode('change');
                    setChangePassUserId(pData.id);
                } else {
                    setMode('change-error');
                }       
            } else {
                window.location.href = '/login';
            }
        }
    }, [params]);

    useEffect(() => {
        console.log('changePassUserId', changePassUserId);
    }, [changePassUserId]);

    useEffect(() => {
        switch (msgError) {
            case 'due_date_plan_pro_test':
                setIsError(true)
                setMessageError("Se venció el período de prueba de tu cuenta. Ingresa a meducar.com desde una pc para continuar con tu cuenta Meducar PRO");
                break;

            case 'due_date_owner_plan_pro_test':
                setIsError(true)
                setMessageError("Hay un problema con el acceso a tu cuenta. Comunícate con el administrador del centro de salud");
                break;

            case 'user_type_incorrect':
                setIsError(true)
                setMessageError("Email y/o contraseña inválidos");
                break;

            case 'plan_free':
                setIsError(true)
                setMessageError("Debes tener una cuenta Meducar PRO para poder utilizar la app");
                break;
            case 'invalid_credentials':
                setIsError(true)
                setMessageError("Email y/o contraseña inválidos");
                break;
            default:
                //setIsError(true);
                console.log("error credenciales")
                break;
        }

        setIsSubmitting(false);

    }, [msgError]);

    
    const resetIsError = () => {
        setIsError(false);
    }

    useEffect(() => {
        console.log(user);
        if(!loginLoading && user) {
            //TODO_G: Avisar con una animación
            // alert('LOGIN OK');
            console.log('user', user)
            // handleLogin();
            // history.push('/');
        }
    }, [loginLoading]);
    
    // const handleChange = prop => event => {
    //     setValues({ ...values, 
    //         [prop]: event.target.value,
    //         username_error: '',
    //         password_error: '', 
    //     });        
    // };

    const decodeParam = (param) => {

        let result = null;

        try {

            var json = JSON.parse(atob(param));
            console.log(json);

            console.log(json.salt);
            var salt = hex.parse(json.salt);
            var iv = hex.parse(json.iv);        
            console.log(salt);
            console.log(iv);

            var encrypted = json.ciphertext;// no need to base64 decode.

            var iterations = parseInt(json.iterations);
            if (iterations <= 0) {
                iterations = 999;
            }

            var key = 'nonce_value';
            var encryptMethodLength = (256/4); // (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
            var hashKey = pbkdf2(key, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

            console.log(hashKey);

            var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});

            console.log(decrypted.toString(CryptoJS.enc.Utf8));

            var auxParam = decrypted.toString(CryptoJS.enc.Utf8);
            auxParam = atob(auxParam);
            console.log(auxParam);

            result = JSON.parse(auxParam);
            console.log(result);

            console.log(result.id);

        } catch (e) {
            console.log('Error');
        }

        return result;
    }
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const formRefRecover = useRef(); 
    const handleClickRecover = async (event) => {
        event.preventDefault();

        console.log(formRefRecover.current.values);
        const header = {
            caller: window.location.host
        }
        const data = {
            username: formRefRecover.current.values.recoveryEmailAddress
        }
    
        try {
            const auxRes = await apiCall(`/api/auth/recover-pass`, data, header, 'POST');
            if (auxRes?.status == 200) {
                toast.success("Se le ha enviado un email con los pasos para recuperar su contraseña.");            
            } else {
                toast.error("No se pudo enviar el email con los pasos para recuperar su contraseña. ERROR: " + auxRes.statusText);
            }   
        } catch (error) {
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("No se pudo enviar el email con los pasos para recuperar su contraseña.");
            }            
        }
    }

    const formRefChangePass = useRef(); 
    const handleClickChangePass = async (event) => {
        event.preventDefault();
    
        console.log(formRefChangePass.current.values);
        
        try {
            const auxRes = await apiCall(`/api/auth/change-pass`, formRefChangePass.current.values, null, 'POST');
            if (auxRes?.status == 200) {
                toast.success("Su contraseña fue actualizada con éxito.");            
            } else {
                toast.error("No se pudo actualizar la contraseña. ERROR: " + auxRes.statusText);
            }
        } catch (error) {
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("No se pudo actualizar la contraseña.");
            }            
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }    
        setOpenSnackbarOk(false);
        //setRedirect(1);
        //props.login();
    };

    const showOk = data => {
        if (data.message == 'token_generated') {
            session.setToken(data.data.token);
            session.setUser(JSON.stringify(data.data.data));
            console.log('handleLogin --> ', data.data.token)
            setOpenSnackbarOk(true);
        } else {

        }
    }

    useEffect(() => {
        if(user) {
            console.log('user', user);
            const token = session.getToken();
            let callWizard = !user.offices;
            callWizard = callWizard || user.offices.length == 0;
            if (token) {
                //console.log('token', token)
                //alert(user.id);
                handleLogin(token, user, callWizard);
            }
            // history.push('/');
        }        
    }, [user]);

    const buttonSubmit = useMemo(() => {
        const loading = (isSubmitting && !isError);
        return(
            <button type="submit" disabled={ loading } className="w-full h-[48px] justify-center rounded-2xl border border-transparent bg-[#3388FF] px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                { loading && <div className="inline-block mr-2"><div className="w-3 h-3 border-l-2 border-b-2 border-white rounded-full animate-spin"></div></div> }
                { loading ? 'Ingresando ... ' : 'Ingresar' }
            </button>
            
        );
    }, [isSubmitting, isError])


    return (
        <>
            {
                mode != 'login' && (
                    <div className="absolute left-0 top-0 h-6 w-6 m-3" onClick={ () => { setMode('login') } }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                )
            }            
            
            {
                mode == 'login' && (
                    <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8 font-sans">
                        <div className={`w-full max-w-md`}>
                            <div className="flex justify-center">
                                <div className="mt-[110px] pb-4"><img src={logo} /></div>
                            </div>                            
                            <div>
                                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1C1243]">Ingresar</h2>
                            </div>
                            <Formik
                                initialValues={{
                                    username: '',
                                    password: ''
                                }}
                                validate={(valores) =>{
                                    let errores = {}
                                    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                                    
                                    if (!valores.username) {
                                        errores.username ='El campo email no puede estar vacío'
                                    } else if (!regexEmail.test(valores.username)) {
                                        errores.username ='Debes escribir un email válido'
                                    }

                                    if (!valores.password) {
                                        errores.password ='La contraseña no puede estar vacía'
                                    } 

                                    return errores;
                                }}
                                onSubmit={(valores, {resetForm})=>{
                                    // resetForm();
                                    resetIsError(); 
                                    dispatch(userLogin({ username: valores.username, password: valores.password}));
                                    setIsSubmitting(true);
                                }} 
                            >
                                {({errors}) => (
                                    <Form className="mt-8" action="#" method="POST">
                                        <input type="hidden" name="remember" value="true" />
                                        
                                        <div className={`${ (isSubmitting && !isError) ? 'pointer-events-none opacity-50' : '' }`} onMouseOver={() => resetIsError()}>
                                            
                                            <div className="my-4 px-[15px]">
                                                <label htmlFor="email-address" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Email</label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                        </svg>
                                                    </span>
                                                    <Field 
                                                        type="text" 
                                                        name="username" 
                                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                                    />
                                                </div>
                                                <ErrorMessage name="username" component={() => (
                                                    <span className="mx-1 mt-1 text-red-600" >{errors.username}</span>
                                                )} />
                                            </div>

                                            <div className="mt-4 px-[15px]">
                                                <label htmlFor="password" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Contraseña</label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                        </svg>
                                                    </span>
                                                    <Field 
                                                        type="password" 
                                                        name="password" 
                                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                                        placeholder="" 
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="password" component={() => (
                                                <span className="mx-1 mt-1 text-red-600" >{errors.password}</span>
                                            )} />                                        
                                        </div>

                                        <div className="flex mt-4 items-center justify-between">
                                            <div className="flex items-center">
                                                {/*
                                                <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label> 
                                                */}
                                            </div>

                                            <div className="">
                                                <a href="#" className="text-base text-[#1C1243] font-semibold pr-[15px]" onClick={ () => { setMode('recovery') } }>¿Olvidaste tu contraseña?</a>
                                            </div>
                                        </div>

                                        <div className="mb-2 text-red-600 text-center">    
                                            { isError ? messageError : <span>&nbsp;</span> }
                                        </div>

                                        <div className="px-[15px]">{ buttonSubmit }</div>

                                        <div className="mt-[10px] flex items-center justify-center">
                                            <div className="text-base font-sans">
                                                <a href="register" className="text-[#A29EB6]">¿Aún no te registraste?<span className="font-semibold text-[#FF5555]"> Registrarse</span></a>
                                            </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                )
            }
            
            {
                mode == 'recovery' && (
                    <div className="font-sans mt-[110px] flex items-center justify-center px-8 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md">
                            <div>                                
                                <div className="flex w-full items-center justify-center">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-32 h-32">
                                        <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                    </svg> */}
                                    <img src={ recoverLogo } className="h-[120px] mb-8" />
                                </div>
                                <h2 className="text-center text-2xl font-bold tracking-tight text-[#1C1243]">Recuperar la contraseña</h2>
                                <p className="text-center mt-2 text-gray-400 px-6">Escribe tu email y te enviaremos ahí los nuevos datos de acceso</p>
                            </div>
                            <Formik
                                innerRef={formRefRecover}
                                initialValues={{
                                    recoveryEmailAddress: ''
                                }}
                                validate={(valores, msgError) =>{
                                    let errores = {}
                                    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                                    
                                    if (!valores.recoveryEmailAddress) {
                                        errores.recoveryEmailAddress ='El campo email no puede estar vacío'
                                    } else if (!regexEmail.test(valores.recoveryEmailAddress)) {
                                        errores.recoveryEmailAddress ='Debes escribir un email válido'
                                    }

                                    if  (msgError) {
                                        errores.recoveryEmailAddress ='Credenciales inválidas'
                                    }

                                    return errores;
                                }}
                            >
                                {({errors}) => (
                                    <form className="mt-8 space-y-6">
                                        <input type="hidden" name="remember" value="true" />
                                        <div className="-space-y-px">
                                            
                                            <div className="my-4">
                                                <label htmlFor="recoveryEmailAddress" className="pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Email</label>
                                                <div className="mt-3 flex">
                                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                        </svg> */}
                                                        <img src={ EmailSVG } />
                                                    </span>
                                                    {/* <input 
                                                        type="text" 
                                                        name="recovery-email-address" 
                                                        id="recovery-email-address" 
                                                        className="block w-full flex-1 rounded-r-xl border border-l-0 border-gray-300 px-3 py-2" 
                                                        placeholder=""
                                                        value={ values.username }
                                                        onChange={ handleChange('username') }
                                                        onBlur={ emailErrors }
                                                    /> */}
                                                    <Field 
                                                        type="text" 
                                                        name="recoveryEmailAddress" 
                                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                                    />
                                                </div>
                                                <ErrorMessage name="recoveryEmailAddress" component={() => (
                                                    <span className="ml-1 text-red-600" >{errors.recoveryEmailAddress}</span>
                                                )} />
                                            </div>
                                            
                                        </div>

                                        <div>
                                            <button onClick={ handleClickRecover } className="w-full h-[48px] justify-center rounded-2xl border border-transparent bg-[#3388FF] px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                {/* 
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                                                    </svg>
                                                </span> 
                                                */}
                                                Recuperar
                                            </button>
                                        </div>
                                    </form>
                                    )}
                            </Formik>
                        </div>
                    </div>
                )
            }

            {
                mode == 'change' && (
                    <div className="font-sans mt-[110px] flex items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md">
                            <div>                                
                                <div className="flex w-full items-center justify-center">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-32 h-32">
                                        <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                    </svg> */}
                                    <img src={ changePassLogo } className="h-[120px] mb-8" />
                                </div>
                                <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-[#1C1243]">Cambiar la contraseña</h2>
                                <p className="text-center mt-2 text-gray-400 px-6">Ingresa tu nueva contraseña</p>
                            </div>
                            <Formik
                                innerRef={formRefChangePass}
                                initialValues={{
                                    id: changePassUserId,
                                    password: '',
                                    password_confirm: ''
                                }}
                                validate={(valores) =>{
                                    let errores = {}
                                    
                                    if (!valores.password) {
                                        errores.password ='La contraseña no puede estar vacía'
                                    } else if (valores.password.length < 6) {
                                        errores.password ='Debes escribir al menos 6 caracteres'
                                    }

                                    if (!valores.password_confirm) {
                                        errores.password_confirm ='La confirmación de contraseña no puede estar vacía'
                                    } else if (valores.password != valores.password_confirm) {
                                        errores.password_confirm ='La contraseña y la confirmación de contraseña deben ser iguales'
                                    }

                                    return errores;
                                }}
                            >
                                {({errors}) => (                            
                                    <form className="mt-8 space-y-6" autoComplete="off">
                                        <Field type="hidden" name="id" />
                                        <div className="-space-y-px">
                                            
                                            <div className="my-4 px-[15px]">
                                                <label htmlFor="password" className="pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Contraseña</label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                        </svg>
                                                    </span>
                                                    {/* <input 
                                                        type="password" 
                                                        name="password" 
                                                        id="password" 
                                                        className="block w-full flex-1 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 py-2" 
                                                        placeholder="" 
                                                        autoComplete="new-password"
                                                        value={ values.password }
                                                        onChange={ handleChange('password') } 
                                                        onBlur={ passwordErrors }   
                                                    /> */}
                                                    <Field 
                                                        type="password" 
                                                        name="password" 
                                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                                        placeholder=""
                                                        autoComplete="off" 
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="password" component={() => (
                                                <span className="ml-1 text-red-600" >{errors.password}</span>
                                            )} />  

                                            <div className="my-4 px-[15px]">
                                                <label htmlFor="password_confirm" className="pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Confirmar contraseña</label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                        </svg>
                                                    </span>
                                                    {/* <input 
                                                        type="password" 
                                                        name="password" 
                                                        id="password" 
                                                        className="block w-full flex-1 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 py-2" 
                                                        placeholder="" 
                                                        autoComplete="new-password"
                                                        value={ values.password }
                                                        onChange={ handleChange('password') } 
                                                        onBlur={ passwordErrors }   
                                                    /> */}
                                                    <Field 
                                                        type="password" 
                                                        name="password_confirm" 
                                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                                        placeholder=""
                                                        autoComplete="new-password" 
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="password_confirm" component={() => (
                                                <span className="ml-1 text-red-600" >{errors.password_confirm}</span>
                                            )} />                                          
                                        </div>

                                        <div className="px-[15px]">
                                            <button onClick={ handleClickChangePass } className="w-full h-[48px] justify-center rounded-2xl border border-transparent bg-[#3388FF] px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                {/* 
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                                    </svg>
                                                </span> 
                                                */}
                                                Aceptar
                                            </button>
                                        </div>
                                    </form>
                            )}
                            </Formik>
                        </div>
                    </div>
                )
            }
        
            



        {/* <Container maxWidth="xs" className={pageClasses.overlay} >


            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                open={ openSnackbarOk }
                autoHideDuration={ 1500 }
                onClose={ handleSnackbarClose } >
                    <MySnackbarContentWrapper
                        onClose={ handleSnackbarClose }
                        variant="success"
                        message="Bienvenido!" />
            </Snackbar>
           

<Grid container spacing={0}>
<Grid item xs={12} container
    direction="row"
    justify="center"
    alignItems="center"
>

    
</Grid>
<Grid item xs={12} container
    direction="row"
    justify="center"
    alignItems="center"
>
    
</Grid>





















<Grid item xs={12}>
    <Paper className={`${fixedHeightPaper} ${pageClasses.test}`}>
        <Typography variant="h5" component="h2" align="center" className={pageClasses.marginBottom}>Iniciar Sesión</Typography>
        
        <form noValidate autoComplete="off">
            
            <FormControl fullWidth variant="outlined">
                <InputLabel error={ (values.username_error) ? true : false } htmlFor="outlined-adornment-username">Correo electrónico</InputLabel>
                <OutlinedInput
                    error={ (values.username_error) ? true : false }
                    id="outlined-adornment-username"
                    value={ values.username }
                    onChange={ handleChange('username') }
                    aria-describedby="outlined-username-helper-text"
                    labelWidth={140}

                />
            </FormControl>
            
            <FormControl fullWidth className={ pageClasses.textField } variant="outlined">
                <InputLabel error={ (values.username_error) ? true : false } htmlFor="outlined-adornment-password">Clave</InputLabel>
                <OutlinedInput
                    error={ (values.password_error) ? true : false }
                    id="outlined-adornment-password"
                    type={ values.showPassword ? 'text' : 'password' }
                    value={ values.password }
                    onChange={ handleChange('password') }
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={40}
                />
            </FormControl>

            <Button variant="contained" className={ pageClasses.sendButton } onClick={handleClickLogin} >Ingresar</Button>

            
        </form>

    </Paper>
</Grid>

</Grid>

            

            
        </Container> */}

        {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        /> */}

        </>
    
    )}

const useStylesLogin = makeStyles(theme => ({
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#096155',
        zIndex: 9999
    },
    paperLogin: {
        marginTop: 'calc(10vh);'
    },
    textField: {
        marginTop: 10,
    },
    sendButton: {
        marginTop: 10,
        float: 'right'
    },
    paperColor: {
        backgroundColor: '#DCF7FC   ;'
    },
    img: {
        marginTop: '5vh',
        margin: 'auto'
    },
    marginBottom: {
        marginBottom: '15px;'
    }
}));