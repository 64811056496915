import { GET_PRESCRIPTIONS_START, CREATE_NEW_PRESCRIPTION_START } from '../../consts/actionTypes';

export const getPrescriptions = payload => ({
    type: GET_PRESCRIPTIONS_START,
    payload
});

export const createPrescription = payload => ({
    type: CREATE_NEW_PRESCRIPTION_START,
    payload
});