import React, {useEffect, useState, useMemo} from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getPatientHC } from "../redux/actions/patients";
import { getEventOptions } from "../redux/actions/offices";
import HcDetail from "./HcDetail";
import SectionLoading from "./SectionLoading";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";

const MedicalHistory = ({professional_id, id}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    
    const actualHC = useSelector(state => state.patients.actualHC);
    const options = useSelector((state) => state.offices.eventOptions);

    // const [hcId, setHcId] = useState(null);
    const [hcCurrent, setHcCurrent] = useState(null);

    const hcById = useMemo(() => {
      const result = {};
      console.log('HC', actualHC);
      if (actualHC != null) {
        actualHC.map(i => result[i.id] = i)
        console.log('HCByID', result);
      }

      return result;
    }, [actualHC])

    useEffect(() => {
        const data = {
            professional_id,
            id
        }
        if (!options) {
            dispatch(getEventOptions(data));
        }
        dispatch(getPatientHC(data));
    }, []);

    const showHcDetail = useMemo(() => {
      const paramHcId = searchParams.get("hcId");
      if (paramHcId && hcById[parseInt(paramHcId)]) {
        console.log('HCCC', hcById[parseInt(paramHcId)])
        setHcCurrent(hcById[parseInt(paramHcId)])
        return true;
      }
      return false;
      // console.log("paramHcId", paramHcId)
      // console.log('hcCurrent?.id', hcCurrent?.id)
      // console.log('show hc', paramHcId === hcCurrent?.id)
      // return parseInt(paramHcId) === parseInt(hcCurrent?.id);
    }, [ searchParams, hcCurrent, hcById ])

    const returnEqual = (officeId) => {
        return options.data.offices[officeId].name
    }


    const catchHc = (data) => {
      setSearchParams(createSearchParams({ hcId: data.id }));
      // setHcCurrent(data);
    }

    const handleCloseHCDetail = () => {
      setHcCurrent(null);
    };

  const showEvolution = (item) => {

    // console.log(item);
    if (!item) return(<></>);

    switch (item.evolution_type) {

      case 'consultation':
        return (
          <>
          <div onClick={ () => catchHc(item) }>
            <div className="m-4 flex items-center justify-between">
              <div className="font-sans tracking-wide text-sm text-black font-bold capitalize">{item.professional?.title} {item.professional?.name.toLowerCase()} {item.professional?.surname.toLowerCase()}</div>
              <div className="">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#A29EB6"
                    viewBox="0 0 20 20"
                    className="inline-block mr-1 w-3 h-3 text-gray-400"
                  >
                    <path
                      strokeWidth="1"                     
                      d="M5.32008 0.25C5.73429 0.25 6.07008 0.585786 6.07008 1V1.24454C6.11151 1.23387 6.15308 1.22352 6.19478 1.21351C8.20425 0.731113 10.2958 0.731113 12.3052 1.21351C12.3469 1.22352 12.3885 1.23387 12.4299 1.24454V1C12.4299 0.585786 12.7657 0.25 13.1799 0.25C13.5941 0.25 13.9299 0.585786 13.9299 1V1.80188C16.0355 2.84323 17.603 4.80891 18.1478 7.18568C18.6174 9.23472 18.6174 11.367 18.1478 13.416C17.4705 16.3708 15.2126 18.6902 12.3052 19.3882C10.2958 19.8706 8.20425 19.8706 6.19478 19.3882C3.28739 18.6902 1.02946 16.3708 0.352229 13.416C-0.11741 11.367 -0.11741 9.23472 0.352229 7.18568C0.896982 4.8089 2.46449 2.84323 4.57008 1.80188V1C4.57008 0.585786 4.90587 0.25 5.32008 0.25ZM4.57021 3.52772C3.78977 4.04042 3.12637 4.72364 2.62944 5.52886H15.8706C15.3736 4.72364 14.7102 4.04042 13.9298 3.52771C13.9223 3.93548 13.5895 4.26375 13.1799 4.26375C12.7657 4.26375 12.4299 3.92796 12.4299 3.51375V2.80616C12.2742 2.75544 12.1158 2.71066 11.9551 2.67207C10.1758 2.24493 8.32424 2.24493 6.54493 2.67207C6.3842 2.71066 6.22583 2.75544 6.07008 2.80616V3.51375C6.07008 3.92796 5.73429 4.26375 5.32008 4.26375C4.91053 4.26375 4.57766 3.93548 4.57021 3.52772ZM16.553 7.02886H1.947C1.89682 7.19009 1.85251 7.35415 1.81432 7.52078C1.39523 9.34928 1.39523 11.2524 1.81432 13.0809C2.36769 15.4953 4.20607 17.3682 6.54493 17.9296C8.32424 18.3568 10.1758 18.3568 11.9551 17.9296C14.2939 17.3682 16.1323 15.4953 16.6857 13.0809C17.1048 11.2524 17.1048 9.34928 16.6857 7.52079C16.6475 7.35415 16.6032 7.19009 16.553 7.02886Z"
                    ></path>
                  </svg>
                <span className="font-sans mt-2 text-sm text-gray-400"><Moment format="DD-MM-YYYY">{item.date_from}</Moment></span>
              </div>
            </div>
            <div className="m-4 flex items-center justify-between">
              <div className="flex font-sans mt-2 text-sm text-gray-400">
                <svg 
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#A29EB6"
                      viewBox="0 0 496 512"
                      className="inline-block mr-2 w-4 h-4 text-sm text-gray-400"
                    >
                    <path
                      strokeWidth="1"
                      d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"
                    ></path>
                </svg>
                {!item.data_struct.name ? 
                <span className="flex">
                  <div>General</div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black" class="w-4 h-4 ml-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                    </svg>
                  </div>
                </span> 
                : item.data_struct.name}
                </div>
              <button onClick={ () => catchHc(item) }><div className="tracking-wide text-lg text-black font-bold">
              <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    width="8" 
                    height="12" 
                    viewBox="0 0 8 12" 
                    fill="none"
                  >
                  <path
                    fill="black"
                    fillRule="evenodd" 
                    clipRule="evenodd"
                    d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z"
                  ></path>
                  </svg>
                
                </div></button>
            </div>
          </div>            
          </>
        )
        break;

        case 'image':
          return (
            <>
            <div onClick={ () => catchHc(item) }>
              <div className="m-4 grid grid-cols-2">
                <div className="font-sans tracking-wide text-sm text-black font-bold capitalize">{item.professional?.title} {item.professional?.name.toLowerCase()} {item.professional?.surname.toLowerCase()}</div><div className="ml-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#A29EB6"
                    viewBox="0 0 20 20"
                    className="inline-block mr-1 w-3 h-3 text-gray-400"
                  >
                    <path
                      strokeWidth="1"                     
                      d="M5.32008 0.25C5.73429 0.25 6.07008 0.585786 6.07008 1V1.24454C6.11151 1.23387 6.15308 1.22352 6.19478 1.21351C8.20425 0.731113 10.2958 0.731113 12.3052 1.21351C12.3469 1.22352 12.3885 1.23387 12.4299 1.24454V1C12.4299 0.585786 12.7657 0.25 13.1799 0.25C13.5941 0.25 13.9299 0.585786 13.9299 1V1.80188C16.0355 2.84323 17.603 4.80891 18.1478 7.18568C18.6174 9.23472 18.6174 11.367 18.1478 13.416C17.4705 16.3708 15.2126 18.6902 12.3052 19.3882C10.2958 19.8706 8.20425 19.8706 6.19478 19.3882C3.28739 18.6902 1.02946 16.3708 0.352229 13.416C-0.11741 11.367 -0.11741 9.23472 0.352229 7.18568C0.896982 4.8089 2.46449 2.84323 4.57008 1.80188V1C4.57008 0.585786 4.90587 0.25 5.32008 0.25ZM4.57021 3.52772C3.78977 4.04042 3.12637 4.72364 2.62944 5.52886H15.8706C15.3736 4.72364 14.7102 4.04042 13.9298 3.52771C13.9223 3.93548 13.5895 4.26375 13.1799 4.26375C12.7657 4.26375 12.4299 3.92796 12.4299 3.51375V2.80616C12.2742 2.75544 12.1158 2.71066 11.9551 2.67207C10.1758 2.24493 8.32424 2.24493 6.54493 2.67207C6.3842 2.71066 6.22583 2.75544 6.07008 2.80616V3.51375C6.07008 3.92796 5.73429 4.26375 5.32008 4.26375C4.91053 4.26375 4.57766 3.93548 4.57021 3.52772ZM16.553 7.02886H1.947C1.89682 7.19009 1.85251 7.35415 1.81432 7.52078C1.39523 9.34928 1.39523 11.2524 1.81432 13.0809C2.36769 15.4953 4.20607 17.3682 6.54493 17.9296C8.32424 18.3568 10.1758 18.3568 11.9551 17.9296C14.2939 17.3682 16.1323 15.4953 16.6857 13.0809C17.1048 11.2524 17.1048 9.34928 16.6857 7.52079C16.6475 7.35415 16.6032 7.19009 16.553 7.02886Z"
                    ></path>
                  </svg>
                  <span className="font-sans mt-2 text-sm text-gray-400"><Moment format="DD-MM-YYYY">{item.created}</Moment></span>
                </div>
              </div>
              <div className="m-4 flex justify-between">
                <div className="font-sans mt-2 text-sm text-gray-400">
                  <svg className="inline-block mr-2 w-4 h-4 text-sm text-gray-300" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.2858 6.78571C10.2858 5.30638 11.485 4.10714 12.9643 4.10714C14.4437 4.10714 15.6429 5.30638 15.6429 6.78571C15.6429 8.26505 14.4437 9.46429 12.9643 9.46429C11.485 9.46429 10.2858 8.26505 10.2858 6.78571ZM12.9643 5.60714C12.3134 5.60714 11.7858 6.13481 11.7858 6.78571C11.7858 7.43662 12.3134 7.96429 12.9643 7.96429C13.6152 7.96429 14.1429 7.43662 14.1429 6.78571C14.1429 6.13481 13.6152 5.60714 12.9643 5.60714Z" fill="#A29EB6"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.6283 2.08306C10.7351 1.63898 8.76488 1.63898 6.87171 2.08306C4.37161 2.6695 2.4195 4.6216 1.83306 7.12171C1.38898 9.01488 1.38898 10.9851 1.83306 12.8783C1.94804 13.3685 2.11552 13.8376 2.32933 14.2795L3.67665 12.9321C5.59455 11.0142 8.70408 11.0142 10.622 12.9321L11.8305 14.1407L12.0046 13.9667C13.4905 12.4808 15.8582 12.4689 17.3596 13.8518C17.4853 13.5384 17.5884 13.2133 17.6669 12.8783C18.111 10.9851 18.111 9.01488 17.6669 7.12171C17.0805 4.62161 15.1284 2.6695 12.6283 2.08306ZM18.2745 15.4029C18.6556 14.7297 18.9453 13.9969 19.1273 13.2208C19.6242 11.1024 19.6242 8.89764 19.1273 6.77916C18.4108 3.72441 16.0256 1.33924 12.9708 0.622696C10.8524 0.125768 8.64763 0.125768 6.52916 0.622696C3.47441 1.33924 1.08924 3.72441 0.372697 6.77915C-0.124232 8.89763 -0.124232 11.1024 0.372697 13.2209C1.08924 16.2756 3.47441 18.6608 6.52916 19.3773C8.64764 19.8742 10.8524 19.8742 12.9708 19.3773C15.1171 18.8739 16.9328 17.5467 18.0715 15.7424C18.1714 15.6466 18.2395 15.5288 18.2745 15.4029ZM16.6056 15.2371L16.563 15.186C15.6705 14.115 14.051 14.0416 13.0652 15.0273L12.8912 15.2014L14.723 17.0332C15.4613 16.5616 16.1008 15.951 16.6056 15.2371ZM13.2932 17.7247L9.56132 13.9928C8.22921 12.6607 6.06943 12.6607 4.73731 13.9928L3.15205 15.5781C4.08014 16.7289 5.37814 17.5666 6.87171 17.9169C8.76488 18.361 10.7351 18.361 12.6283 17.9169C12.8546 17.8639 13.0765 17.7996 13.2932 17.7247Z" fill="#A29EB6"/>
                  </svg>
                  Imágenes
                </div>
                <div>
                  <button className="float-right mt-2" onClick={ () => catchHc(item) }>
                    <div className="tracking-wide text-lg text-black font-bold">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        width="8" 
                        height="12" 
                        viewBox="0 0 8 12" 
                        fill="none"
                      >
                      <path
                        fill="black"
                        fillRule="evenodd" 
                        clipRule="evenodd"
                        d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z"
                      ></path>
                      </svg>
                    </div>
                  </button>
                  <div className="bg-no-repeat bg-cover bg-center w-8 h-8 mr-4 rounded-full float-right border border-slate-300" style={{ backgroundImage: `url(https://cdn.meducar.com/small/${item.image})` }}></div>
                </div>                
              </div>  
            </div>          
            </>
          )
          break;
    }
  }


  return (
    <>
    {actualHC?.length > 0 &&
      actualHC.slice(0).reverse().map((el) => (
        <div key={`evol_${el.evolution_type}_${el.id}`} className="ml-2 mr-2 mt-2 mb-4 max-w-md mx-auto bg-white rounded-xl shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] overflow-hidden md:max-w-2xl">
          { showEvolution(el) }
        </div>
    ))
    }

    {actualHC?.length == 0 &&        
        (<div className="font-sans text-center mt-4 text-sm text-gray-500">No existe un historial medico.</div>)
        
      }

    {!actualHC &&        
        <SectionLoading loading={ true } />
      }

      {
        showHcDetail && (
          <div className="absolute top-0 left-0 z-[1000]">
            <HcDetail data={hcCurrent} handleCloseHCDetail={handleCloseHCDetail}  />
          </div>
        ) 
      }
    </>
  );
};

export default MedicalHistory;
