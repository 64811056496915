import React, { useEffect, Fragment, useState, useContext } from "react";
import { useNavigate, useParams }                           from "react-router-dom";
import { useDispatch, useSelector }                         from "react-redux";

import { getInfoPatient }      from "../redux/actions/patients";
// import { apiCall }             from "../redux/api";
import { prescriptionsResult } from "../redux/selectors";

import session from "../utils/session";

// import { toast } from 'react-toastify';

// import WhatsappSVG from '../assets/whatsappJuan.svg';
import CanvasPDF from "../components/CanvasPDF";


const PdfPrescription = ({ onClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id }   = useParams();

  const error         = useSelector((state) => state.patients.error);
  const loading       = useSelector((state) => state.patients.isLoading);
  // const patient       = useSelector((state) => state.patients.patient);
  const updateOk      = useSelector((state) => state.patients.update);
  const prescriptions = useSelector((state) => prescriptionsResult(state));

  const { professional: professionalData, offices } = session.getUser();
  const professional_id = professionalData.id;

  // const [ sendingWA, setSendingWA ]       = useState(false);
  // const [ sendWA, setSendWA ]             = useState(false);
  // const [ sendingEmail, setSendingEmail ] = useState(false);
  // const [ sendEmail, setSendEmail ]       = useState(false);
  const [ pdfURL, setPdfURL ]             = useState(null); 
  // const [ hasWhatsApp , setHasWhatsApp ]  = useState(true);  
  // const [ existsAddons, setExistsAddons ] = useState(true);

  useEffect(() => {
    if(updateOk) {
      dispatch(getInfoPatient({ id, professional_id }));
    } else if(updateOk == false) {

    }
  }, [loading]);

  useEffect(() => {
     //console.log("offices D", offices)
    if (prescriptions && prescriptions[0].length >= 0) {
      for (let i=0 ; i < prescriptions[0].length ; i++) {
          if (prescriptions[0][i].id == id ) {
              
            if (!prescriptions[0][i].pathfile.startsWith('https')) {
              setPdfURL("https://cdn.meducar.com/"+prescriptions[0][i].pathfile);
            } else {
              setPdfURL(prescriptions[0][i].pathfile);
            }  
          }
      }
    }
  }, [prescriptions]);

  const handleClose = () => {

    let storage = JSON.parse(localStorage.CurrentUrl)

    if (storage.id == "") {
      navigate('/prescriptions/');
    } else {
      navigate(`/patients/${storage.id.id}/${storage.id.section}`);
    }
    
  };

  // const sendPrescriptionWhatsapp = async (e, id) => {

  //   if (hasWhatsApp) {
  //     e.preventDefault();
  //     e.stopPropagation();

  //     if (sendingWA) { return; }

  //     setSendingWA(true);
      
  //     const data = {
  //       id: id,
  //       type: 'whatsapp'
  //     }

  //     const auxRes = await apiCall(`/api/prescriptions/send`, data, null, 'POST');
  //     if (auxRes?.status != 200) {
  //         toast.error("No se pudo enviar la receta. ERROR: " + auxRes.statusText);
  //     }

  //     setSendingWA(false);
  //     setSendWA(true);
  //   } else {
  //     toast.info("Debes tener activo el complemento de Whatsapp para utilizar esta función");
  //   }
  // };

  // const sendPrescriptionEmail = async (e, id) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   if (sendingEmail) { return; }
   
  //   setSendingEmail(true);
    
  //   const data = {
  //     id: id,
  //     type: 'email'
  //   }

  //   const auxRes = await apiCall(`/api/prescriptions/send`, data, null, 'POST');
  //   if (auxRes?.status != 200) {
  //       toast.error("No se pudo enviar la receta. ERROR: " + auxRes.statusText);
  //   }

  //   setSendingEmail(false);
  //   setSendEmail(true);
  // };
 

  return (
    <div className="mt-[0px] pt-[0px] bg-white px-2 overflow-hidden">

      <div className="navbar h-[80px] pb-0 text-[#1C1243] bg-[#FFF]">            
        <div className="flex-1 px-2 mx-2 text-2xl font-bold pt-4">
          Detalle de la receta
        </div>
        <div className="absolute top-12 right-8 text-gray-900" onClick={ () => handleClose() }>
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="#1C1243"/>
          </svg>              
        </div>
      </div>
      <div style={{ height:'calc(100vh - 90px)' }}>
      
        <CanvasPDF url={pdfURL}/>
      
          {/* <iframe
            id=""
            title=""
            width="100%"
            height="100%"
            src={pdfURL}
            frameBorder="0"
          >
          </iframe>  */}
      </div>


    {/* <div className={`py-2 text-center text-xs text-gray-400 truncate w-full`}>
    {
        prescriptions.sendWA
        ?
        <div>
            Enviada por whatsapp hace un instante
        </div>
        :
        prescriptions.sent_whatsapp_at && (
            <div>
            Enviada por whatsapp: <Moment fromNow>{prescriptions.sent_whatsapp_at}</Moment>
            </div>
        )
    }
    {
        prescriptions.sendEmail
        ?
        <div>
            Enviada por email hace un instante
        </div>
        :
        prescriptions.sent_email_at && (
            <div>
            Enviada por email: <Moment fromNow>{prescriptions.sent_email_at}</Moment>
            </div>
        )
    }
    </div>    */}
                
    {/* <div style={{ display: 'flex', justifyContent:'space-evenly' }} className="">
        { existsAddons && 
          <button type="button" onClick={ (e) => sendPrescriptionWhatsapp(e, id) } class={`${hasWhatsApp ? 'bg-green-500' : 'bg-[#A29EB6]'} text-sm text-white px-4 h-[48px] w-[178px] rounded-2xl mb-4 mr-2 badge border-0`}>
          { sendingWA && (<div className="inline-block mr-2"><div className="w-3 h-3 border-l-2 border-b-2 border-white rounded-full animate-spin"></div></div>) }
              <div>
                <img src={ WhatsappSVG } className="inline mr-1 -mt-1" />
              </div>
              <div className="font-semibold">
                Whatsapp
              </div>
          </button> 
        }
        
        <button type="button" onClick={ (e) => sendPrescriptionEmail(e, id) } class="text-sm text-white px-4 h-[48px] w-[143px] rounded-2xl bg-blue-500 mb-4 mr-2 badge border-0">
        { sendingEmail && (<div className="inline-block mr-2"><div className="w-3 h-3 border-l-2 border-b-2 border-white rounded-full animate-spin"></div></div>) }
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
            </div>
            <div className="pl-1 font-semibold">
              Email
            </div>
        </button>
    </div>       */}
    </div>
  );
};

export default PdfPrescription;