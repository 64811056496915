import React, { useEffect, useState, useMemo } from 'react';
import clsx from "clsx";

import { useSelector } from 'react-redux';

import { useStylesApp } from "../appStyles";

import { Link, useLocation } from 'react-router-dom';

import PatientsSVG from '../assets/users.svg';
import TurnosSVG from '../assets/calendar.svg';
import PrescriptionSVG from '../assets/recetas.svg';
import PerfilSVG from '../assets/config.svg';

import session from "../utils/session";

export default function FooterMenu() {
    const location = useLocation();

    // const currentPage = useSelector(state => state.layout.currentPage);
    // const showFooterMenu = useSelector(state => state.layout.showFooterMenu);

    const classes = useStylesApp();

    const user = session.getUser();
    
    const { professional: professionalData, secretary: secretaryData, country_id } = user;

    const sectionsWithFooter = [
        'patients',
        'appointments',
        'prescriptions',
        'settings'
    ];

    const actualPath = useMemo(() => {
        let path = location.pathname;
        if (path.substr(-1) === "/") {
            path = path.slice(0, -1)
        }
        return path
    }, [location])

    const showFooterMenu = useMemo(() => {
        return sectionsWithFooter.some(el => { return ('/' + el) === actualPath});
    }, [actualPath])
 
    return(
        <>
            {
                (showFooterMenu) && (
                    <div className={clsx(classes.footer, 'text-center')}>
                        
                        <div className="bg-[#EBF1FF] h-[2px] mx-6 rounded-full">&nbsp;</div>
                        
                        <ul className="menu bg-white horizontal font-sans">
                            <li>
                                <Link to="/patients" className='flex-col'>
                                <div className='menu-footer-item'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke={`${actualPath == '/patients' ? '#3388FF' : '#666'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> 
                                </div>                                
                                <div className={`mt-1 font-semibold ${actualPath === '/patients'? 'text-[#3388FF]':'text-[#666]'}`}>Pacientes</div>
                                </Link>                   
                            </li>
                            <li>
                                <Link to="/appointments" className='flex-col'>
                                <div className='menu-footer-item'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke={`${actualPath == '/appointments' ? '#3388FF' : '#666'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg> 
                                </div>                                
                                <div className={`mt-1 font-semibold ${actualPath === '/appointments'? 'text-[#3388FF]':'text-[#666]'}`}>Turnos</div>
                                </Link>                
                            </li>
                            {(professionalData && !secretaryData) &&
                            <li>
                                <Link to="/prescriptions" className='flex-col'>
                                <div className='menu-footer-item'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke={`${actualPath == '/prescriptions' ? '#3388FF' : '#666'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
                                </div>                                
                                <div className={`mt-1 font-semibold ${actualPath === '/prescriptions'? 'text-[#3388FF]':'text-[#666]'}`}>Recetas</div>
                                </Link>                   
                            </li>
                            }
                            {/* 
                            <li>
                                <Link to="/patients" className={ clsx(classMenuItems, (currentPage == '/patients') && 'current') }>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className={ clsx(classMenuItemSVG, (currentPage == '/patients') ? 'stroke-current' : 'stroke-black') }
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                                    ></path>
                                </svg>
                                Chats
                                </Link>                
                            </li> 
                            */}
                            <li>
                                <Link to="/settings" className='flex-col'>
                                    <div className='menu-footer-item'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke={`${actualPath == '/settings' ? '#3388FF' : '#666'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                    </div>                                
                                    <div className={`mt-1 font-semibold ${actualPath === '/settings'? 'text-[#3388FF]':'text-[#666]'}`}>Perfil</div>
                                </Link>                  
                            </li>
                        </ul>
                    </div>
                )
            }
        </>    
    );

}