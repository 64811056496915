import React from 'react';

export default function SectionEmpty({ show, title, subtitle, fullscreen = true }) {
    return (
        <>
            {
                show && (
                    <div  className={`${fullscreen ? 'h-screen' : ''} bg-white`}>
                        <div className="font-sans flex flex-col items-center justify-center py-20">
                            {
                                title && (
                                    <div className='text-xl font-bold'>
                                        <h1>{ title }</h1>
                                    </div>
                                )
                            }
                            {
                                subtitle && (
                                    <div className='text-sm text-gray-500'>
                                        <h2>{ subtitle }</h2>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>        
    );
}