import { REGISTER_START, SAVE_ONBOARDING_ONE, SAVE_ONBOARDING_TWO, SAVE_ONBOARDING_THREE, REGISTER_ERROR, REGISTER_COMPLETE, SAVE_USERDATA_REGISTER, UPDATE_TOKEN} from '../../consts/actionTypes';

const initialState = {
    isLoading: false,
    userData: null,
    userToken: null,
    msgError: '',
};

export default function(state = initialState, action) {
    switch(action.type) {
        case REGISTER_START:
            return {...state, isLoading: true, userData: action.payload, msgError: ''}
            break;
        case REGISTER_ERROR:
            return {...state, isLoading: true, msgError: action.error.response.data}
            break;
        case REGISTER_COMPLETE:
            return {...state, isLoading: false, userData: action.results.data.data.data, userToken: action.results.data.data.token, msgError: ''}
            break;

        case SAVE_ONBOARDING_ONE:
            return {...state, userData: action.payload, msgError: ''}
            break;
        case SAVE_ONBOARDING_TWO:
            return {...state, userData: action.payload, msgError: ''}
            break;
        case SAVE_ONBOARDING_THREE:
            return {...state, userData: action.payload, msgError: ''}
            break;
        

        // case SAVE_USERDATA_REGISTER:
        //     return {...state, userData: action.payload, userToken: action.payload.userToken}
        //     break;

        // case UPDATE_TOKEN:
        //     return {...state, userToken: action.payload.userToken}
        //     break;
        default:
            return {...state}
    }
}