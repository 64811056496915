import { useEffect, useState, useRef } from "react";
import RecordRTC from 'recordrtc';

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [audioMimeType, setAudioMimeType] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }
    // Manage recorder state.
    if (isRecording) {
      recorder.startRecording();
    } else {
      // recorder.stop();
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        
        console.log("Formato generado: ", blob.type);  // Confirma el formato (WebM o WAV)
        setAudioMimeType(blob.type);

        // Verificar el tipo de archivo generado
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        
        setIsRecording(false);
      });
    }
  }, [recorder, isRecording]);

  useEffect(() => {
    console.log('audioURL', audioURL);
    if (audioURL) {
      const audioElement = new Audio(audioURL);
      audioElement.addEventListener("loadedmetadata", () => {
        setDuration(audioElement.duration);
      });
    }
  }, [audioURL])


  const startRecording = () => {
    setIsRecording(true);
  };
  const stopRecording = () => {
    setIsRecording(false);
  };
  const audioData = async () => {
    return await audioUrlToBase64(audioURL, audioMimeType);
  }
  return [audioURL, setAudioURL, isRecording, startRecording, stopRecording, audioData, duration, setDuration];
};

async function requestRecorder() {

  var mimeType = 'audio/webm'; // WebM es más compatible en Android, pero puede ser forzado en iOS

  // Detectar el navegador para tomar decisiones según la plataforma
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
      // Safari prefiere WAV o M4A, pero puede forzar WebM
      mimeType = 'audio/wav'; // WAV es compatible en iOS
  }

  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new RecordRTC(stream, {
    type: 'audio',
    mimeType: mimeType,
    bitsPerSecond: 128000  // Calidad del audio
  });
}

// Function to convert audio URL to Base64
async function audioUrlToBase64(audioUrl, mimeType) {
  try {
    // Fetch the audio file
    const response = await fetch(audioUrl);
    const blob = await response.blob();

    // Read the blob as data URL
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    // Handle FileReader load event to convert to Base64
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const res = {
          base64: reader.result.split(',')[1],
          mimeType: mimeType
        };
        resolve(res);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export default useRecorder;