import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
// import { setShowFooterMenu } from '../redux/actions/layout';

import { getOffices } from "../redux/actions/offices";
import { cleanInfoPatient } from '../redux/actions/patients';

import { useNavigate } from "react-router-dom";

import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import moment from 'moment';
import "moment/locale/es";
import "../datePicker.css"

import { toast } from 'react-toastify';

import { apiCall } from "../redux/api";

import session from "../utils/session";

import ar from '../flags/ar.png'
import pe from '../flags/pe.png'
import bo from '../flags/bo.png'
import co from '../flags/co.png'
import ec from '../flags/ec.png'
import py from '../flags/py.png'
import uy from '../flags/uy.png'
import ve from '../flags/ve.png'
import cl from '../flags/cl.png'
import mx from '../flags/mx.png'
import removeAreaCode from "../middlewares/removeAreaCode";
import SectionLoading from "./SectionLoading";

const PatientForm = ({
  handleChange,
  handleChangePhone,
  editMode,
  handleCloseEdit,
  validateForm,
  // selectedDate,
  handleDateChange,
  requiredFields,
  data,
  button,
  title,
  disabledButton,
  send,
  cleanPhone,
  setCleanPhone,
  isSubmitting,
  externalLoading
}) => {

  const dispatch  = useDispatch();
  const navigate  = useNavigate();
  const officeRef = useRef({});

  let Storage = null;
  if (localStorage?.CurrentUrl) {
    Storage = JSON.parse(localStorage.CurrentUrl);
  }

  // const editButton = useSelector(state => state.patients.editButton);
  // const offices = useSelector((state) => state.offices.offices);
  const loading = useSelector((state) => state.patients.isLoading);

  const [ valueDate, onChangeDate ]                 = useState();
  const [ medicalSecurities, setMedicalSecurities ] = useState([]);
  const [ ooSSClass, setOoSSClass ]                 = useState('');
  const [country, setCountry]                       = useState({});

  const { professional: professionalData, secretary: secretaryData, offices, country_id } = session.getUser();

  console.log('data', data);
  // console.log(offices);

  const minDate = new Date('1900-01-01');
  const maxDate = new Date();

  const codes = [
    {
      idx: 6,
      name: "Argentina",
      src: ar,
      code: 549
    }, 
    {
      idx: 75,
      name: "Peru",
      src: pe,
      code: 519
    },
    {
      idx: 12,
      name: "Bolivia",
      src: bo,
      code: 5919
    }, 
    {
      idx: 19,
      name: "Colombia",
      src: co,
      code: 579
    }, 
    {
      idx: 25,
      name: "Ecuador",
      src: ec,
      code: 5939
    },
    {
      idx: 74,
      name: "Paraguay",
      src: py,
      code: 5959
    },
    {
      idx: 96,
      name: "Uruguay",
      src: uy,
      code: 5989
    }, 
    {
      idx: 97,
      name: "Venezuela",
      src: ve,
      code: 5989
    }, 
    {
      idx: 18,
      name: "Chile",
      src: cl,
      code: 569
    },
    {
      idx: 60,
      name: "Mexico",
      src: mx,
      code: 521
    },       
  ]
  
  useEffect(() => {
    console.log('valueDate', valueDate);
    if (valueDate) {
      const d = moment(valueDate, "YYYY-MM-DD hh-mm-ss").format("YYYY-MM-DD");

      const isValid = moment(d, "YYYY-MM-DD", true).isValid();      
      handleDateChange(d, isValid);
    }
  }, [valueDate]);

  useEffect(() => {

    if (!offices) {
      dispatch(getOffices());
    } else {
      if (!editMode) {      
        if (offices.length == 1) {
          getOOSS(offices[0].id);
        }
      } else {
        if (data.offices.length > 0) {
          getOOSS(data.offices[0].id);
        }
      }
    }
    
    // dispatch(setShowFooterMenu(false));
    setMedicalSecurities([]);
    getCountry();

    if (editMode) {
      onChangeDate(data.born_date);
      setCleanPhone(data.telephone);
    }

    // console.log('secretaryData', secretaryData);
    // console.log('offices', offices);

  }, []);

  useEffect(() => {
    if (country && data.telephone) {
      setCleanPhone(removeAreaCode(data.telephone, String(country.code)));
    }

  }, [country]);

  useEffect(() => {
    if (medicalSecurities) {
      setOoSSClass('');
    } else {
      setOoSSClass('text-[#1C124350]');
    }

  }, [medicalSecurities]);

  useEffect(() => {

    if (editMode) return;
    
    if (officeRef?.current?.value > 0) {

      setOoSSClass('');

      if (secretaryData) {
        getOOSS(offices[0].id);
      } else {
        getOOSS(officeRef.current.value);
      }
  
    } else {
      setOoSSClass('text-[#1C124350]');
    }    

  }, [officeRef?.current?.value]);

  useEffect(() => {

    if (editMode) return;

    if (officeRef && officeRef?.current && data.office_id != null && data.event_id != null) {
      officeRef.current.value = data.office_id;
      officeRef.current.disabled = true;
    }
  }, [data])


  const handleClose = () => {
    //setOpen(false);
    //dispatch(setShowHeader(true));
    // dispatch(setShowFooterMenu(true));
    //dispatch(cleanInfoPatient())
    if (editMode) {
      handleCloseEdit();
    } else {
      if (Storage?.fromAppointmet) {
        navigate("/appointments")      
      } else {
        navigate("/patients")
      } 
    }
    
  };

  const getOOSS = async (officeId) => {
    const auxRes = await apiCall(`/api/offices/${officeId}/medical-securities`, null, null, 'GET');

    if (auxRes?.status == 200) {
      
      if (officeId == 1) {
        setMedicalSecurities([]);
      } else {
        setMedicalSecurities(auxRes.data.data);
      }
      
    } else {
      toast.error("No se pudo obtener el listado de obras sociales. ERROR: " + auxRes.statusText);
    }
  }

  const getCountry = () => {
    for (let i = 0 ; i < codes.length ; i++) {
      if (codes[i].idx == country_id) {
        setCountry({code: codes[i].code, src: codes[i].src})
      }
    }
    
  }

  if (externalLoading) {
    return <SectionLoading loading={ true } />
  }
 
  return (
    <>
      <div className="z-[10000] bg-[#1C124350] absolute top-0 h-screen w-screen font-sans">
        <div className="rounded-t-3xl bg-white mt-[40px] h-full">            
            <div className="flex justify-between px-4 sm:px-0">
              <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900"></h3>
              <h3 className="p-4 text-lg text-center font-bold leading-6 text-[#1C1243]">{title}</h3>
              <button onClick={handleClose}>                
                <h3 className="mt-2 p-4 text-lg text-center font-medium leading-6 text-gray-900">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                  </svg>              
                </h3>
              </button> 
            </div>

            <div className="mt-5">
                <div className="">
                  <div className="bg-white px-4">
                  <form action="#" method="POST" autoComplete="off">
                    {/* <div className="grid grid-cols-6 gap-6 overflow-y-auto pb-6" style={{ maxHeight:'calc(100vh - 245px)' }}> */}
                    <div className="overflow-y-auto pb-2" style={{ maxHeight:'calc(100vh - 220px)' }}>
                      
                      <div className="col-span-6 sm:col-span-3 mb-6">
                        <label htmlFor="last-name" className={`block text-base font-semibold ${requiredFields.surname && send ? 'text-red-600' : 'text-[#1C1243]' }`}>
                          <span>Apellido
                            {requiredFields.surname && (
                              <span className="ml-1 text-red-600">*</span>
                            )}
                          </span>
                        </label>
                        <input 
                          type="text" 
                          id="surname" 
                          className="input-style"
                          required
                          placeholder="apellido"
                          name="surname"
                          value={data.surname}
                          onChange={(e) => handleChange(e)}
                          disabled={false}

                        />
                      </div>
                      
                      
                      <div className="col-span-6 sm:col-span-3 mb-6">
                        <label htmlFor="name" className={`block text-base font-semibold ${requiredFields.name && send ? 'text-red-600' : 'text-[#1C1243]' }`}>
                          <span>Nombres
                            {requiredFields.name && (
                              <span className="ml-1 text-red-600">*</span>
                            )}
                          </span>
                        </label>
                        <input 
                          type="text" 
                          id="name" 
                          className="input-style"
                          required
                          placeholder="nombres"
                          name="name"
                          value={data.name}
                          onChange={(e) => handleChange(e)}
                          disabled={false}
                        />                          
                      </div>



                      <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="last-name" className="block text-base font-semibold text-[#1C1243]">
                        <span>DNI</span>
                      </label>
                      <input 
                        type="number" 
                        id="id_number" 
                        className="input-style"
                        required
                        placeholder="DNI"
                        name="id_number"
                        value={data.id_number}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="current_medication" className={`block text-base font-semibold ${requiredFields.born_date && send ? 'text-red-600' : 'text-[#1C1243]' }`}>
                        <span>Fecha de Nacimiento</span>
                        {requiredFields.born_date && (
                              <span className="ml-1 text-red-600">*</span>
                            )}
                      </label>
                      
                      <div className="mt-2">
                        <DatePicker 
                          onChange={onChangeDate} 
                          value={valueDate} 
                          locale="es-AR"
                          format="dd/MM/yyyy"
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="sex" className="block text-base font-semibold text-[#1C1243]">
                      Sexo
                      </label>
                      <select 
                        id="sex" 
                        name="sex"
                        className="input-style"
                        required
                        onChange={(e) => handleChange(e)}
                        //ref={el => {officeRef.current = el}}
                      >
                      {editMode ? <option  className="" value="-1">{data.sex == "F" ? "Femenino" : data.sex == "M" ? "Masculino" : ""}</option> 
                      : <option selected value=''>Elige el sexo</option> }
                        {/* <option selected value=''>Elige el sexo</option> */}
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                      </select>
                    </div>

                    {/* <legend className="block text-base font-semibold text-[#1C1243]">Sexo</legend>
                      <div className="mt-2 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="sex"
                          className="text-base block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          type="radio"
                          name="sex"
                          value="m"
                          checked={data.sex === "m"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="sex" className="ml-3 block text-base font-medium text-gray-900">
                          Masculino
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="sex"
                          className="text-base block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          type="radio"
                          name="sex"
                          value="f"
                          checked={data.sex === "f"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="sex" className="ml-3 block text-base font-medium text-gray-900">
                          Femenino
                        </label>
                      </div>
                      </div> */}

                    {
                      (offices.length > 1 && !editMode) &&
                        <div className="col-span-6 sm:col-span-3 mb-6">
                          <label htmlFor="office_id" className={`block text-base font-semibold ${requiredFields.office_id && send ? 'text-red-600' : 'text-[#1C1243]' }`}>
                          <span>Consultorio
                              {requiredFields.office_id && (
                                <span className="ml-1 text-red-600">*</span>
                              )}
                            </span>
                          </label>
                          <select 
                            id="office_id" 
                            name="office_id"
                            className="input-style"
                            required
                            onChange={(e) => handleChange(e)}
                            //ref={el => {officeRef.current = el}}
                            ref={officeRef}
                          >
                            {/* {editMode ?
                              
                              <option selected value='1'>
                              {data.offices[0].name}
                              </option>
                              
                            :
                              <option selected value='1'>
                              Consultorio
                              </option>
                            } */}

                            {/* Cuando esté OK tiro a api de updatePatient hay que descomentar options de arriba y borrar abajo                */}
                              <option selected value='-1'>
                              Consultorio
                              </option>
          
                            {offices 
                              ? offices.map((office, index) => (
                              <option key={index} value={office.id}>
                                {office.name}
                              </option>
                            ))
                            : null}
                          </select>
                        </div>
                    } 
 
                  {/* <div className="mb-4 font-sans text-lg font-semibold text-[#1C1243]"> */}
                  <div className={`mb-4 font-sans text-lg font-semibold text-[#1C1243] ${ooSSClass}`}>
                    Datos Clínicos
                  </div>

                  <div className="col-span-6 sm:col-span-3 mb-6">
                    <label htmlFor="office_medical_security_id" className={`block text-base font-semibold text-[#1C1243] ${ooSSClass}`}>
                    <span>Obra Social</span>
                    </label>
                    <select 
                      id="office_medical_security_id" 
                      name="office_medical_security_id"
                      className="input-style"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      {
                        editMode ? 
                          <option  className="" value="-1">{data.medical_security ? data.medical_security.medical_security_name : "Particular"}</option> 
                        : 
                          <option  className="" value="-1">Elige la obra social</option> }

                      {/* <option  className="" value="-1">Elige la obra social</option> */}

                      {
                        medicalSecurities && medicalSecurities.map((el) => (
                            <option key={`socialSecurity-${el.id}`} value={ el.id }>{ el.medical_security_name }</option>
                        ))
                      }
                      
                    </select>
                  </div>



                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="medical_security_number" className={`block text-base font-semibold text-[#1C1243] ${ooSSClass}`}>
                        <span>Número de Afiliado</span>
                      </label>
                      <input 
                        type="text" 
                        id="medical_security_number" 
                        name="medical_security_number" 
                        className="input-style"
                        required
                        placeholder="número de afiliado"
                        value={data.medical_security_number}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="mb-4 font-sans text-lg font-semibold">
                      Datos de Contacto
                    </div>

                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="last-name" className={`block text-base font-semibold ${requiredFields.telephone && send ? 'text-red-600' : 'text-[#1C1243]' }`}>
                        <span>Teléfono
                          {requiredFields.telephone && (
                            <span className="ml-1 text-red-600">*</span>
                          )}
                        </span>
                      </label>
                  
                      <div className="flex">
                        <div className="flex items-center input-style left p-0">                        
                          <img className="w-[24px] mt-0 ml-4" src={country.src} />
                          <div className="pl-2">+{country.code}</div>
                        </div>
                        <input 
                          type="text" 
                          id="telephone" 
                          className="input-style right"
                          required
                          placeholder="Número de teléfono"
                          name="telephone"
                          value={cleanPhone}
                          onChange={(e, code = country.code) => handleChangePhone(e, code = country.code)}
                          disabled={false}
                        />
                      </div>
                      
                      
                    </div>

                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="address" className="block text-base font-semibold text-[#1C1243]">
                        <span>Domicilio</span>
                      </label>
                      <input 
                        type="text" 
                        id="address" 
                        className="input-style"
                        required
                        placeholder="Domicilio"
                        name="address"
                        value={data.address}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>
            
                    <div className="col-span-6 sm:col-span-3 mb-6">
                      <label htmlFor="last-name" className="block text-base font-semibold text-[#1C1243]">
                        <span>Email</span>
                      </label>
                      <input 
                        type="text" 
                        id="email" 
                        className="input-style"
                        required
                        placeholder="ejemplo@meducar.com"
                        name="email"
                        value={data.email}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>
                  </div>
                  </form>
                </div>                

                {/* {requiredFields.surname && <div className="ml-4 mb-1 text-red-600">El apellido es un campo obligatorio</div>}
                {requiredFields.name && <div className="ml-4 mb-1 text-red-600">El nombre es un campo obligatorio</div>}
                {requiredFields.born_date && <div className="ml-4 mb-1 text-red-600">La fecha de nacimiento es un campo obligatorio</div>}
                {requiredFields.office_id && <div className="ml-4 mb-1 text-red-600">Debes seleccionar el consultorio</div>}
                {requiredFields.telephone && <div className="ml-4 mb-1 text-red-600">El teléfono es un campo obligatorio</div>} */}


                <div className="fixed bottom-0 w-full px-4 py-3 text-right sm:px-6">
                  <button onClick={(e) => validateForm(e)} type="submit" className="submit-button" disabled={disabledButton}>{(loading && isSubmitting) ? "Guardando..." : button}</button>
                </div>
              </div>
            </div>

        </div>
      </div>
    </>
  );
};

export default PatientForm;
