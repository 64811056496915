import React, { useEffect, useState } from "react";
import { useDispatch, useSelector }   from "react-redux";
import { useNavigate, useParams }     from "react-router-dom";

import { makeStyles }         from "@material-ui/core/styles";
import Typography             from "@material-ui/core/Typography";
import Modal                  from "@material-ui/core/Modal";
import Backdrop               from "@material-ui/core/Backdrop";
import Fade                   from "@material-ui/core/Fade";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { apiCall }            from "../redux/api";

import PatientForm from "../components/PatientForm";
import { useAuth } from "../components/AuthProvider";

import { setShowHeader } from "../redux/actions/layout";
import { createPatient }               from "../redux/actions/patients";
import { useEditButton }               from "../redux/actions/patients";
import { setBackButton }               from "../redux/actions/layout";

import session         from "../utils/session";
import { convertDate } from "../utils/dates";

import { toast } from 'react-toastify';

import removeAreaCode from '../middlewares/removeAreaCode';

import Swal from 'sweetalert2'
import '../swal.css';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "100%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  img: {
    marginTop: "20px",
  },
  width: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const NewPatient = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const { setUrl } = useAuth();

  let Storage = null;
  if (localStorage?.CurrentUrl) {
    Storage = JSON.parse(localStorage.CurrentUrl);
  }
  
  const onCreatePatientResult = useSelector((state) => state.patients.onCreatePatientResult);
  const onCreatePatientError = useSelector((state) => state.patients.error);

  // const [userId, setUserId]                               = useState(null);
  const [onCreatePatientCalled, setOnCreatePatientCalled] = useState(false);
  const [selectedDate, setSelectedDate]                   = useState(new Date());
  const [error, setError]                                 = useState(false);
  const [open, setOpen]                                   = useState(false);
  const [phoneCode, setPhoneCode]                         = useState();
  const [hasCode, setHasCode]                             = useState(false);
  const [oneOffice, setOneOffice]                         = useState(false);
  const [country, setCountry]                             = useState({});
  const [send, setSend]                                   = useState(false);
  const [cleanPhone, setCleanPhone]                       = useState();
  const [isSubmitting, setIsSubmitting]                   = useState(false);
  const [loading, setLoading]                             = useState(false);

  const [patient, setPatient] = useState({
    office_id: "",
    name: "",
    surname: "",
    telephone: "",
    country_id: 6,
  });
  const [requiredFields, setRequiredFields] = useState({
    surname: true,
    name: true,
    telephone: true,
    office_id: true,
    born_date: true
  });
  const [patientDataByParams, setPatientDataByParams] = useState(null);

  const { professional: professionalData, secretary: secretaryData } = session.getUser();
  const userData                                       = session.getUser();
  //const professional_id = professionalData.id;

  const handleDateChange = (date, isValid) => {
    setSelectedDate(date);
    setRequiredFields({
      ...requiredFields, born_date: !isValid
    })

  };

  useEffect(() => {
    // if (professionalData) {
    //   setUserId(professionalData.id)
    // } else if (secretaryData) {
    //   setUserId(secretaryData.id)
    // }
    // const data = {
    //   userId,
    // };
    dispatch(useEditButton(false));
    dispatch(setBackButton(true));
    // dispatch(setShowHeader(false));
    setRequiredFields({
      ...requiredFields, born_date: true
    });
    setHasCode(false);
    onlyOneOffice();
    setIsSubmitting(false);

    if (params?.data) {
      console.log('params', params);
      try {
        const auxData = JSON.parse(atob(params.data));
        console.log(auxData);
        setPatientDataByParams(auxData);        
      } catch(ex) {
        //Nada
      }      
    }

  }, []);

  useEffect(async () => {
    console.log('patientDataByParams', patientDataByParams);
    if (patientDataByParams) {
      
      let dataPatientInDB = null;
      if (patientDataByParams.dni) {
        
        // Busco paciente con ese DNI
        const auxRes = await apiCall(`/api/patients?id_number=${patientDataByParams.dni}`, null, null, 'GET');
        if (auxRes?.status == 200) {
          if (auxRes.data.data.total > 0) {
            dataPatientInDB = auxRes.data.data.data[0];
          }
        } else {
          toast.error("No se pudo consultar el DNI del paciente. ERROR: " + auxRes.statusText);
        }
      }     

      let auxSurname = patientDataByParams.name;
      let auxName = '';
      if (patientDataByParams.name) {
        const arrName = patientDataByParams.name.split(' ');
        if (arrName.length > 1) {
          auxSurname = arrName[arrName.length - 1];
          auxName = patientDataByParams.name.replace(auxSurname, '').trim();
        }
      }

      const auxPat = {
        ...patient,
        surname: auxSurname,
        name: auxName,
        id_number: patientDataByParams.dni ? patientDataByParams.dni : null,
        event_id: patientDataByParams.event_id,
        office_id: patientDataByParams.office_id
      };

      if (dataPatientInDB) {
        // Modo edición con el agregado de event_id para que lo asigne al guardar
        
        Swal.fire({
          html: `Ya existe el paciente <b>${dataPatientInDB.surname}, ${dataPatientInDB.name}</b> con el mismo DNI.`,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3388FF',
          cancelButtonColor: '#3388FF',
          denyButtonColor: '#3388FF',
          confirmButtonText: 'Asociar al turno',          
          denyButtonText: 'Asociar y editar paciente',
          cancelButtonText: 'Crear nuevo paciente',
          reverseButtons: false,
          allowOutsideClick: false,
          customClass: {
              confirmButton: 'custom-button-1',
              denyButton: 'custom-button-2',
              cancelButton: 'custom-button-3',             
              htmlContainer:'text'
            },
          }).then((result) => {
            if (result.isConfirmed) {                
              // PATCH turno y salir
              addEventToPatient(patientDataByParams.event_id, dataPatientInDB.id, true);
            } else if (result.isDenied) {              
              // PATCH turno y llamar al editar paciente
              addEventToPatient(patientDataByParams.event_id, dataPatientInDB.id, false);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Me quedo en la pantalla pero sin DNI así se crea el paciente nuevo
              auxPat.id_number = null;
            }

            setPatient(auxPat);
            setRequiredFields({
              ...requiredFields, 
              surname: !auxPat.surname, 
              name: !auxPat.name,
              office_id: !auxPat.office_id
            });
        });

      } else {
        setPatient(auxPat);
        setRequiredFields({
          ...requiredFields, 
          surname: !auxPat.surname, 
          name: !auxPat.name,
          office_id: !auxPat.office_id
        });
      }
      
    }
  }, [patientDataByParams]);

  useEffect(() => {

    console.log('selectedDate', selectedDate);

    if (oneOffice) {
      setPatient({
        ...patient,
        born_date: selectedDate,
        office_id: userData.offices[0].id
      });

    } else {
      setPatient({
        ...patient,
        born_date: selectedDate,
      });

    }

  }, [selectedDate]);

  useEffect(() => {

    if (hasCode) {
      if (
        //requiredFields.office_id ||
        requiredFields.surname ||
        requiredFields.name ||
        requiredFields.telephone ||
        requiredFields.born_date
      ) {
        setHasCode(false)
        return;
      }
  
      setError(false);
  
      setOnCreatePatientCalled(true);
      dispatch(createPatient(patient));
    }

  }, [hasCode]);

  useEffect(() => {

    setPatient({
      ...patient,
      telephone: "" + phoneCode + cleanPhone,
    });

  }, [cleanPhone]);

  useEffect(() => {

    if (onCreatePatientCalled && onCreatePatientResult != null) {
      setOnCreatePatientCalled(false);
    }

    // Paciente creado ERROR
    // if (onCreatePatientCalled && onCreatePatientResult == 0) { alert('cero') }

    if (onCreatePatientError) { 
      toast.error(onCreatePatientError.message);
      setHasCode(false);
      setSend(false);
      setIsSubmitting(true);
      setOnCreatePatientCalled(false);
    }
    
    // Paciente creado OK
    if (onCreatePatientCalled && onCreatePatientResult == 1) { 
      handleOpen();
      closePopup();
    }

  }, [onCreatePatientResult, onCreatePatientError]);

  /*async function createPatient(patient) {
        console.log('funcion');
       let resultGetInfo = await http.createNewPatient(currentProfessional, patient);
       console.log('resultGetPatients', resultGetInfo)
         if (resultGetInfo.status === 200) {
          if (resultGetInfo.data.data) {
           console.log(resultGetInfo.data.data);
         }
       }
     }*/

  const addEventToPatient = async (eventId, patientId, close) => {

    setLoading(true);

    // Busco paciente con ese DNI
    const auxRes = await apiCall(`/api/events/${eventId}/patient`, { patient_id: patientId }, null, 'PATCH');
    console.log(auxRes);
    if (auxRes?.status == 200) {
      if (auxRes.data.data.total > 0) {
        dataPatientInDB = auxRes.data.data.data[0];
      }
    } else {
      toast.error("No se pudo asociar el turno al paciente. ERROR: " + auxRes.statusText);
      closePopup(0);
    }

    // Con un OK o un ERROR, salgo del popup si tiene el flag activo
    if (close) {
      closePopup(0);
    } else {
      navigate(`/patients/${patientId}/info`);
    }

  }

  const validateForm = (e) => {
    e.preventDefault();
    
    // setSend(true)
    // setIsSubmitting(true);

    if (oneOffice) {
      if (
        requiredFields.surname ||
        requiredFields.name ||
        requiredFields.born_date ||
        requiredFields.telephone
      ) {
        setHasCode(false)
        toast.error("Hay errores en el formulario");
        return;
      }

    } else {
      if (
        requiredFields.office_id ||
        requiredFields.surname ||
        requiredFields.name ||
        requiredFields.born_date ||
        requiredFields.telephone
      ) {
        setHasCode(false)
        toast.error("Hay errores en el formulario");
        return;
      }

    }

    // if (!onCreatePatientCalled) {
    //   setPatient({...patient, telephone: ""+ phoneCode + cleanPhone });
    // }
    setSend(true)
    setIsSubmitting(true);
    
    setHasCode(true);

  };
  //Funcion que convierte la fecha ingresada y la devuelve en dos formatos(Uno aceptado por la API y otro por los DatePicker)
  // const getDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   const fullDate = `${year}-${month}-${day}`;
  //   return fullDate;
  // };

  const handleChange = (e) => {
    setPatient({
      ...patient,
      [e.target.name]: e.target.value,
    });

    if (e.target.value === "") {
      setRequiredFields({ ...requiredFields, [e.target.name]: true });
    } else {
      setRequiredFields({ ...requiredFields, [e.target.name]: false });
    }
  };

  const handleChangePhone = (e, code) => {

    setPhoneCode(code);
    setCleanPhone(removeAreaCode(e.target.value, String(code)));   

    if (e.target.value === "") {
      setRequiredFields({ ...requiredFields, [e.target.name]: true });
    } else {
      setRequiredFields({ ...requiredFields, [e.target.name]: false });
    }
    
  }

  const handleOpen = () => {
    if (error) return;
    setOpen(true);
  };

  const onlyOneOffice = () => {

    if (userData.offices.length == 1) {

      setOneOffice(true)

    } 

  }

  const closePopup = (delay = 1000) => {
    
    dispatch(setShowHeader(true));
    
    setTimeout(() => {

      setLoading(false);      
      if (Storage?.fromAppointmet && Storage?.type == 'create-pat') {
        setUrl(null);
        navigate("/appointments");
      } else {
        navigate("/patients")
      }

    }, [delay]);    
  }

  return (
    <>
      <PatientForm
        handleChange={handleChange}
        handleChangePhone={handleChangePhone}
        validateForm={validateForm}
        // selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        requiredFields={requiredFields}
        data={patient}
        disabledButton={onCreatePatientCalled}
        button="Crear Paciente"
        title="Nuevo Paciente"
        send={send}
        cleanPhone={cleanPhone}
        setCleanPhone={setCleanPhone}
        isSubmitting={isSubmitting}
        externalLoading={loading}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade style={{ borderRadius: "20%", maxWidth: "80%" }} in={open}>
          <div className={classes.paper}>
            <Typography variant="h5" align="center" id="transition-modal-title">
              Paciente creado exitosamente
            </Typography>
            <CheckCircleOutlineIcon
              style={{ fontSize: 100, justifyContent: "center", width: "100%" }}
              color="primary"
            />
          </div>
        </Fade>
      </Modal>
                        
    </>
  );
};

export default NewPatient;