import {GET_OFFICES_START, GET_SCHEDULE_START, GET_EVENT_OPTIONS_START, APPOINTMENT_DATE, SCHEDULE_RANGE_CHANGE, CREATE_APPOINTMENT_START, SET_CURRENT_OFFICE, SET_APPOINTMENTS_DATE, NEW_APPOINTMENT_START, GET_TAGS_START, CHANGE_TAGS_START, RESET_APPOINTMENT_OPTIONS} from '../../consts/actionTypes';

export const getOffices = payload => ({
    type: GET_OFFICES_START,
    payload
});

export const setCurrentOffice = payload => ({
    type: SET_CURRENT_OFFICE,
    payload
});


export const getSchedule = payload => ({
    type: GET_SCHEDULE_START,
    payload
});


export const getEventOptions = payload => ({
    type: GET_EVENT_OPTIONS_START,
    payload
});

export const changeDate = payload => ({
    type: APPOINTMENT_DATE,
    payload
});
export const changeSchemaRangeChange = payload => ({
    type: SCHEDULE_RANGE_CHANGE,
    payload
});

export const createAppointment = payload => ({
    type: CREATE_APPOINTMENT_START,
    payload
});

export const resetAppointmentOptions = payload => ({
    type: RESET_APPOINTMENT_OPTIONS,
    payload
});

export const newAppointment = payload => ({
    type: NEW_APPOINTMENT_START,
    payload
});

export const getTags = payload => ({
    type: GET_TAGS_START,
    payload
});

export const changeTags = payload => ({
    type: CHANGE_TAGS_START,
    payload
});


