import { GET_PATIENTS_START, 
    GET_PATIENTS_ERROR, 
    GET_PATIENTS_COMPLETE, 
    GET_INFO_PATIENT_START, 
    GET_INFO_PATIENT_ERROR,
    GET_INFO_PATIENT_COMPLETE,
    CLEAN_INFO_PATIENT,
    USE_EDIT_BUTTON,
    UPDATE_PATIENT_START,
    UPDATE_PATIENT_ERROR,
    UPDATE_PATIENT_COMPLETE,
    CREATE_NEW_PATIENT_START,
    CREATE_NEW_PATIENT_ERROR,
    CREATE_NEW_PATIENT_COMPLETE,
    CLEAN_PATIENTS,
    RESET_PATIENTS,
    GET_HC_START,
    GET_HC_ERROR,
    GET_HC_COMPLETE, 

    CHANGE_PATIENT_TAGS_START,
    CHANGE_PATIENT_TAGS_ERROR,
    CHANGE_PATIENT_TAGS_COMPLETE
} from '../../consts/actionTypes';

const initialState = {

    // Listado
    isLoading: true,
    patientResults: null,
    totalPatients: 0,

    // Detalle
    patientId: null,

    editButton: true,
    error: false,

    inChangePatientTags: 0,

    onCreatePatientResult: null,

    actualHC: []
};


export default function(state = initialState, action) {
    switch(action.type) {
        case GET_PATIENTS_COMPLETE:
            return {...state, isLoading: false, error: false,  
                patientResults: (state.patientName == action.name && !action.reset) ? [ ...state.patientResults, action.results.data.data.data] : [...[], action.results.data.data.data], current_page: action.results.data.data.current_page, 
                last_page: action.results.data.data.last_page, patientName: state.patientName == action.name ? state.patientName : action.name, totalPatients: action.results.data.data.total, update: null}
            
        case CLEAN_INFO_PATIENT:
            return {...state, patient:null, actualHC: undefined}
            
        case GET_PATIENTS_ERROR:
            return {...state, isLoading: false, patientResults: null, error: true}
            
        case GET_PATIENTS_START:
            return {...state, isLoading: true, error: false, patientResults: state.patientResults ? state.patientResults : []}
            

        case CREATE_NEW_PATIENT_START:
            return {...state, onCreatePatientResult: null, isLoading: true, error: false}
            
        case CREATE_NEW_PATIENT_ERROR:
            return {...state, onCreatePatientResult: 0, isLoading: false, error: action.error.response.data}
            
        case CREATE_NEW_PATIENT_COMPLETE:
            return {...state, onCreatePatientResult: 1, isLoading: false, error: false, patientResults: null}
            

        case GET_INFO_PATIENT_START:
            return {...state}
            
        case GET_INFO_PATIENT_ERROR:
            return {...state, patient: null, error: action.error}    
            
        case GET_INFO_PATIENT_COMPLETE:
            return {...state, patient: action.results.data.data}
            

        case CLEAN_PATIENTS:
            return {...state, patientResults: null, curren_page: 0, last_page: 0, patientName: '', totalPatients: 0, isLoading: false}
            

        case USE_EDIT_BUTTON:
            return {...state, editButton: action.payload}
            

        case UPDATE_PATIENT_START:
            return {...state, isLoading: true};
            
        case UPDATE_PATIENT_ERROR:
            return {...state, update: false, isLoading: false}
            
        case UPDATE_PATIENT_COMPLETE:
            return {...state, update: true, isLoading: false, patientResults: null}
            

        case GET_HC_START:
            return {...state, actualHC: null, hc_count: 0}
        case GET_HC_ERROR:
            return {...state, actualHC: null}
        case GET_HC_COMPLETE:
            return {...state, actualHC: action.results.data.data.data, hc_count: action.results.data.data.total}

        case CHANGE_PATIENT_TAGS_START:
            return {...state, inChangePatientTags: true }
        case CHANGE_PATIENT_TAGS_ERROR:
            return {...state, error: action.error}
        case CHANGE_PATIENT_TAGS_COMPLETE:
            let auxPatient = { ...state.patient, tags: action.results.data.data.data };
            return {...state, patient: auxPatient, inChangePatientTags: false }

        default:
            return {...state}
    }
}