import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Prescription from "./Prescription";
import SectionLoading from '../components/SectionLoading';

const useStyles = makeStyles((theme) => ({
  data: {
    marginBottom: "5px",
    fontSize: "15px",
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    margin: "auto",
    marginTop: "3px",
  },
}));

const InfiniteListPrescriptions = ({
  name,
  data,
  hasMore,
  error,
  loading,
  isSearch,
  setPageNumber,
  handleClickPrescription,
}) => {
  const pageClasses = useStyles();

  //const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(false);
  //const [patients, setPatients] = useState([]);
  //const [hasMore, setHasMore] = useState(false);
  //const [pageNumber, setPageNumber] = useState(1);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  /*useEffect(() => {
      if(pageNumber > 1){
         if(data.length > 0) {
            getData(pageNumber);
         }
      }
      
   }, [pageNumber]);

   useEffect(() => {
      //Esto se llama la primera vez (no deberia)
      setPageNumber(1);
      getData(pageNumber, true);
   }, [name]);*/

  if (!data) {
    return <SectionLoading loading={ true } smaller={ true } />;
  }

  return (
    <Fragment>

      <div className="flex justify-center">
        {loading && isSearch && (
          <SectionLoading loading={ loading } smaller={ true } />
        )}
      </div>

      {!error ? (
        data.map((data, index) => {
          return data.map((d, i) => {
            if (10 === i + 1) {
              return (
                <div key={i} ref={lastBookElementRef}>
                  <Prescription
                    key={d.id}
                    data={d}
                    button="Ver"
                    handleClickPrescription={(id) => {
                      handleClickPrescription(id);
                    }}
                  />
                </div>
              );
            } else {
              return (
                <Prescription
                  key={d.id}
                  data={d}
                  button="Ver"
                  handleClickPrescription={(id) => {
                    handleClickPrescription(id);
                  }}
                />
              );
            }
          });
        })
      ) : (
        <div className="flex justify-center">
          <li className="flex items-center p-5">
            <div className="flex">
              <h2 className="font-gray-100">No se encontraron resultados</h2>
            </div>
          </li>
        </div>
      )}

      <div className="flex justify-center">
        {loading && !isSearch && (
          <SectionLoading loading={ loading } smaller={ true } />
        )}
      </div>

    </Fragment>
  );
};

export default InfiniteListPrescriptions;
