import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import OfficesWrapper from "./OfficesWrapper";
import { setPageTitle, setShowHeader } from "../redux/actions/layout";
import { useAuth } from "../components/AuthProvider";
import packageJson from "../../package.json";

import session from "../utils/session";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#088b78",
    height: "30vh",
    paddingTop: "20px",
  },
  container: {
    paddingTop: "10vh",
    height: "60vh",
  },
}));

const Settings = () => {
  //const userData = useSelector(state => state.login.userData);
  const { handleLogout } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();

  const userData = session.getUser();

  useEffect(() => {
    //dispatch(setPageTitle("Mi perfil"));
    dispatch(setShowHeader(false));
  }, []);

  return (
    <>
    <div className="absolute top-5 right-5 text-gray-300">
      {`v${packageJson.version}`}
    </div>
    <div style={{backgroundColor: 'rgba(51, 136, 255, 0.1)', borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px', minHeight: '400px'}} className="max-w-md mx-auto overflow-hidden md:max-w-2xl pb-2 font-sans">
      <div className="md:flex">
        
        <div className="mt-16">
          <h2 className="float-left ml-6 text-2xl font-bold">
            Mi Perfil
          </h2>
          {/* <div className="float-right mr-6 border-2 border-black px-5 py-2 pb-1 rounded-full">
            <button>
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.90683 1.31686C10.5899 0.633761 11.5164 0.25 12.4825 0.25C14.4942 0.25 16.1249 1.8808 16.1249 3.89249C16.1249 4.85853 15.7412 5.78502 15.0581 6.46812L10.6517 10.8745C8.63989 12.8863 6.11919 14.3135 3.35907 15.0035L2.5074 15.2165C1.69274 15.4201 0.954828 14.6822 1.15849 13.8675L1.37141 13.0159C2.06144 10.2558 3.48864 7.73506 5.50041 5.72329L9.90683 1.31686ZM12.4825 1.75C11.9142 1.75 11.3693 1.97573 10.9675 2.37752L10.2683 3.0767C10.1445 3.7018 10.4536 4.52884 11.1498 5.22512C11.8461 5.92139 12.6731 6.23046 13.2983 6.10664L13.9974 5.40746C14.3992 5.00566 14.6249 4.46071 14.6249 3.89249C14.6249 2.70922 13.6657 1.75 12.4825 1.75ZM11.9466 7.45828C11.2391 7.22525 10.5906 6.78716 10.0892 6.28578C9.58779 5.78439 9.1497 5.13587 8.91668 4.42834L6.56107 6.78395C4.74154 8.60348 3.45072 10.8833 2.82662 13.3797L2.77041 13.6045L2.99526 13.5483C5.49164 12.9242 7.77147 11.6334 9.591 9.81388L11.9466 7.45828Z" fill="black"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M0 19C0 18.5858 0.335786 18.25 0.75 18.25H16.5C16.9142 18.25 17.25 18.5858 17.25 19C17.25 19.4142 16.9142 19.75 16.5 19.75H0.75C0.335786 19.75 0 19.4142 0 19Z" fill="black"/>
              </svg>
            </button>
          </div>   */}
        </div>

        <div className="float-none mb-6">&nbsp;</div>      
        
        <div className="flex md:shrink-0 mt-8 mx-6">
          
          <img className="w-[92px] h-[92px] rounded-full mr-4" alt="Perfil" src={`${userData.photo ? userData.photo : 'https://cdn.meducar.com/img/profile_notfound.png'}`} />
          
          <div className="mt-4">
            <div className="text-base font-bold leading-6">{userData.name}</div>
            <div className="text-sm text-gray-400 leading-6">{userData.username}</div>
            <div className="text-sm text-gray-400 leading-6">{userData.whatsapp}</div>
          </div>
        </div>

        <div className="px-6 pt-4 mt-2 -mb-12">
          <div className="pb-2 text-lg font-bold">Consultorios</div>
          <OfficesWrapper offices={userData.offices} />  
        </div>
      </div>
    </div>

    {/* <div className="absolute top-12 right-6 mt-1 border-2 border-black px-5 py-2 pb-1 rounded-full">
      <button>
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.90683 1.31686C10.5899 0.633761 11.5164 0.25 12.4825 0.25C14.4942 0.25 16.1249 1.8808 16.1249 3.89249C16.1249 4.85853 15.7412 5.78502 15.0581 6.46812L10.6517 10.8745C8.63989 12.8863 6.11919 14.3135 3.35907 15.0035L2.5074 15.2165C1.69274 15.4201 0.954828 14.6822 1.15849 13.8675L1.37141 13.0159C2.06144 10.2558 3.48864 7.73506 5.50041 5.72329L9.90683 1.31686ZM12.4825 1.75C11.9142 1.75 11.3693 1.97573 10.9675 2.37752L10.2683 3.0767C10.1445 3.7018 10.4536 4.52884 11.1498 5.22512C11.8461 5.92139 12.6731 6.23046 13.2983 6.10664L13.9974 5.40746C14.3992 5.00566 14.6249 4.46071 14.6249 3.89249C14.6249 2.70922 13.6657 1.75 12.4825 1.75ZM11.9466 7.45828C11.2391 7.22525 10.5906 6.78716 10.0892 6.28578C9.58779 5.78439 9.1497 5.13587 8.91668 4.42834L6.56107 6.78395C4.74154 8.60348 3.45072 10.8833 2.82662 13.3797L2.77041 13.6045L2.99526 13.5483C5.49164 12.9242 7.77147 11.6334 9.591 9.81388L11.9466 7.45828Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 19C0 18.5858 0.335786 18.25 0.75 18.25H16.5C16.9142 18.25 17.25 18.5858 17.25 19C17.25 19.4142 16.9142 19.75 16.5 19.75H0.75C0.335786 19.75 0 19.4142 0 19Z" fill="black"/>
        </svg>
      </button>
    </div> */}

    {/* 
    <div className="ml-6 mt-6 text-2xl font-bold">Notificaciones</div>

    <div className="max-w-md mx-auto rounded-xl overflow-hidden md:max-w-2xl">
      <div className="md:flex">
        <div className="p-4">
          <div className="ml-2 mr-2 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            <div className="m-4 grid grid-cols-2">
              <div className="ml-4 flex">
                <svg 
                  style={{ color: '#A29EB6' }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="mt-2 w-10 h-10 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  ></path>
                </svg>
                <div className="ml-4 mt-4 text-base font-bold">Push</div>
              </div>        
              <div>
                <div className="ml-16">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 576 512"
                    className="inline-block mr-1 w-14 h-14 text-gray-400"
                  >
                    <path fill="#A29EB6" d="M384 64H192C85.961 64 0 149.961 0 256s85.961 192 192 192h192c106.039 0 192-85.961 192-192S490.039 64 384 64zM64 256c0-70.741 57.249-128 128-128 70.741 0 128 57.249 128 128 0 70.741-57.249 128-128 128-70.741 0-128-57.249-128-128zm320 128h-48.905c65.217-72.858 65.236-183.12 0-256H384c70.741 0 128 57.249 128 128 0 70.74-57.249 128-128 128z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    */}

    <div className="font-sans pt-10 text-center text-base text-[#FF6A5D] font-medium">
      <button type="button" onClick={() => {
        session.logout();
        handleLogout()
      }}>
        Cerrar Sesión
      </button>
    </div>

    {/* <div className="py-2 h-screen bg-gray-300 px-2 overflow-x-auto"> */}
      {/* <div className="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden md:max-w-lg">
              <div className="md:flex">
                <div className="w-full p-4"> */}

      {/* <div className="card bg-gray-100 shadow-lg rounded-lg compact mt-16">
        <figure>
          <img src={userData.photo} />
        </figure>
        <div className="card-body">
          <h2 className="card-title">{userData.name}</h2>
          <p className="text-lg font-bold">
            {userData.professional
              ? userData.professional.speciality.name
              : null}
          </p>
          <p>
            <span className="text-sm text-gray-400">Teléfono</span>
            <span className="text-sm font-bold text-black ml-2">
              {userData.whatsapp ? userData.whatsapp : "--"}
            </span>
            <br />
            <span className="text-sm text-gray-400">Email</span>
            <span className="text-sm font-bold text-black ml-2">
              {userData.username ? userData.username : "--"}
            </span>
            <br />
            <span className="text-sm text-gray-400">Ciudad</span>
            <span className="text-sm font-bold text-black ml-2">
              {userData.city_name ? userData.city_name : "--"}
            </span>
          </p>
        </div>
      </div>
      <Link to="/logout">
      <div className="mb-2 w-full mt-4 mx-auto">
        <button className="btn btn-block  " >
          Cerrar Sesion
        </button>
      </div>
      </Link>
    </div> */}
    </>
  );
};

export default Settings;
