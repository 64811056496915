import { all } from 'redux-saga/effects';

import patients from './patients';
import register from './register';
import login from './login';
import layout from './layout';
import office from './offices';
import prescriptions from './prescriptions';

export default function* rootSaga() {
	yield all([
		patients(),
		register(),
		login(),
		layout(),
		office(),
		prescriptions()
	]);
}
