import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TimePicker from 'react-time-picker';
import { useAuth } from "../components/AuthProvider";
// ES6 Modules or TypeScript

import sendSwal from '../middlewares/sendSwal'

// CommonJS


//import { HourRangeSelector } from "../components/HourRangeSelector";
import moment from "moment";

export const OnboardingTwo = (userData) => {

    const Swal = require('sweetalert2')

    const iniHour = '08';
    const endHour = '17';
    const iniMin = '00';
    const endMin = '00';
    const hHeader = 220;
    const hFooter = 125;
    const classHeader = `h-[${hHeader}px] bg-white`; //bg-gray-200
    const classFooter = `h-[${hFooter}px] bg-white`;
    const classContent = `mt-[${hHeader}px] mb-[${hFooter}px] min-h-[calc(100vh-${hHeader + hFooter}px)] max-h-[calc(100vh-${hHeader + hFooter}px)] overflow-y-auto`;

    const dayName = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

    const { token, wizardData, handleSaveWizardStep } = useAuth();

    const navigate = useNavigate();

    const [selectedHourUse, setSelectedHourUse] = useState([false,false,false,false,false,false,false]);
    const [selectedHourIni, setSelectedHourIni] = useState([iniHour, iniHour, iniHour, iniHour, iniHour, iniHour, iniHour]);
    const [selectedMinIni, setSelectedMinIni] = useState([iniMin, iniMin, iniMin, iniMin, iniMin, iniMin, iniMin]);
    const [selectedHourFin, setSelectedHourFin] = useState([endHour, endHour, endHour, endHour, endHour, endHour, endHour]);
    const [selectedMinFin, setSelectedMinFin] = useState([endMin, endMin, endMin, endMin, endMin, endMin, endMin]);

    const [showStep3, setShowStep3] = useState(true);

    useEffect(() => {
        if (!token) {
            navigate('/login');
            //window.location.href = '/login'
        }

        if (wizardData?.step >= 2) {
            // console.log(wizardData.step);
            // llenar campos
            handleSaveWizardStep({
                hours: wizardData?.hours,
            });
            thereAre()
        }

        if (wizardData?.countryId != 6) {
            setShowStep3(false);
        }

    }, []);

    const getGrid = () => {

        let auxGrid = [];

        let mainText = "Has seleccionado un horario de inicio posterior al de finalización. El horario de ese día no será guardado. ¿Deseas continuar o modificar?"
        let textConfirmButtom = "Modificar"
        let textCancelButtom ="Continuar"

        Array.from(selectedHourIni, (item, index) => {
            if (selectedHourUse[index]) {

                if ( (selectedHourFin[index] > selectedHourIni[index]) || ((selectedHourFin[index] == selectedHourIni[index]) && (selectedMinFin[index] > selectedMinIni[index])) ) {
                    auxGrid.push({
                        day: dayName[index],
                        idxDay: index,
                        init: selectedHourIni[index] + ":" + selectedMinIni[index], 
                        end: selectedHourFin[index] + ":" + selectedMinFin[index]
                    });
                } else {
                    sendSwal(mainText, textConfirmButtom, textCancelButtom, keepInOB2, true);
                }

            }            
        });

        
        // console.log(auxGrid);
        return auxGrid;    

    }

    const getReverseGrid = () => {

        let auxReverseGrid = [];
        Array.from(wizardData.hours, (item, index) => {
            if (wizardData.hours[index]) {
                auxReverseGrid.push({
                    day: wizardData.hours[index].day,
                    idxDay: wizardData.hours[index].idxDay,
                    init: wizardData.hours[index].init,
                    end: wizardData.hours[index].end
                });
            }            
        });
        return auxReverseGrid; 

    }

    const thereAre = () => {

        if (wizardData.hours.length > 0) {

            let gridSaved = getReverseGrid();

            // console.log("hola",gridSaved)
            // console.log(wizardData.hours);
            
            let selectedAux = [...selectedHourUse]; 
            let initHourAux = [...selectedHourIni]; 
            let initMinAux = [...selectedMinIni]; 
            let endHourAux = [...selectedHourFin]; 
            let endMinAux = [...selectedMinFin]; 

            for ( let i=0 ; i< gridSaved.length; i++ ) {
                
                let idx = gridSaved[i].idxDay;

                selectedAux[idx] = true;
                initHourAux[idx] = gridSaved[i].init.substring(0, 2)
                initMinAux[idx] = gridSaved[i].init.substring(3, 5)
                endHourAux[idx] = gridSaved[i].end.substring(0, 2)
                endMinAux[idx] = gridSaved[i].end.substring(3, 5)
            }

            //console.log(initAux);

            setSelectedHourUse(selectedAux);
            setSelectedHourIni(initHourAux);
            setSelectedMinIni(initMinAux);
            setSelectedHourFin(endHourAux);
            setSelectedMinFin(endMinAux);

        }
    }



    const goToOnboardingOne = (data) => {
        navigate(`/onboarding-one`)
        //window.location.href = '/onboarding-one'
    }

    const goToOnboardingThree = (data) => {
        if (showStep3) {
            navigate(`/onboarding-three`)
        } else {
            navigate(`/onboarding-four`)
        }        
        //window.location.href = '/onboarding-three'
    }

    const goToOB3 = (data) => {

        let mainText = "Vas a poder configurar tus horarios de atención más adelante. ¿Continuar?"
        let textConfirmButtom = "Aceptar"
        let textCancelButtom ="Cancelar"

        if (selectedHourUse[0] == false && 
            selectedHourUse[1] == false &&
            selectedHourUse[2] == false &&
            selectedHourUse[3] == false &&
            selectedHourUse[4] == false &&
            selectedHourUse[5] == false &&
            selectedHourUse[6] == false) {
            sendSwal(mainText, textConfirmButtom, textCancelButtom, goToOnboardingThree, true);
            
          } else { 
            goToOnboardingThree();
        }
    }

    const handleSave = (data) => {

        data.step = 2;
        if (wizardData?.step) {
            data.step = Math.max(data.step, wizardData.step);
        }
        // console.log(data);
        handleSaveWizardStep(data);
        goToOB3();

    }

    const keepInOB2 = () => {
        navigate(`/onboarding-two`); 
    }

    // const daySelector = (day) => {

    //     let result = "";

    //     switch (day) {
    //         case 'L':
    //             result ='Lunes';
    //             setClassMonday(!classMonday);
    //             break;
    //         case 'M':
    //             result ='Martes';
    //             setClassThuesday(!classThuesday);
    //             break;
    //         case 'X':
    //             result ='Miércoles';
    //             setClassWednesday(!classWednesday);
    //             break;
    //         case 'J':
    //             result ='Jueves';
    //             setClassThursday(!classThursday);
    //             break;
    //         case 'V':
    //             result ='Viernes';
    //             setClassFriday(!classFriday);
    //             break;
    //         case 'S':
    //             result ='Sábado';
    //             setClassSaturday(!classSaturday);
    //             break;
    //         case 'D':
    //             result ='Domingo';
    //             setClassSunday(!classSunday);
    //             break;
    //       }
          
    //       if ( result != "" && daysToShow.indexOf(result) == -1 ) {
    //         setDaysToShow([...daysToShow, result])
    //       }
    // }

    // const handleAddDay = (day) => {
    //     setDaysToShow([...daysToShow, day]);
    // }

    const handleChangeHour = (type, dayIndex, e) => {

        // console.log(type);
        // console.log(dayIndex);
        //console.log(e.target.value);
        let auxData = null;
        if (type == 1) {
            auxData = [...selectedHourIni];
            auxData[dayIndex] = e.target.value;
            setSelectedHourIni(auxData);
        }
        if (type == 2) {
            auxData = [...selectedMinIni];
            auxData[dayIndex] = e.target.value;
            setSelectedMinIni(auxData);
        }
        if (type == 3) {
            auxData = [...selectedHourFin];
            auxData[dayIndex] = e.target.value;
            setSelectedHourFin(auxData);
        }
        if (type == 4) {
            auxData = [...selectedMinFin];
            auxData[dayIndex] = e.target.value;
            setSelectedMinFin(auxData);
        }
        if (type == 5) {
            auxData = [...selectedHourUse];
            auxData[dayIndex] = !auxData[dayIndex];
            setSelectedHourUse(auxData);
        }
    }

  return (
    <>
        <div className={`fixed w-full ${classHeader} px-[35px]`}>
            <h2 className="font-sans    mt-3 pt-[100px] text-center text-2xl font-bold tracking-tight text-[#3388FF] leading-tight">Días y horarios de atención al público</h2>
            <h4 className="font-sans mt-2 leading-5 text-center text-[17px] font-semibold tracking-tight text-gray-400">Elige el día y luego</h4>
            <h4 className="font-sans leading-5 text-center text-[17px] font-semibold tracking-tight text-gray-400">el rango horario</h4>
        </div>
        <div className="fixed top-[67px] left-[33px]" onClick={ () => goToOnboardingOne() }>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="black"/>
            </svg>
        </div>


                <div className={`${ classContent } flex items-start justify-center px-4 sm:px-6 lg:px-8`}>
                   
                    <form className="" >
                        <div className="">
                            <div className="flex justify-center sticky top-0 bg-white z-10 pt-8 -mx-10 mb-4">
                                <label htmlFor="days" className="text-base font-sans tracking-tight font-bold text-[#1C1243] py-1 px-4 mr-1">Días</label>
                                {
                                    /*
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classMonday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('L') } }>L</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classThuesday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('M') } }>M</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classWednesday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('X') } }>X</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classThursday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('J') } }>J</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classFriday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('V') } }>V</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classSaturday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('S') } }>S</span>
                                    <span className={`pt-1 pl-2 rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base font-medium ${(classSunday) ? 'bg-[#3388FF] text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { daySelector('D') } }>D</span>
                                    */
                                }

                                {
                                    Array.from(selectedHourIni, (item, index) => (
                                        <span key={`day-${index}`} className={`pt-1 mb-2 text-center rounded-full w-[34px] h-[34px] border border-transparent mr-1 text-base ${(selectedHourUse[index]) ? 'bg-[#3388FF] font-bold text-white outline-none': 'text-[#1C1243] bg-[#EFF1F3]'}`} onClick={ () => { handleChangeHour(5, index, '') } }>{ dayName[index].substring(0, 1) }</span>
                                    ))
                                }
                                
                            </div>

                            {/* <div className="mt-4">
                                {daysToShow.length > 0 &&   
                                    (daysToShow.map( el => <HourRangeSelector key={`hour-${el}-${Math.round(Math.random() * 1000)}`} day={el} addDay={handleAddDay} gridHours={gridHours} />))
                                } 
                            </div> */}

                            {
                                Array.from(selectedHourIni, (item, index) => (
                                    <div key={`hours-${index}`} className={`font-sans tracking-tight ${(selectedHourUse[index]) ? '':'hidden'}`}>
                                        
                                        <div className="grid pt-[3px] grid-cols-2 font-sans px-4 mt-4 rounded-2xl border border-gray-300 w-[260px] h-[48px]"> 
                                        <div className="ml-2 pt-2 font-bold text-base text-[#1C1243]">{ dayName[index] }</div>
                                            <div className="flex">
                                                <div className="my-2 font-semibold text-base text-gray-400 w-[40px]">
                                                    {/* <TimePicker className="" disableClock={true} format="HH:mm" clearIcon={null} clockIcon={null} value={selectedHourIni[index]} maxTime={selectedHourFin[index]} onChange={ (e) => handleChangeHour(1, index, e) } minutesStep={10}/> */}
                                                    <div className="flex">
                                                        <div>
                                                            <select
                                                                type="text" 
                                                                name="hourIni" 
                                                                className="bg-white appearance-none font-bold text-base focus:outline-none"
                                                                onChange={ (e) => handleChangeHour(1, index, e) }
                                                                value={selectedHourIni[index]}
                                                            >
                                                                <option>00</option>
                                                                <option>01</option>
                                                                <option>02</option>
                                                                <option>03</option>
                                                                <option>04</option>
                                                                <option>05</option>
                                                                <option>06</option>
                                                                <option>07</option>
                                                                <option>08</option>
                                                                <option>09</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                                <option>13</option>
                                                                <option>14</option>
                                                                <option>15</option>
                                                                <option>16</option>
                                                                <option>17</option>
                                                                <option>18</option>
                                                                <option>19</option>
                                                                <option>20</option>
                                                                <option>21</option>
                                                                <option>22</option>
                                                                <option>23</option>
                                                                <option>24</option>
                                                            </select>
                                                        </div>
                                                        <div className="font-bold text-base">:</div>
                                                        <div>
                                                            <select
                                                                type="text" 
                                                                name="minIni"  
                                                                className="bg-white appearance-none font-bold text-base focus:outline-none"
                                                                onChange={ (e) => handleChangeHour(2, index, e) }
                                                                value={selectedMinIni[index]}
                                                            >
                                                                <option>00</option>
                                                                <option>10</option>
                                                                <option>20</option>
                                                                <option>30</option>
                                                                <option>40</option>
                                                                <option>50</option>
                                                            </select>
                                                        </div>
                                                    </div>                                          
                                                </div>
                                                <div className="my-2 font-semibold text-base text-[#1C1243] pl-2">a</div>
                                                <div className="pl-1 my-2 font-semibold text-base text-gray-400 w-[40px]">
                                                    {/* <TimePicker className="" disableClock={true} format="HH:mm" clearIcon={null} clockIcon={null} value={selectedHourFin[index]} minTime={selectedHourIni[index]} onChange={ (e) => handleChangeHour(2, index, e) } minutesStep={10}/> */}
                                                    <div className="flex">
                                                        <div>
                                                            <select
                                                                type="text" 
                                                                name="hourEnd" 
                                                                className="bg-white appearance-none font-bold text-base focus:outline-none"
                                                                onChange={ (e) => handleChangeHour(3, index, e) }
                                                                value={selectedHourFin[index]}
                                                            >
                                                                <option>00</option>
                                                                <option>01</option>
                                                                <option>02</option>
                                                                <option>03</option>
                                                                <option>04</option>
                                                                <option>05</option>
                                                                <option>06</option>
                                                                <option>07</option>
                                                                <option>08</option>
                                                                <option>09</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                                <option>13</option>
                                                                <option>14</option>
                                                                <option>15</option>
                                                                <option>16</option>
                                                                <option>17</option>
                                                                <option>18</option>
                                                                <option>19</option>
                                                                <option>20</option>
                                                                <option>21</option>
                                                                <option>22</option>
                                                                <option>23</option>
                                                                <option>24</option>
                                                            </select>
                                                        </div>
                                                        <div className="font-bold text-base">:</div>
                                                        <div>
                                                            <select
                                                                type="text" 
                                                                name="min-end"  
                                                                className="bg-white appearance-none font-bold text-base focus:outline-none"
                                                                onChange={ (e) => handleChangeHour(4, index, e) }
                                                                value={selectedMinFin[index]}
                                                            >
                                                                <option>00</option>
                                                                <option>10</option>
                                                                <option>20</option>
                                                                <option>30</option>
                                                                <option>40</option>
                                                                <option>50</option>
                                                            </select>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>  
                                        </div>
  
                                    </div>
                                ))
                            }

                            {
                                /*
                                <div className={`grid grid-cols-2 font-sans px-6 py-2 mt-4 ${(classMonday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Lunes</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                        <TimePicker value={selectedHourIni[0]} onChange={ (e) => handleChangeHour(1, 0, e) } format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                        <TimePicker value={selectedHourFinL} onChange={setSelectedHourFinL} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 mt-4 ${(classThuesday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Martes</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniM} onChange={setSelectedHourIniM} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinM} onChange={setSelectedHourFinM} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 my-4 ${(classWednesday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Miércoles</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniX} onChange={setSelectedHourIniX} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinX} onChange={setSelectedHourFinX} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 my-4 ${(classThursday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Jueves</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniJ} onChange={setSelectedHourIniJ} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinJ} onChange={setSelectedHourFinJ} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 my-4 ${(classFriday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Viernes</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniV} onChange={setSelectedHourIniV} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinV} onChange={setSelectedHourFinV} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 my-4 ${(classSaturday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Sábado</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniS} onChange={setSelectedHourIniS} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinS} onChange={setSelectedHourFinS} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>

                            <div className={`grid grid-cols-2 font-sans px-6 py-2 my-4 ${(classSunday) ? '':'hidden'} shadow-[0_3px_10px_2px_rgba(0,0,0,0.1)] rounded-xl`}>
                                <div className="pt-2 font-semibold text-base">Domingo</div>
                                <div className="flex bg-white w-[150px]">
                                    <div className="my-1 pl-4 pr-2 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourIniD} onChange={setSelectedHourIniD} format="HH:mm" minutesStep={10}/>
                                    </div>
                                    <div className="my-1 font-semibold text-base">a</div>
                                    <div className="my-1 pl-2 pr-4 font-semibold text-base text-gray-400">
                                    <TimePicker value={selectedHourFinD} onChange={setSelectedHourFinD} format="HH:mm" minutesStep={10}/>
                                    </div>
                                </div>    
                            </div>
                            */
                            }
                            

                            {/* <div className="">
                                <Field
                                    as="select" 
                                    type="text" 
                                    name="daysAndHours"
                                    value={gridHours}                                     
                                />
                            </div> */}

                            {/* <ErrorMessage name="days" component={() => (
                                <span className="mx-1 mt-1 text-red-600" >{errors.days}</span>
                            )} /> */}
                        </div>

                        <div className={`font-sans fixed w-full left-0 bottom-0 flex justify-between mt-0 py-2 px-5 ${classFooter}`}>
                            <div className="pt-[40px] px-2 flex">
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[24px] h-[8px] bg-[#3388FF] rounded-[100px] mr-2"></div>
                                { showStep3 && (<div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>) } 
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                            </div>
                            <div className="pt-[10px] flex">
                            <span className="mr-4 mt-[18px] text-base text-gray-400 font-bold">Siguiente</span>
                            <span>
                                <div onClick={ () => handleSave({hours: getGrid()}) } className="justify-center rounded-full w-[60px] h-[60px] border border-transparent bg-[#3388FF] py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg className="ml-2 mt-[15px] font-semibold" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z" fill="white"/>
                                    </svg>
                                </div>
                            </span>
                            </div>
                        </div>                         
                            
                    </form>
                </div>        
    </>
  )
}
