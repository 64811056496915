import Swal from 'sweetalert2'
import '../swal.css';

const sendSwal = (mainText, textConfirmButtom, textCancelButtom, isConfirmed, withCancel) => {

    Swal.fire({
        text: mainText,
        showCancelButton: withCancel,
        confirmButtonColor: '#3388FF',
        cancelButtonColor: '#A29EB6',
        confirmButtonText: textConfirmButtom,
        cancelButtonText: textCancelButtom,
        customClass: {
            confirmButton: 'custom-button',
            cancelButton: 'custom-button',
            htmlContainer:'text'
          },
        }).then((result) => {
        if (result.isConfirmed) {
            isConfirmed();
        }
        })

}

export default sendSwal;

