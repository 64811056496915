import { useState, useEffect } from "react";

export function usePersistentState(key, initialValue) {
    const fullkey = `MeducarPWA::${key}`

    const isObject = (val) => {
        return (typeof val === 'object');
    }
    const isNull = (value) => {
        return value === null || value === "null";
    }
    
    const [storedValue, setStoredValue] = useState(() => {
        try {
            let item = window.localStorage.getItem(fullkey);
            if (isNull(item) && !isNull(initialValue)) {
                window.localStorage.setItem(fullkey, isObject(initialValue) ? JSON.stringify(initialValue) : initialValue)
                return initialValue;
            }
            return item;

        } catch (err) {
            console.error(err)
            return initialValue
        }
    })

    useEffect(() => {
        try {
            window.localStorage.setItem(fullkey, isObject(storedValue) ? JSON.stringify(storedValue) : storedValue)
        } catch (err) {
            console.error(err)
        }
    }, [ storedValue ])

    return [ storedValue, setStoredValue ]
}