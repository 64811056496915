import { useState, useEffect } from "react";

export const SocialSecuritySelected = ({item, remove}) => {   
  return (
    <div className="flex my-[12px] font-sans">
      <div style={{ fontSize: 14 }} className="rounded-2xl border border-transparent bg-[#3388FF] px-4 pt-2 text-base font-semibold text-white">
        {item.name} 
      </div> 
      <div onClick={ () => remove(item.id) } className="ml-2 border border-[#3388FF] border-dashed bg-white rounded-2xl font-bold text-[#3388FF] text-xl bg-[#3388FF] pl-4 pt-1 w-[44px] h-[44px]">x</div>       
    </div>
  )
}