import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams }   from "react-router-dom";
import { useDispatch } from "react-redux";
import { newAppointment } from "../redux/actions/offices";
import { useAuth } from "../components/AuthProvider";
import 'moment/locale/es';
import Moment from 'react-moment';
import moment from 'moment';

export const ConfirmAppointment = () => {

    const { dataAppointment, handleSaveAppoinmentData }  = useAuth();
    
    const navigate   = useNavigate();
    const currentUrl = useParams();
    const dispatch = useDispatch();

    const Storage = JSON.parse(localStorage.CurrentUrl);

    const handleClose = () => {
      navigate(`/appointments/select-patient`);
    }

    const handleClick = () => {
      
      const auxDataAppointment = Object.assign({}, dataAppointment);

      let auxHour = auxDataAppointment.hour;
      if (auxHour.includes("*")) {
        auxHour = auxHour.replaceAll('*', '');
      }

      let data = {
        office_id: auxDataAppointment.office,
        consultation_type_id: auxDataAppointment.consultation,
        date: auxDataAppointment.date + " " + auxHour,
        patient_id: (auxDataAppointment.patient ? auxDataAppointment.patient : null),
        observations: auxDataAppointment.observations
      }
      if (auxDataAppointment.professional) {
        data.professional_id = auxDataAppointment.professional;
      }
      if (auxDataAppointment.overschedule) {
        data.overschedule = auxDataAppointment.overschedule;
      }
      if (auxDataAppointment.patient_data) {
        data.patient_data = { ...auxDataAppointment.patient_data };
      }

      dispatch(newAppointment(data));
      handleSaveAppoinmentData(null);
      //navigate('/appointments/');
      
      //TODO_G: Mejorar esperando la respuesta del tiro de creación. Por ahora
      // espera de 500ms para que el listado ya tome los cambios
      setTimeout(() => {
        if (Storage?.id?.id) {
          navigate(`/patients/${Storage.id.id}/events`);
        } else {
          navigate('/appointments');
        }
      }, 500);      

    }

  const handleChange = (e) => {
    handleSaveAppoinmentData({ ...dataAppointment, observations: e.target.value });
  };

  return (
    <>
      {
        dataAppointment && (
          <div className="pb-5 h-screen px-2">
            <div className="max-h-full max-w-md bg-white md:max-w-lg font-sans">
                
                <div className="sticky top-0 bg-white pt-6 pb-2">
                    <div className="mt-4 px-2 flex items-start justify-between pb-4">
                        {/* <h3 className="text-lg text-center font-medium text-gray-900"></h3> */}
                        <h3 className="text-lg leading-tight text-[#1C1243] pr-6">Nuevo {dataAppointment.overschedule == 1 ? "sobreturno" : "turno"} para <span className="font-bold capitalize">{dataAppointment.patientName}</span> el <span className="font-bold capitalize"><Moment format="dddd" locale="es">{dataAppointment.date}</Moment> <Moment format="DD" locale="es">{dataAppointment.date}</Moment>/<Moment format="MM" locale="es">{dataAppointment.date}</Moment></span> a las <span className="font-bold">{dataAppointment.overschedule == 1 ? dataAppointment.hour.replace("*","") : dataAppointment.hour }hs.</span></h3> 
                        <button className="px-1 pt-2" onClick={() => handleClose()}>                
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                            </svg>
                        </button> 
                    </div>
                </div>

                <div className="px-2 mt-4">
                
                  <div className="mb-6">

                    {/* <div className={`${dataAppointment.overschedule == 1 ? 'text-red-600 border boder-solid border-red-600 font-sans text-xl font-semibold py-4 px-4 mx-2 my-2 bg-white rounded-xl' : 'text-[#3388FF] border boder-solid border-[#3388FF] font-sans text-xl font-semibold py-4 px-4 mx-2 my-2 bg-white rounded-xl'}`} >
                      Nuevo {dataAppointment.overschedule == 1 ? "sobre turno" : "turno"} para {dataAppointment.completeName} el <Moment format="dddd" locale="es">{dataAppointment.date}</Moment> <Moment format="DD" locale="es">{dataAppointment.date}</Moment>/<Moment format="MM" locale="es">{dataAppointment.date}</Moment> a las {dataAppointment.hour}hs.
                    </div>  */}

                    <div className="md:flex justify-center items-center">
                      <label className="text-base font-semibold">Motivo de Consulta</label>
                      <textarea
                        className="textarea-style"
                        name="observations"
                        rows="4"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      className="submit-button"
                      onClick={() => handleClick()}
                    >
                      Confirmar {dataAppointment.overschedule == 1 ? "sobreturno" : "turno"}
                    </button>
                  </div>

                </div>

            </div>
          </div>
        )
      }      
    </>    
  )
}
