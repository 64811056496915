import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePersistentState } from "../hooks/usePersistentState";

import * as Sentry from "@sentry/react";

const AuthContext = React.createContext(null);

export const useAuth = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [userData, setUserData] = usePersistentState('userData', null);
    const [token, setToken] = usePersistentState("userToken", null);
    const [wizardData, setWizardData] = usePersistentState('wizardData', {});

    // Mover a otro provider
    const [ dataAppointment, setDataAppointment ] = usePersistentState('dataAppointment', null);

    const setUrl = (url) => {        
        localStorage.setItem('CurrentUrl', JSON.stringify(url));
    }

    // const setData = (data) => {
    //     const incomningData = JSON.parse(data)
    //     localStorage.setItem('dataAppointment', JSON.stringify(incomningData));
    // }

    useEffect(() => {

        if (userData) {
            console.log(userData);
            const dataSentry = {
                id: userData.id,
                email: userData.username,
                // type: userData.type,
                // admin_account: userData.admin_account,
                // only_admin: userData.only_admin
            }
            console.log('UserData -> Sentry', dataSentry);
            Sentry.setUser(dataSentry);
        }

    }, [userData]);
  
    const handleRegister = (token) => {
        
        setToken(token);

        setWizardData(null);
        navigate('/onboarding-one');
    }

    const handleLogin = (token, userData, callWizard = false) => {
        
        setToken(token);

        if (callWizard) {

            if (wizardData?.userId == userData.id) {
                if (wizardData.step) {
                    switch (wizardData.step) {
                        case 1:
                            navigate('/onboarding-two');
                            break;
                        case 2:
                            navigate('/onboarding-three');
                            break;
                        case 3:
                            navigate('/onboarding-four');
                            break;
                        case 4:
                            navigate('/onboarding-five');
                            break;
                        case 5:
                            navigate('/onboarding-six');
                            break;
                        default:
                            navigate('/onboarding-one');
                    }
                } else {
                    navigate('/onboarding-one');
                }
            } else {
                setWizardData({userId: userData.id, countryId: userData.country_id});
                navigate('/onboarding-one');
            }
            
        } else {
            navigate('/patients');
        }        
    };
  
    const handleLogout = () => {
        
        setToken();

        // navigate('/login');
        window.location.href = '/login';
    };

    const handleSaveWizardStep = (data) => {
        setWizardData({...wizardData, ...data })
    }

    const handleSaveAppoinmentData = (data) => {
        console.log('handleSaveAppoinmentData:::', data);
        setDataAppointment(data);
    }
  
    const value = {
        userData,

        setUrl,
        // setData,
        token,
        wizardData,
        handleRegister,
        handleLogin,
        handleLogout,
        handleSaveWizardStep,

        dataAppointment,
        handleSaveAppoinmentData
    };
  
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};

export default AuthProvider;