import React from "react";
import { MyAudioPlayer } from "../MyAudioPlayer";

export const Audio = (props) => {

    return (
        <div>
            <div className='text-slate-400 mb-2'>Referencia de audio</div>
            
            <div className="py-4 px-0">
                <MyAudioPlayer dataUrl={`https://cdn.meducar.com/${props.data['wAudio-9999'].src}`} />
            </div>            

            <div>
                <div className='mt-4'>
                    <p className="text-slate-400">Consulta:</p>
                    <p className="mt-2 text-md whitespace-pre-line">{props?.data['wText-9999']}</p>
                </div>
            
            </div>
        </div>
    )
}