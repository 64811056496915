import { LOGIN_START, LOGOUT, SAVE_USERDATA, UPDATE_TOKEN } from '../../consts/actionTypes';

export const login = payload => ({
    type: LOGIN_START,
    payload
});

export const logout = payload => ({
    type: LOGOUT,
    payload
});

export const saveData = payload => ({
    type: SAVE_USERDATA,
    payload
});

export const updateToken = payload => ({
    type: UPDATE_TOKEN,
    payload
});

