const isNull = (value) => {
    return value === "" || value === null || value === "null";
}

function getUser() {
    let result = localStorage.getItem('MeducarPWA::userData');
    if (!isNull(result)) {
        result = JSON.parse(result);
        if (result.secretary === undefined) {
            result.secretary = null;
        }
        if (result.professional === undefined) {
            result.professional = null;
        }
    } else {
        result = {}
    }
    return result;
}
function setUser(userData) {
    return localStorage.setItem('MeducarPWA::userData', userData);
}

function getUserPatients() {
    let result = [];
    let auxResult = localStorage.getItem('MeducarPWA::userData');
    if (!isNull(auxResult)) {
        auxResult = JSON.parse(auxResult);
        if (auxResult.patient_ids) {
            result = String(auxResult.patient_ids).split(',');
        }
    }
    return result;
}
function setUserPatient(patientData) {
    let arrPatients = [];
    if (patientData) {
        let auxPatients = localStorage.getItem('MeducarPWA::userPatients');
        if (!isNull(auxPatients)) {
            arrPatients = JSON.parse(auxPatients);
        }
        arrPatients.push(patientData);
    }
    return localStorage.setItem('MeducarPWA::userPatients', JSON.stringify(arrPatients));
}

function getCurrentOffice() {
    const aux = localStorage.getItem('MeducarPWA::currentOffice');
    if (!isNull(aux)) {
        return aux;
    }
    return null;
}
function setCurrentOffice(officeData) {
    const aux = localStorage.setItem('MeducarPWA::currentOffice', officeData);
    if (!isNull(aux)) {
        return aux;
    }
    return null;
}

function getToken() {
    const token = localStorage.getItem('MeducarPWA::userToken');
    if (!isNull(token)) {
        return token;
    }
    return '';
}
function setToken(userToken) {
    return localStorage.setItem('MeducarPWA::userToken', userToken);
}

function logout() {
    setUser('');
    setToken('');
    setCurrentOffice('');
    return true;
}

const session = {
    getUser: getUser,
    setUser: setUser,
    getUserPatients: getUserPatients,
    setUserPatient: setUserPatient,

    getCurrentOffice: getCurrentOffice,
    setCurrentOffice: setCurrentOffice,

    getToken: getToken,
    setToken: setToken,
    
    logout: logout,
};
export default session;
