import { REGISTER_START, SAVE_ONBOARDING_ONE, SAVE_ONBOARDING_TWO, SAVE_ONBOARDING_THREE, SAVE_USERDATA_REGISTER, UPDATE_TOKEN } from '../../consts/actionTypes';

export const register = payload => ({
    type: REGISTER_START,
    payload
});

export const saveOnboardingOne = payload => ({
    type: SAVE_ONBOARDING_ONE,
    payload
});

export const saveOnboardingTwo = payload => ({
    type: SAVE_ONBOARDING_TWO,
    payload
});

export const saveOnboardingThree = payload => ({
    type: SAVE_ONBOARDING_THREE,
    payload
});

export const saveData = payload => ({
    type: SAVE_USERDATA_REGISTER,
    payload
});

export const updateToken = payload => ({
    type: UPDATE_TOKEN,
    payload
});