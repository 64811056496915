export default function useFormat(seconds) {
  if (isNaN(seconds)) {
    return `0:00`;
  }

  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);

  // Asegurarse de que los segundos siempre tengan dos dígitos
  const formattedSecs = secs < 10 ? `0${secs}` : secs;

  return `${minutes}:${formattedSecs}`;
}