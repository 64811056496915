import {GET_OFFICES_START, GET_OFFICES_ERROR, GET_OFFICES_COMPLETE,
GET_SCHEDULE_START, GET_SCHEDULE_ERROR, GET_SCHEDULE_COMPLETE, GET_EVENT_OPTIONS_COMPLETE, GET_EVENT_OPTIONS_ERROR, GET_EVENT_OPTIONS_START, APPOINTMENT_DATE, SCHEDULE_RANGE_CHANGE, CREATE_NEW_PATIENT_START, CREATE_APPOINTMENT_ERROR, CREATE_APPOINTMENT_COMPLETE, CREATE_APPOINTMENT_START
, SET_CURRENT_OFFICE, NEW_APPOINTMENT_START, NEW_APPOINTMENT_ERROR, NEW_APPOINTMENT_COMPLETE, GET_TAGS_START, GET_TAGS_ERROR, GET_TAGS_COMPLETE, CHANGE_TAGS_START, CHANGE_TAGS_ERROR, CHANGE_TAGS_COMPLETE, RESET_APPOINTMENT_OPTIONS} from '../../consts/actionTypes';


const initialState = {
    isLoading: true,
    
    date: 7,

    schemaRange: { type: 'today', init: null, finish: null },
    
    schedule: null,
    
    totalEvents: 0,
    currentOffice: null,
    
    tags: null,
    inChangeTags: 0,

}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_OFFICES_START:
            return {...state, isLoading: true}
            break;
        case GET_OFFICES_ERROR:
            return {...state, isLoading: false, error: true, offices: null}
            break;
        case GET_OFFICES_COMPLETE:
            return {...state, isLoading: false, error: false, offices: action.results.data.data}
            break;
        case GET_SCHEDULE_START:
            return {...state, isLoading: true}
            break; 
        case GET_SCHEDULE_ERROR:
            return {...state, isLoading: false, error: true}
            break;
        case GET_SCHEDULE_COMPLETE:
            if(action.results.data.data == null) {
                return {...state, isLoading: false, error: false, schedule: null, totalEvents: null}
            } else {
                return {...state, isLoading: false, error: false, schedule: action.results.data.data.data, totalEvents: action.results.data.data.total}
            }
            //Pueden venir varias paginas, revisar
            
            break;
        case GET_EVENT_OPTIONS_START:
            return {...state, isLoading: true}
            break;
        case GET_EVENT_OPTIONS_COMPLETE:
            return {...state, isLoading: false, error: false, eventOptions: action.results.data}
            break;
        case GET_EVENT_OPTIONS_ERROR: 
            return {...state, isLoading: false, error: action.error}
            break;
        
        
        case APPOINTMENT_DATE:
            return {...state, date: action.payload, schedule: null}
            break;
        case SCHEDULE_RANGE_CHANGE:
            return {...state, schemaRange: action.payload, schedule: null}
            break;

        case CREATE_APPOINTMENT_START:
            return {...state, isLoading: true}
            break;
        case CREATE_APPOINTMENT_ERROR:
            return {...state, isLoading: false, error: true}
            break;
        case CREATE_APPOINTMENT_COMPLETE:
            //Agregar schedule al existente
            return {...state, isLoading: false, scheduleOptions: action.results.data.data.data}
            break
        case RESET_APPOINTMENT_OPTIONS:
            return {...state, isLoading: false, scheduleOptions: null}

        case SET_CURRENT_OFFICE:
            return {...state, currentOffice: action.payload}

        case NEW_APPOINTMENT_START:
            return {...state, isLoading: true, error: false}
        case NEW_APPOINTMENT_ERROR:
            return {...state, isLoading: false, error: action.error}
        case NEW_APPOINTMENT_COMPLETE:
            return {...state, isLoading: false, error: false}

        case GET_TAGS_START:
            return {...state, isLoading: true, error: false}
        case GET_TAGS_ERROR:
            return {...state, isLoading: false, error: action.error}
        case GET_TAGS_COMPLETE:
            return {...state, isLoading: false, error: false, tags: action.results.data.data}

        case CHANGE_TAGS_START:
            return {...state, inChangeTags: true }
        case CHANGE_TAGS_ERROR:
            return {...state, error: action.error}
        case CHANGE_TAGS_COMPLETE:
            return {...state, tags: action.results.data.data.data, inChangeTags: false }

        default:
            return {...state}
    }
}