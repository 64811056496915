import { useEffect, useState } from 'react';
import { useNavigate }   from "react-router-dom";
import { useSelector } from "react-redux";
import { useAudio } from "../components/AudioProvider";
import CoverLoading from '../components/CoverLoading';
import { toast } from 'react-toastify';
import { apiCall, headerWithLocal } from "../redux/api";
import '../styles/audio.css'
import session from '../utils/session';
import sendSwal from '../middlewares/sendSwal'
import Stars from '../assets/stars.svg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { MyAudioPlayer } from '../components/MyAudioPlayer';

export const ManageAudio = (props) => {
  const navigate   = useNavigate();
  const userData = session.getUser();
  const  {audioRecord}  = useAudio();
  const StorageId = JSON.parse(localStorage.CurrentUrl);
  const StorageAudio = audioRecord.audioRecord;
  const patient = useSelector(state => state.patients.patient);

  const [ loadingTranscript, setLoadingTranscript ] = useState(false);
  const [ transcript, setTranscript ] = useState(null);
  const [ transcriptEnhanced, setTranscriptEnhanced ] = useState(null);
  const [ loadingTranscriptEnhanced, setLoadingTranscriptEnhanced ] = useState(false);
  const [ transcriptSaved, setTranscriptSaved ] = useState(null);
  const [ showTranscribeButtom, setShowTranscribeButtom ] = useState(true);
  const [ audioUrl, setAudioUrl ] = useState("");
  const [ audioId, setAudioId ] = useState(null);
  
  useEffect(() => {
      generateAudio()
      // console.log("userData",userData)

  }, []);

  const handleClose = (id) => {

    let mainText = "Quieres descartar el audio?"
    let textConfirmButtom = "Confirmar"
    let textCancelButtom ="Cancelar"

    sendSwal(mainText, textConfirmButtom, textCancelButtom, (id) => backToUpload(id), true);

  }

  const generateAudio = () => {

    // Decodificar el contenido base64
    const binaryData = atob(StorageAudio.audio);
    const mimeType = StorageAudio.mimeType ? StorageAudio.mimeType : "audio/webm";

    // Crear un array de bytes a partir del contenido binario
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }

    // Crear un Blob a partir del array de bytes
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });  // Reemplaza "audio/wav" con el tipo MIME correcto

    // Crear una URL del Blob
    setAudioUrl(URL.createObjectURL(blob));

    // Puedes usar audioUrl para reproducir el audio o realizar otras operaciones

  }

  const backToUpload = (id) => {
    localStorage.setItem("MeducarPWA::audioRecord", null)
    navigate(`/patients/${id}/hc/upload-audio`)
  }

  const saveEvolution = () => {
    if (transcript || transcriptEnhanced) {
      saveTranscription();
    } else {
      uploadAudio();
    }
    localStorage.setItem("MeducarPWA::audioRecord", null);
  }

  const uploadAudio = () => {

    let mainText = "Quieres guardar el archivo de audio?"
    let textConfirmButtom = "Confirmar"
    let textCancelButtom ="Cancelar"

    sendSwal(mainText, textConfirmButtom, textCancelButtom, saveOnlyAudio, true);


  }

  const transcribeAudio = async () => {
    
    //setShowTranscribeButtom(false);
    setLoadingTranscript(true);

    const auxRes = await apiCall(`/api/patients/${patient.id}/uploads/audio`, StorageAudio, null, 'POST');
    console.log(auxRes);

    setAudioId(null);

    if (auxRes?.status == 200) {
      if (auxRes.data?.data?.detail?.id) {
        setAudioId(auxRes.data.data.detail.id);

        const auxTranscriptionRes = await apiCall(`/api/patients/${patient.id}/audios/${auxRes.data.data.detail.id}/transcript`, null, headerWithLocal(), 'POST');
        console.log(auxTranscriptionRes);

        if (auxTranscriptionRes?.status == 200) {

            if (auxTranscriptionRes.data?.data?.detail?.result) {
                console.log(auxTranscriptionRes.data.data.detail.result);
                
                setTranscript(auxTranscriptionRes.data.data.detail.result);
                setTranscriptSaved(auxTranscriptionRes.data.data.detail.result);
                setShowTranscribeButtom(false);

            }
          
        } else {
          toast.error("No se pudo transcribir el audio. ERROR: " + auxTranscriptionRes.statusText);
        }
        
      }

    } else {
      toast.error("No se pudo guardar el audio. ERROR: " + auxRes.statusText);
    }

    setLoadingTranscript(false);  

  }

  const saveOnlyAudio = async () => { 

    const auxRes = await apiCall(`/api/patients/${patient.id}/uploads/audio`, StorageAudio, null, 'POST');
    console.log(auxRes);

    setAudioId(null);

    if (auxRes?.status == 200) {
      if (auxRes.data?.data?.detail?.id) {
        setAudioId(auxRes.data.data.detail.id);

        const dataPost = {
          audio_id: auxRes.data.data.detail.id,
          text: null,
          text_enhanced: null,
          text_saved: null
        }
    
        const auxRes2 = await apiCall(`/api/patients/${patient.id}/consultations`, dataPost, null, 'POST');
    
        if (auxRes2?.status == 200) {
          
          navigate(`/patients/${patient.id}/hc`);
          
        } else {
          toast.error("No se pudo guardar el audio. ERROR: " + auxRes2.statusText);
        }
      }

      if (auxRes.data?.data?.detail?.url) {
        setAudioUrl(auxRes.data.data.detail.url)
      }

    } else {
      toast.error("No se pudo guardar el audio. ERROR: " + auxRes.statusText);
    }    

  }

  const saveTranscription = async () => {    
    
    const dataPost = {
      audio_id: audioId,
      text: transcript,
      text_enhanced: transcriptEnhanced,
      text_saved: transcriptSaved
    }

    const auxRes = await apiCall(`/api/patients/${patient.id}/consultations`, dataPost, null, 'POST');

    if (auxRes?.status == 200) {
      
      navigate(`/patients/${patient.id}/hc`);
      
    } else {
      toast.error("No se pudo guardar el audio. ERROR: " + auxRes.statusText);
    }

  }

  const improveText = async () => {

    setLoadingTranscriptEnhanced(true);

    const dataPost = {
      text: transcript,
      profession: userData.professional.speciality.name,
      nationality: userData.country.name
    }

    const auxRes = await apiCall(`/api/transcriptions/enhance`, dataPost, headerWithLocal(), 'POST');
    console.log("auxRes", auxRes); 

    if (auxRes?.status == 200) {
    
      if (auxRes.data?.data?.detail?.result) {
          console.log(auxRes.data.data.detail.result);
          
          setTranscriptEnhanced(auxRes.data.data.detail.result);
          setTranscriptSaved(auxRes.data.data.detail.result);
          
      }
    
    } else {
      toast.error("No se pudo mejorar el audio el audio. ERROR: " + auxRes.statusText);
    }

    setLoadingTranscriptEnhanced(false);
  }

  const playIcon = (
    <svg height="36" width="36" viewBox="0 0 24 24">
      <path fill="#6b7280" d="M8 5v14l11-7z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );

const pauseIcon = (
  <svg height="36" width="36" viewBox="0 0 24 24">
      <path fill="#6b7280" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      <path d="M0 0h24v24H0z" fill="none" />
  </svg>
  );
const loopOffIcon = (
    <svg height="16" width="16" viewBox="0 0 24 24">
      <path fill="none" d="M10 16.5l1.5 1.5 5.5-5.5-5.5-5.5-1.5 1.5 2.5 2.5H2v2h10.5l-2.5 2.5zm-6-9l-1.5-1.5L12 2l1.5 1.5-2.5 2.5H22v2H10.5l2.5 2.5L12 10l-8-8zm8 14l1.5 1.5 8-8-1.5-1.5-2.5 2.5H2v-2h14.5l-2.5-2.5L12 14l-8 8z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );

const loopIcon = (
    <svg height="16" width="16" viewBox="0 0 24 24">
        <path fill="none" d="M7.77 8.55l-2.12-2.12a4 4 0 0 1 5.66-5.66l1.41 1.41a1 1 0 0 0 1.41-1.41l-1.41-1.41a6 6 0 0 0-8.48 8.48l2.12 2.12a1 1 0 0 0 1.41-1.41zm8.48 6.36l2.12 2.12a4 4 0 0 1-5.66 5.66l-1.41-1.41a1 1 0 0 0-1.41 1.41l1.41 1.41a6 6 0 0 0 8.48-8.48l-2.12-2.12a1 1 0 0 0-1.41 1.41z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );

  const handleCanPlay = (e) => {
    e.target.pause();
  };

  return (
    <>
      <CoverLoading loading={ loadingTranscript || loadingTranscriptEnhanced } />

      <div className="h-screen justify-between items-center font-sans mt-6">
        <div id='uno' className="grid grid-cols-2 items-start py-4 font-sans">
            <h3 className="pl-[10%] w-[350px] text-lg text-[#1C1243]">
              <span className="">
                Evolución de Paciente:{' '}
                <div className="font-bold capitalize">
                  {patient.surname}, {patient.name}
                </div>
              </span>
            </h3>
            <button className='mr-4' onClick={() => handleClose(StorageId.id.id)}>
              <h3 className="pl-[90%] pt-[10px] text-lg font-medium text-[#1C1243]">
                <svg
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z"
                    fill="#1C1243"
                  />
                </svg>
              </h3>
            </button>
          </div>

          <div id='dos' style={{ height: "calc(100vh - 112px)" }} className='h-screen'>

          <div className="flex justify-between items-center mx-4 gap-2">
            <div className='w-[80vw]'>
              <MyAudioPlayer dataUrl={audioUrl} />
            </div>
            <div className='w-10'>
              <button
                className="w-10 h-10 rounded-xl text-white my-1 py-1 px-2 pointer bg-red-600"
                onClick={(id) => handleClose(id)} >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" stroke='white' fill='white' className='w-6 h-4'><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
              </button>
            </div>              
          </div> 

          {showTranscribeButtom &&
          <div className="mt-4 text-center">
            <div className='mx-auto flex justify-around font-sans border border-1 border-blue-500 rounded-xl w-[190px] h-[48px]'>
              <img className="ml-2 mt-[12px] h-6 w-6" src={ Stars } alt="Stars" />
              <button
              className="mr-3 text-xs text-blue-500 bg-white font-semibold"
              onClick={() => transcribeAudio()}
              disabled={`${ loadingTranscript ? 'disabled' : '' }`}
              >
              {!loadingTranscript ? 'Transcribir audio con IA' : 'Transcribiendo...'}
              </button>
            </div>
          </div>
          }

          <div className='flex flex-col text-[#1C1243] mt-4'>
            <div className='mx-4 font-semibold'>Transcripción</div>
            <div className='w-full px-4 py-2'>
              <textarea
                className='w-full border border-gray-300 bg-white h-[48vh] rounded-xl p-2'
                value={ transcriptSaved ? transcriptSaved : '' }
                onChange={(e) => setTranscriptSaved(e.target.value)}
              />
            </div>

            {(transcriptSaved && !transcriptEnhanced) &&
            <div className="text-center">
              <div className='mx-auto px-2 flex justify-around font-sans rounded-xl w-[180px] h-[30px]'>
                <img className="ml-2 mt-[4px] h-6 w-6" src={ Stars } alt="Stars" />
                <button
                className="text-xs text-blue-500 bg-white font-semibold"
                disabled={`${ loadingTranscriptEnhanced ? 'disabled' : '' }`}
                onClick={() => improveText()}
                >
                {loadingTranscriptEnhanced? 'Mejorando texto...' : 'Mejorar texto con IA'}
                </button>
              </div>
            </div>
            }

            <div className='flex mt-2 justify-end mx-4'>
              <button
                className="submit-button"
                onClick={() => saveEvolution()}
                >
                Guardar evolución
              </button>
            </div>          
          </div>
      </div>
      </div>
  </>
  )
}