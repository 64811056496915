import React, { useEffect, useState, useRef, useMemo } from 'react';
import {useDispatch, useSelector}             from 'react-redux';
import { useNavigate }                        from 'react-router-dom';
import { makeStyles }                         from '@material-ui/core/styles';


import InfiniteListPrescriptions from '../components/InfiniteListPrescriptions';
import SectionLoading            from '../components/SectionLoading';
import SectionEmpty              from '../components/SectionEmpty';
import { SelectPatient }         from '../components/SelectPatient';
import { useAuth }               from "../components/AuthProvider";

import { getPrescriptions }            from '../redux/actions/prescriptions';
import { setPageTitle, setShowHeader } from '../redux/actions/layout';

import { prescriptionsCurrentPage, prescriptionsLastPage, prescriptionsResult } from '../redux/selectors';

import session from '../utils/session';

const Prescriptions = ({ loggedIn }) => {

    const { setUrl }  = useAuth();
    const navigate    = useNavigate();
    const dispatch    = useDispatch();
    const inputSearch = useRef(null);

    const [ loadCompleted, setLoadCompleted ] = useState(false);

    const prescriptions      = useSelector((state) => prescriptionsResult(state));
    const totalPrescriptions = useSelector((state) => state.prescriptions.totalPrescriptions);
    const dataCurrentPage    = useSelector((state) => prescriptionsCurrentPage(state));
    const lastPage           = useSelector((state) => prescriptionsLastPage(state));
    const loading            = useSelector((state) => state.prescriptions.isLoading);

    const [pageNumber, setPageNumber]               = useState(1);
    const [name, setName]                           = useState("");
    const [hasMore, setHasMore]                     = useState(false);
    const [isSearch, setIsSearch]                   = useState(false);
    const [showSelectPatient, setShowSelectPatient] = useState(false);    

    let time = 0;

    useEffect(() => {

        getData(1, '', true);
        dispatch(setPageTitle(getTitle()));

        if (!loading && !loadCompleted) {
            setLoadCompleted(true);
        }

        setUrl({id:"", section:""})

    }, []);

    const items = useMemo(() => {
        if (!prescriptions) return [];
        const _items = prescriptions.filter(element => {
            return element !== null && element !== undefined;
          });
          return _items;
    }, [ prescriptions ])

    useEffect(() => {
        dispatch(setPageTitle(getTitle()));
        dispatch(setShowHeader(true));
    }, [totalPrescriptions]);

    useEffect(() => {
        setHasMore(dataCurrentPage < lastPage);
    }, [prescriptions]);
    
    useEffect(() => {
        if (pageNumber > 1) {
          if (prescriptions.length > 0) {
            getData(pageNumber);
          }
        }
    }, [pageNumber]);
    
    useEffect(() => {
        if (name !== "") {
          getData(1, name);
        }
    }, [name]);
    
    useEffect(() => {
        if (!loading && isSearch) {
          setIsSearch(false);
        }
    }, [loading]);

    const handleChange = (e) => {
        let finalValue = "";
        const value = e.target.value;
        if (time) clearTimeout(time);
    
        time = setTimeout(() => {
          finalValue = value.replace(/ /g, "_");
          if (finalValue == "") {
            getData();
            setIsSearch(false);
          } else {
            setIsSearch(true);
          }
          setName(finalValue);
        }, 250);
    };

    const handleClearName = () => {
        inputSearch.current.value = "";
        setName("");
        setIsSearch(false);
        getData();
    };

    const handleClickPrescription = (id) => {
        navigate(id);
    }

    const handleClickNewPrescription = () => {
        handleShowSelectPatient()
        //navigate("/prescriptions/new");
    }

    const handleShowSelectPatient = () => {
        navigate(`/prescriptions/select-patient`);
      }

    const getData = (pageNumber = 1, newName = "", reset = false) => {
        let Data = {
          name: newName,
          limit: 50,
          pageNumber,
          reset
        };
        dispatch(getPrescriptions(Data));
    };

    const getTitle = () => {
        return (
          <div className="px-0 font-sans text-xl">
            Recetas
            {totalPrescriptions > 0 && (
                <span>{` (${totalPrescriptions})`}</span>
            )}
          </div>
        );
    };

    return(
        <>
            {
                loadCompleted && (
                    <div className="mt-[80px] pt-[0px] bg-white px-2">

                        <div className="flex-none ml-2">
                            <button onClick={handleClickNewPrescription} className="bg-[#3388FF] text-white text-lg w-14 h-14 rounded-full fixed right-[30px] bottom-[105px] shadow-xl">
                                <div className="pl-[10px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 36 24" fill="none" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"></path><path d="M14 3v5h5M12 "></path>
                                        <line x1="30" y1="5" x2="30" y2="13"></line><line x1="26" y1="9" x2="34" y2="9"></line>
                                    </svg>
                                </div>
                            </button>
                        </div>                  

                        {/* <div className="ml-4 mt-4 font-sans text-base text-[#1C1243] font-bold">    
                            Listado de recetas
                        </div> */}

                        <div className="max-h-full max-w-md overflow-scroll mx-auto md:max-w-lg mt-2 pb-[85px]">
                            <div className="md:flex">
                                <div className="w-full p-0">
                                <div className="relative">
                                    {/* <input ref={ inputSearch } type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md" placeholder="Buscar..." onChange={(e) => handleChange(e)} /> */}

                                    {/* {
                                                name
                                                ?
                                                <i className="far fa-times-circle absolute right-3 top-4 text-red-400" onClick={ () => handleClearName() }></i>
                                                :
                                                <i className="fas fa-search absolute right-3 top-4 text-gray-300"></i>
                                            }                       */}

                                    { !isSearch && !loading && name && totalPrescriptions > 0 && (
                                    <div className="flex justifyenter">
                                        <div className="font-sans flex mt-2 text-gray-400">
                                        {`${totalPrescriptions} resultado${
                                            totalPrescriptions > 1 ? "s" : ""
                                        } para la búsqueda`}
                                        </div>
                                    </div>
                                    )}

                                    { isSearch && loading && name && (
                                    <div className="flex justify-center">
                                        <div className="font-sans flex mt-2 text-gray-400">
                                        Buscando{" "}
                                        <span className="font-sans font-bold text-black ml-1">{name}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                
                                <div className="mt-0">
                                    <ul>
                                        <InfiniteListPrescriptions
                                            name={name}
                                            data={items}
                                            hasMore={hasMore}
                                            setPageNumber={setPageNumber}
                                            loading={loading}
                                            isSearch={isSearch}
                                            getData={getData}
                                            handleClickPrescription={(id) => handleClickPrescription(id)}
                                        />
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>

                        <SectionEmpty show={ loadCompleted && name == '' && items.length === 0 } title={ 'No se encontraron resultados' } />
                            
                        <SectionEmpty show={ (loadCompleted && name != '' && totalPrescriptions == 0) } title={ 'No se encontraron resultados' } subtitle={ `al buscar "${name}"` } />
                        <SectionEmpty show={ (loadCompleted && name == '' && totalPrescriptions == 0) } title={ 'No se encontraron resultados' } />

                    </div>        
                )
            }      
            <SectionLoading loading={ (!loadCompleted && loading) }/>            
            
            {/* {showSelectPatient && (
            <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={() => handleShowSelectPatient()}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-[80vw] h-[185px] py-1 px-2 mx-auto bg-white rounded-xl shadow-lg">
                            <div className="sm:flex">
                                <div className="text-center sm:ml-4 sm:text-left">
                                    <div className="items-center gap-2 my-3 sm:flex text-[#1C1243]">
                                        <SelectPatient section={"Receta"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )} */}
        </>
    )
}
export default Prescriptions;
