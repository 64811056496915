import { SET_CURRENT_PAGE, SET_PAGE_TITLE, OPEN_SIDEBAR_START, OPEN_SIDEBAR_COMPLETE, SET_BACK_BUTTON, SET_SHOW_HEADER, SHOW_FOOTER_MENU} from '../../consts/actionTypes';

const initialState = {
    previousPage: '',
    currentPage: '',
    sidebar: false,
    title: '',
    backButton: false,
    showHeader: true,
    showFooterMenu: false
};


export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CURRENT_PAGE:
            let auxC = state.currentPage;
            return {...state, previousPage: auxC, currentPage: action.payload}
            break;
        case SET_PAGE_TITLE:
            return {...state, title: action.payload}
            break;
        case OPEN_SIDEBAR_START:
            return {...state}
            break;
        case OPEN_SIDEBAR_COMPLETE:
            return {...state, sidebar: !action.payload}
            break;
        case SET_BACK_BUTTON:
            return {...state, backButton: action.payload}
            break;
        case SET_SHOW_HEADER:
            return {...state, showHeader: action.payload}
            break;
        case SHOW_FOOTER_MENU:
            return {...state, showFooterMenu: action.payload}
            break;
        default:
            return {...state}
    }
}