import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setBackButton, openSidebar } from "../redux/actions/layout";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import { useLocation } from 'react-router-dom';

import { useStylesApp } from "../appStyles";

import { changeDate } from "../redux/actions/offices";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "black",
  },
}));

export default function HeaderTransparent() {
  const location = useLocation();
  const dispatch = useDispatch();

  const sectionsWithFooter = [
    'patients',
    'appointments',
    'prescriptions'
  ];

  const pageTitle = useSelector((state) => state.layout.title);
  const sidebarOpen = useSelector((state) => state.layout.sidebar);

  // const showHeader = useSelector((state) => state.layout.showHeader);

  const test = useSelector((state) => state);

  const classes = useStylesApp();

  const classAppBar = clsx(
    // classes.appBarTransparent,
    sidebarOpen && classes.appBarShift
  );
  // const classToolbar = clsx(
  //     classes.toolbarTransparent,
  //     !showHeaderTransparent && classes.hidden
  // );
  const classMenuButton = clsx(
    classes.menuButtonToolbarTransparent,
    sidebarOpen && classes.menuButtonHidden
  );

  const actualPath = useMemo(() => {
    let path = location.pathname;
    if (path.substr(-1) === "/") {
        path = path.slice(0, -1)
    }
    return path
  }, [location])

  const showHeader = useMemo(() => {
    return sectionsWithFooter.some(el => { return ('/' + el) === actualPath});
  }, [actualPath])

  const handleSidebar = () => {
    dispatch(openSidebar(sidebarOpen));
  };

  const handleDateChange = (e, value) => {
    dispatch(changeDate(value));
  };

  if (!showHeader) {
    return null;
  } else {
    return (
      <>
        <div className="absolute top-0 left-0 w-full h-[80px] z-[1]">
          {/* <Toolbar className={ classToolbar }>
                  <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={ handleDrawer }
                      className={ classMenuButton }
                  >
                      <MenuIcon />
                  </IconButton>
                  <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>

                  </Typography>
                  <IconButton color="inherit">
                      <Badge badgeContent={4} color="secondary">
                      <NotificationsIcon />
                      </Badge>
                  </IconButton>
              </Toolbar> */}
              <div className="navbar h-[80px] pb-0 text-[#1C1243] bg-[#FFF]">
            {/* <div className="flex-none">
              <button
                className="btn btn-square btn-ghost"
                onClick={handleSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div> */}
            <div className="flex-1 px-2 mx-2 text-2xl font-bold pt-4">
              {pageTitle}
            </div>
            {/* <div className="flex-none">
                      <button className="btn btn-square btn-ghost">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">      
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>                    
                      </svg>
                      </button>
                  </div> */}

            {pageTitle == "Turnos" ? (
              <div className="dropdown dropdown-left">
                <button className="btn btn-square btn-ghost">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-6 h-6 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    ></path>
                  </svg>
                </button>
                <ul
                  tabIndex="0"
                  className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-40"
                >
                  <li>
                    <a onClick={(e) => handleDateChange(e, 0)}>Hoy</a>
                  </li>
                  <li>
                    <a onClick={(e) => handleDateChange(e, 7)}>Semana</a>
                  </li>
                  <li>
                    <a onClick={(e) => handleDateChange(e, 30)}>Mes</a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </>      
    );
  }
}
