const assignColor = (dni) => {
    
    //Guardo por si queremos volver a las iniciales
    // let alphabet= ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    // let initialSurname = surname.substring(0,1).toLowerCase()
    // let initialName = name.substring(0,1).toLowerCase();

    // const convertToNumber = (init) => {
        
    //     let num = null ;

    //     for (let i=0 ; i < alphabet.length ; i++) {
    //         if (alphabet[i] == init) {
    //             num = i+1;
    //         }
    //     } 

    //     return num
    // }

    let colorType = parseInt(dni, 10) % 6;
    
    let color = "";

    switch (colorType) {
        case 0:  
            color = "#E7454F"
          break;
        case 1: 
            color = "#C2A53E"
          break;
        case 2: 
            color = "#005B9A"
          break;
        case 3: 
            color = "#6DB8A3"
          break;
        case 4: 
            color = "#8D42A0"
          break;
        case 5: 
            color = "#95A559"
          break;
        default:
            color = "#C2A53E"

    }

    return color;
  }

  export default assignColor;