import React from 'react';
import { useRef, useEffect, useState } from "react";


import { useNavigate } from "react-router-dom";

import { apiCall } from "../redux/api";

import { useAuth } from "../components/AuthProvider";

import sendSwal from '../middlewares/sendSwal'
import verifyImage from '../img-2.png'

import { toast } from 'react-toastify';

export const OnboardingFive = () => {

    const hFooter = 125;
    const classFooter = `h-[${hFooter}px] bg-white`;
    
    const { token, wizardData, handleSaveWizardStep } = useAuth();

    const [inFocus, setInFocus] = useState([true, false, false, false]);
    const [isError, setIsError] = useState(false);

    const [inputOne, setInputOne] = useState('');
    const [inputTwo, setInputTwo] = useState('');
    const [inputThree, setInputThree] = useState('');
    const [inputFour, setInputFour] = useState('');
    
    const [showStep3, setShowStep3] = useState(true);

    const inputOneRef = useRef();
    const inputTwoRef = useRef();
    const inputThreeRef = useRef();
    const inputFourRef = useRef();
    const buttonRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            window.location.href = '/login';
        }

        if (wizardData?.countryId != 6) {
            setShowStep3(false);
        }

        inputOneRef.current.focus();
    }, []);

    useEffect(() => {
        if (inFocus[0]) {
            inputOneRef.current.focus();
        }
        if (inFocus[1]) {
            inputTwoRef.current.focus();
        }

        if (inFocus[2]) {
            inputThreeRef.current.focus();
        }

        if (inFocus[3]) {
            inputFourRef.current.focus();
        }

        if (!inFocus[0] && !inFocus[1] && !inFocus[2] && !inFocus[3]) {
            buttonRef.current.focus();
        }
        
    }, [inFocus]);

    const goToOnboardingFour = (data) => {
        navigate(`/onboarding-four`)
    }

    const goToOnboardingSix = (data) => {
        navigate(`/onboarding-six`)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        const numOne= e.target.numOne.value;
        const numTwo= e.target.numTwo.value; 
        const numThree= e.target.numThree.value; 
        const numFour= e.target.numFour.value; 
        
        const regexNum = /[0-9]/;

        if ((!regexNum.test(numOne) || 
            !regexNum.test(numTwo) ||
            !regexNum.test(numThree) ||
            !regexNum.test(numFour)) && 
            numOne && 
            numTwo && 
            numThree && 
            numFour) 
        {
            setIsError(true);
            resetForm();
            return
        }

        if (numOne && numTwo && numThree && numFour) {
            const code = (1000 * parseInt(numOne)) + (100 * parseInt(numTwo)) + (10 * parseInt(numThree)) + parseInt(numFour);
            // console .log(code);

            const isValid = await validateCode(code);
                
            if (isValid && isError == false) {
                let dataSave = {}
                dataSave.step = 5;
                if (wizardData?.step) {
                    dataSave.step = Math.max(dataSave.step, wizardData?.step);
                }
                dataSave.code = code;
                // console.log(dataSave);
                handleSaveWizardStep(dataSave);
                goToOnboardingSix();
            } else {
                if (!isValid) {
                    inputOneRef.current.value = '';
                    inputTwoRef.current.value = '';
                    inputThreeRef.current.value = '';
                    inputFourRef.current.value = '';
                }
            } 
        }
    }

    const validateCode = async (code) => {
        let result = false;
        const auxRes = await apiCall(`/api/auth/validate-code`, {code}, null, 'POST');
        if (auxRes?.status == 200) {
            // console.log(auxRes);
            result = auxRes.data.data;
            if (!result) {
                toast.error("No se pudo validar el código. Por favor, reintente.");
            }
        } else {
            toast.error("No se pudo validar el código. Por favor, reintente.");
        }
        return result
    }
    
    const passFocus = (e, idx) => {
        if (idx == "one") {
            setInputOne(e.target.value)
            setInFocus([false, true, false, false])
            setIsError(false);
        } else if (idx == "two") {
            setInputTwo(e.target.value)
            setInFocus([false, false, true, false])
        } else if (idx == "three") {
            setInputThree(e.target.value)
            setInFocus([false, false, false, true])
        } else if (idx == "four") {
            setInputFour(e.target.value)
            setInFocus([false, false, false, false])
        }
    }

    const resetForm = () => {
        setInputOne('');
        setInputTwo('');
        setInputThree('');
        setInputFour('');
        setInFocus([true, false, false, false])
    }
   

  return (
    <>
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 font-sans">
        <div className={`w-full max-w-md space-y-8`}>
            <div onClick={ () => goToOnboardingFour() } className="fixed top-[67px] left-[33px]">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="black"/>
                </svg>
            </div>

            <div className="mt-[137px] grid justify-items-center"><img src={verifyImage} /></div>

            <div className="font-sans">
                <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-[#1C1243]">Código de validación</h2>
                <h4 className="mt-4 text-center text-sm font-semibold tracking-tight text-gray-400">Ingrese el código que le enviamos al Whatsapp.</h4>
                <h4 className="text-center text-sm font-semibold tracking-tight text-gray-400">{ wizardData.telephone }</h4>
            </div>
                    <form onSubmit={submitHandler} className="space-y-6" action="#" method="POST">
                        <input type="hidden" name="remember" value="true" />
                                        
                            <div className="my-4 grid justify-items-center">                             
                                <div className="flex justify-start font-sans">

                                    <input 
                                    ref={inputOneRef}
                                    type="text" 
                                    name="numOne" 
                                    className="mr-2 rounded-2xl w-[70px] h-[70px] border border-gray-300 block text-4xl font-bold text-center focus:outline-none" 
                                    onChange={(e)=>passFocus(e, "one")}
                                    onFocus={() => setInputOne('')}
                                    value={inputOne}
                                    /> 
                                
                                    <input 
                                    ref={inputTwoRef}
                                    type="text" 
                                    name="numTwo" 
                                    className="mr-2 rounded-2xl w-[70px] h-[70px] border border-gray-300 block text-4xl font-bold text-center focus:outline-none" 
                                    onChange={(e)=>passFocus(e, "two")}
                                    onFocus={() => setInputTwo('')}
                                    value={inputTwo}
                                    />

                                    <input 
                                    ref={inputThreeRef}
                                    type="text" 
                                    name="numThree" 
                                    className="mx-2 rounded-2xl w-[70px] h-[70px] border border-gray-300 block text-4xl font-bold text-center focus:outline-none" 
                                    onChange={(e)=>passFocus(e, "three")}
                                    onFocus={() => setInputThree('')}
                                    value={inputThree}
                                    />

                                    <input 
                                    ref={inputFourRef}
                                    type="text" 
                                    name="numFour" 
                                    className="ml-2 rounded-2xl w-[70px] h-[70px] border border-gray-300 block text-4xl font-bold text-center focus:outline-none" 
                                    onChange={(e)=>passFocus(e, "four")}
                                    onFocus={() => setInputFour('')}
                                    value={inputFour}
                                    />

                                </div>
                            </div>

                            { isError && 
                                <span className="font-sans mx-1 mt-1 text-red-600" >Solo puedes ingresar valores numéricos entre 0 y 9</span>
                            }                  
                        
                        <div className="w-full px-[20px] py-2 bg-white">
                            <button ref={buttonRef} type="submit" className="w-full justify-center rounded-2xl border border-transparent bg-[#3388FF] py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Validar</button>
                        </div>

                        {/* <div className={`font-sans fixed w-full left-0 bottom-0 flex justify-between mt-0 py-2 px-5 ${classFooter}`}>
                            <div className="pt-[40px] px-2 flex">
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                { showStep3 && (<div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>) } 
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[24px] h-[8px] bg-[#3388FF] rounded-[100px] mr-2"></div>
                            </div>
                            <div className="pt-[10px]">
                            <span className="mr-4 text-base text-white font-bold">Siguiente</span>
                            <span>
                                <button type="submit" className="justify-center rounded-full w-[60px] h-[60px] border border-transparent bg-white py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg className="ml-2 font-semibold" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z" fill="white"/>
                                    </svg>
                                </button>
                            </span>
                            </div>
                        </div>  */}
                    </form>
        </div>
    </div>
    </>
  )
}