import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiCall } from "../redux/api";
import { toast } from 'react-toastify';
import Select  from 'react-select';
import { usePrescriotion } from "../components/PrescriptionProvider";
import { useAuth } from "../components/AuthProvider";

import convertToDni from "../middlewares/convertToDni";

import 'moment/locale/es';
import Moment from 'react-moment';
import moment from 'moment';
  
export const SelectPatient = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { dataAppointment, handleSaveAppoinmentData }  = useAuth();

    // const patients = useSelector((state) => patientsResult(state));
    const { handleSelectPatient } = usePrescriotion();

    // const [patientId, setPatientId] = useState("");
    const [ optionsPats, setOptionsPats ] = useState([]);
    const [ searchPat, setSearchPat ] = useState('');
    const [ timeInputPatient, setTimeInputPatient ] = useState(0);
    const [ showMessage, setShowMessage ] = useState(false);

    const [ patientTmp, setPatientTmp ] = useState(null);
    const [requiredFields, setRequiredFields] = useState({
        name: true
    });

    useEffect(() => {
        setPatientTmp(null);
    }, []);

    const goToCreate = () => {

        // if ( section == "Turno" ) {
        if (dataAppointment?.patient) {
            navigate(`/appointments/${dataAppointment.patient}/confirm-new-appointment`);
        } else if (patientTmp?.name) {
            handleSaveAppoinmentData({ ...dataAppointment, patient_data: patientTmp });
            navigate(`/appointments/-1/confirm-new-appointment`);
        } else {
            setShowMessage(true)
        }

        //} else if ( section == "Receta" ) {
         //   navigate(`/prescriptions/${patientId}/new`);
        //}        
    }

    const handleInputChangePat = (newValue, actionMeta) => {
    
        if (timeInputPatient) {
            clearTimeout(timeInputPatient);            
        }
        setTimeInputPatient(setTimeout(() => {
            getData(newValue);            
        }, 250));
    
        setSearchPat(newValue);    
    }
    const handleChangePat = (el) => {
        if (!el) { return }
        handleSaveAppoinmentData({ ...dataAppointment, patient: el.data.id, patientName: el.data.surname + ", " + el.data.name });
        handleSelectPatient(el.data);
    }

    const getData = async (input) => {
        let auxPats = [];
        if (input) {
            const auxRes = await apiCall(`/api/patients?search=${input}&limit=50&page=1`, null, null, 'GET');
            if (auxRes?.status == 200) {
                if (auxRes.data.data.data) {
                    auxRes.data.data.data.map((el) => {
                        {el.id_number && 
                            auxPats.push({
                                label: `${el.surname}, ${el.name} - DNI ${el.id_number} `, 
                                value: el.id,
                                data: el
                            });
                        }
                        {!el.id_number && 
                            auxPats.push({
                                label: `${el.surname}, ${el.name}`, 
                                value: el.id,
                                data: el
                            });
                        }
                    });
                }
            } else {
                toast.error("No se pudo obtener el listado de pacientes. ERROR: " + auxRes.statusText);
            }
            setTimeInputPatient(0);
            
        }    
        setOptionsPats(auxPats);
    }

    const handleClose = () => {

        //if ( section == "Turno" ) {
        navigate(`/appointments/day-and-hour`);
        //} else if ( section == "Receta" ) {
        //    navigate(`/prescriptions/`);
        //}   

    }

    const selectFocus = () => {
        setShowMessage(false);
    }

    const createPat = (patientName) => {
        setPatientTmp({ ...patientTmp, name: patientName });
    }
    const handleChange = (el) => {
        if (!el) { return }

        console.log(el.target.name);
        console.log(el.target.value);
        setPatientTmp({ ...patientTmp, [el.target.name]:el.target.value});
    }


    return (
        <div className="pb-5 h-screen px-2">
            <div className="max-h-full max-w-md bg-white md:max-w-lg font-sans">

                <div className="sticky top-0 bg-white pt-6 pb-2">
                    <div className="mt-4 px-2 flex items-start justify-between pb-4">
                        {/* <h3 className="text-lg text-center font-medium text-gray-900"></h3> */}
                        <h3 className="text-lg leading-tight text-[#1C1243] pr-6">Nuevo {dataAppointment.overschedule == 1 ? "sobreturno" : "turno"} el <br /><span className="font-bold capitalize"><Moment format="dddd" locale="es">{dataAppointment.date}</Moment> <Moment format="DD" locale="es">{dataAppointment.date}</Moment>/<Moment format="MM" locale="es">{dataAppointment.date}</Moment></span> a las <span className="font-bold">{dataAppointment.overschedule == 1 ? dataAppointment.hour.replace("*","") : dataAppointment.hour }hs.</span></h3> 
                        <button className="px-1 pt-2" onClick={() => handleClose()}>                
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                            </svg>
                        </button> 
                    </div>
                </div>

                <div className="px-2 mt-4">
                    { 
                        !patientTmp 
                        ?
                            <div className="mb-4">
                                <label className="text-base font-semibold">Selecciona el paciente</label>
                                <div className="mt-2 mb-4 w-full">
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: 'red',
                                                borderRadius: '8px',
                                                height: '48px',
                                                borderColor: '#d1d5db',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                                textAlign: 'left',
                                            }),
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                textAlign: "left",
                                            }),
                                            noOptionsMessage: (baseStyles) => ({
                                                ...baseStyles,
                                                textAlign: "left",
                                            }),
                                            loadingMessage: (baseStyles) => ({
                                                ...baseStyles,
                                                textAlign: "left",
                                            }),
                                            
                                            }}
                                        placeholder={`Buscar...`}
                                        onInputChange={ handleInputChangePat } 
                                        onChange={ (inputValue) => handleChangePat(inputValue) }
                                        onFocus={selectFocus}
                                        options={optionsPats}
                                        loadingMessage={`Cargando...`}
                                        noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese la búsqueda' : (searchPat && timeInputPatient ? 'Buscando' : <div onClick={ () => createPat(inputValue) }>Crear paciente de nombre: <b>{ inputValue }</b></div>)}
                                    />
                                </div>
                                {
                                    showMessage && <div className="mx-2 mt-1 px-1 text-red-600 leading-none text-sm">Por favor, selecciona un paciente para avanzar.</div>
                                }
                            </div>
                        :
                            <div className="mb-4">
                                <div className="col-span-6 sm:col-span-3 mb-6">
                                    <label htmlFor="name" className={`block text-base font-semibold ${patientTmp && requiredFields.patientname ? 'text-red-600' : 'text-[#1C1243]' }`}>
                                    <span>Nombre y Apellido
                                        {requiredFields.name && (
                                        <span className="ml-1 text-red-600">*</span>
                                        )}
                                    </span>
                                    </label>
                                    <input 
                                        type="text" 
                                        id="patientName" 
                                        className="input-style"
                                        required
                                        // placeholder="nombre y apellido"
                                        name="name"
                                        value={patientTmp.name}
                                        onChange={(e) => handleChange(e)}
                                        disabled={false}
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 mb-6">
                                    <label htmlFor="dni" className={`block text-base font-semibold ${patientTmp && requiredFields.dni ? 'text-red-600' : 'text-[#1C1243]' }`}>
                                    <span>DNI
                                        {requiredFields.dni && (
                                        <span className="ml-1 text-red-600">*</span>
                                        )}
                                    </span>
                                    </label>
                                    <input 
                                        type="text" 
                                        id="patientDNI" 
                                        className="input-style"
                                        required
                                        // placeholder="dni"
                                        name="dni"
                                        value={patientTmp.dni}
                                        onChange={(e) => handleChange(e)}
                                        disabled={false}
                                    />
                                </div>                            
                            </div>
                    }
                    
                    <div className="text-center mt-8">
                        <div className="submit-button" onClick={ () => goToCreate() }>Siguiente</div>
                    </div>
                
                </div>
            </div>
            
        </div>
    )
}

