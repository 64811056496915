import React, { useRef, useState, useEffect } from "react";

import useFormat from "../hooks/useFormat";

export const MyAudioPlayer = ({ dataUrl }) => { 

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMute, setIsMute] = useState(false);
  const [volume, setVolume] = useState(50);
  const [lastVolume, setLastVolume] = useState(50);
  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    const audio = audioRef.current;

    // Escuchar el evento timeupdate para actualizar el progreso
    const updateProgress = () => {
      setCurrentTime(audio.currentTime);
      if (isFinite(audio.duration)) {
        setDuration(audio.duration);        
        const currentProgress = (audio.currentTime / audio.duration) * 100;
        setProgress(currentProgress);
      } else {
        setDuration(0);
        setProgress(0);
      }
      
    };
    const handleEnded = () => {
      setProgress(0);
      setIsPlaying(false);
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("ended", handleEnded);

    // Limpiar el evento al desmontar el componente
    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const playPause = () => {
      const audio = audioRef.current;
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
  };

  const soundMute = () => {
      const audio = audioRef.current;
      if (isMute) {
        audio.muted = false;
        audioRef.current.volume = lastVolume / 100;
        setVolume(lastVolume);
      } else {
          audio.muted = true;
          setVolume(0);
          setLastVolume(volume);
      }
      setIsMute(!isMute);
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * audio.duration;
    audio.currentTime = newTime;
    setProgress(e.target.value);
  };
  const handleAudioChange = (e) => {
      const audio = audioRef.current;
      audio.volume = e.target.value / 100;
      setVolume(100 * audio.volume);
  }
  const handlePlaybackRate = (rate) => {
    const audio = audioRef.current;
    audio.playbackRate = rate;
    setPlaybackRate(rate);
  };

  const playIcon = (
    <svg height="36" width="36" viewBox="0 0 24 24">
        <path fill="#6b7280" d="M8 5v14l11-7z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );

  const pauseIcon = (
      <svg height="36" width="36" viewBox="0 0 24 24">
          <path fill="#6b7280" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
          <path d="M0 0h24v24H0z" fill="none" />
      </svg>
  );

  const muteIcon = (
      <svg height="24" width="24" viewBox="0 0 24 24">
          <path fill="#6b7280" d="M3 9h4l5-5v16l-5-5H3zm13.59 3L14 9.41L15.41 8L18 10.59L20.59 8L22 9.41L19.41 12L22 14.59L20.59 16L18 13.41L15.41 16L14 14.59z"></path>
      </svg>
  );

  const soundIcon = (
      <svg height="24" width="24" viewBox="0 0 24 24">
          <path fill="#6b7280" d="M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.84-5 6.7v2.07c4-.91 7-4.49 7-8.77s-3-7.86-7-8.77M16.5 12c0-1.77-1-3.29-2.5-4.03V16c1.5-.71 2.5-2.24 2.5-4M3 9v6h4l5 5V4L7 9z"></path>
      </svg>
  );

  return (
    <div className='flex justify-between items-center gap-1 bg-gray-100 border border-gray-200 px-2 py-1 rounded-full'>
      <div className="audio-player">
          <audio ref={audioRef} playsinline>
              <source src={dataUrl} type="audio/mp4" />
              Your browser does not support the audio element.
          </audio>
      </div>

      <button className="-ml-2" onClick={playPause}>
          {isPlaying ? pauseIcon : playIcon }
      </button>
      <input className="grow h-[6px] max-w-[15vw]"
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleProgressChange}
      />
      <div className="text-xs w-[80px] text-center">
        { useFormat(currentTime) } / { useFormat(duration) }
      </div>

      <div className="flex items-center bg-gray-100 rounded-full pl-2 pr-1 py-1">
          <input
              className="max-w-[45px] h-[6px]"
              type="range"
              min="0"
              max="100"
              value={volume}
              onChange={handleAudioChange}
          />
          <button onClick={soundMute}>
            {isMute ? muteIcon : soundIcon }
          </button>
      </div>

      <div className="flex justify-center items-center bg-gray-500 text-white size-8 min-w-8 text-xs rounded-full">
        {
          (playbackRate == 1) && (<button onClick={() => handlePlaybackRate(1.5)}>x1</button>)
        }
        {
          (playbackRate == 1.5) && (<button onClick={() => handlePlaybackRate(2)}>x1.5</button>)
        }
        {
          (playbackRate == 2) && (<button onClick={() => handlePlaybackRate(1)}>x2</button>)
        }
      </div>
    </div>
  )
}