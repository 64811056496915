import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useNavigate }   from "react-router-dom";
import { useSelector } from "react-redux";

import { apiCall } from "../redux/api";
import { toast } from 'react-toastify';

export const UploadFile = () => {
    const navigate   = useNavigate();
    const StorageId = JSON.parse(localStorage.CurrentUrl);
    const patient = useSelector(state => state.patients.patient);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        console.log(StorageId.id.id)
        console.log(patient)
    }, []);
    const handleClose = (id) => {
        navigate(`/patients/${id}/hc`);
    }
    const handleSelectFile = (e) => {
        setFile(e.target.files)
        setSelectedFiles((images) => [...images, URL.createObjectURL(e.target.files[0])]);
        return URL.revokeObjectURL(e.target.files[0])
    }
    const handleDeleteImage = (blob) => {
        setSelectedFiles(selectedFiles.filter(x => x !== blob));
    };

    const uploadFiles = async() => {
        //console.log(file)
        //alert(`Subiendo imagen: ${file[0].name}`)

        try {

            const config = { 
                timeout: 10000,
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                headers: {"content-type": "application/json"} 
            };
            const imgRes = await toBase64(file[0]);
            const data = { image: imgRes };

            try {

                setLoading(true);

                const auxRes = await apiCall(`/api/patients/${patient.id}/hc/uploads/image`, data, config, 'POST');
                if (auxRes?.status != 200) {
                    console.log(auxRes)
                    toast.error("No se pudo enviar la imagen. ERROR: " + auxRes.statusText);
                } else {
                    navigate(`/patients/${patient.id}/hc`);
                }

            } catch(error) {
                toast.error("No se pudo enviar la imagen. ERROR: " + error);
                return;
            } finally {
                setLoading(false);
            }           

        } catch(error) {
            toast.error("No se pudo enviar la imagen. ERROR: " + error);
            return;
        } finally {
            setLoading(false);
        } 
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

  return (
    <>
    <div className="flex flex-col h-screen justify-between items-center bg-black">
        <div className="bg-black grid grid-cols-2 items-start py-4">
          <h3 className="pl-[10%] w-[350px] text-lg text-white">
            <span className="">
              Paciente:{' '}
              <span className="font-bold capitalize">
                {patient.surname}, {patient.name}
              </span>
            </span>
          </h3>
          <button onClick={() => handleClose(StorageId.id.id)}>
            <h3 className="pl-[90%] pt-[10px] text-lg font-medium text-[#1C1243]">
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z"
                  fill="white"
                />
              </svg>
            </h3>
          </button>
        </div>
        <input
            type="file"
            name="files"
            className=''
            onChange={(e) => handleSelectFile(e)}
        />
        <div className=''>
            {selectedFiles.map((row, index) =>
            <>
            <div key={index}>
                <img src={row} alt={row} />
                <div className='flex mb-20 justify-center font-sans'>
                    <button className='h-[48px] w-[178px] text-xl text-white font-semibold mt-2 px-4 bg-black badge border-0' onClick={() => handleDeleteImage(row)}>Cancelar</button>
                    <button
                        className="h-[48px] w-[178px] rounded-lg text-xl text-white font-semibold mt-2 ml-2 text-sm px-4 bg-blue-500 badge border-0"
                        onClick={() => uploadFiles()}
                        >
                    {(loading) ? "Guardando..." : 'Guardar'}
                    </button>
                </div>
            </div>
            </>
            )}
        </div>
    </div>
    </>
  )
}