import { Formik, Form, Field, ErrorMessage } from "formik";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../components/AuthProvider";
import { SocialSecuritySelected } from "../components/SocialSecuritySelected"

import { apiCall } from "../redux/api";

import { useState, useEffect, useRef } from "react";

import Switch from "react-switch";

import sendSwal from '../middlewares/sendSwal'

import { toast } from 'react-toastify';

export const OnboardingThree = () => {

    const hHeader = 220;
    const hFooter = 125;
    const classHeader = `h-[${hHeader}px] bg-white`; //bg-gray-200
    const classFooter = `h-[${hFooter}px] bg-white`;
    const classContent = `mt-[${hHeader}px] mb-[${hFooter}px] min-h-[calc(100vh-${hHeader + hFooter}px)] max-h-[calc(100vh-${hHeader + hFooter}px)] overflow-y-auto`;

    const { token, wizardData, handleSaveWizardStep } = useAuth();

    const [socialSecurity, setSocialSecurity] = useState([]);
    const [socialSecuritySelected, setSocialSecuritySelected] = useState([]);
    const [checked, setChecked] = useState(false);

    const navigate = useNavigate();

    const elRef = useRef(null);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }

        getOOSS();

        if (wizardData?.step >= 3) {
            handleSaveWizardStep({
                medical_security: wizardData?.medical_security
            });
            thereAre()
        }

    }, []);

    const getOOSS = async () => {
        const auxRes = await apiCall(`/api/medical-securities`, null, null, 'GET');
        if (auxRes?.status == 200) {
            // console.log(auxRes);
            setSocialSecurity(auxRes.data.data);
        } else {
            toast.error("No se pudo obtener la lista de obras sociales. ERROR: " + auxRes.statusText);
        }
    }

    const getReverseGrid = () => {

        let auxReverseGrid = [];
        Array.from(wizardData.medical_security, (item, index) => {
            if (wizardData.medical_security[index]) {
                auxReverseGrid.push({
                    id: wizardData.medical_security[index].id,
                    name: wizardData.medical_security[index].name
                });
            }            
        });
        return auxReverseGrid; 

    }

    const thereAre = () => {

        if (wizardData.medical_security.length > 0) {

            let gridSaved = getReverseGrid();
            
            let selectedAux = [...socialSecuritySelected]; 

            for ( let i=0 ; i< gridSaved.length; i++ ) {

                selectedAux[i] = gridSaved[i]
            }
            setSocialSecuritySelected(selectedAux);
        }
    }


    const goToOnboardingTwo = (data) => {
        navigate(`/onboarding-two`)
    }
    
    const goToOnboardingFour = (data) => {
        navigate(`/onboarding-four`)
    }

    const goToOB4 = (data) => {

        let mainText = "Estás seguro que no trabajas con ninguna obra social"
        let textConfirmButtom = "Confirmar"
        let textCancelButtom ="Cancelar"

        if (socialSecuritySelected.length < 1) {
            sendSwal(mainText, textConfirmButtom, textCancelButtom, goToOnboardingFour, true);
            
          } else { 
            navigate(`/onboarding-four`);            
        }
    }

    const handleSave = (data) => {
        
        data.step = 3;
        if (wizardData?.step) {
            data.step = Math.max(data.step, wizardData.step);
        }
        handleSaveWizardStep(data);
        goToOB4();  
    };

    const handleSelectChange = () => {
        // console.log(elRef.current.value);
        // console.log(socialSecurity);

        const item = socialSecurity.find((el) => (el.id == elRef.current.value));
        // console.log(item);
        if (item) {
            const idx = socialSecuritySelected.findIndex((el) => (el.id == item.id));
            if (idx < 0) {
                let auxArr = [ ...socialSecuritySelected ];
                auxArr.unshift(item);
                setSocialSecuritySelected(auxArr);
            }            
        }
        
    }
    const handleSocialSecurityRemove = (id) => {
        const idx = socialSecuritySelected.findIndex((el) => (el.id == id));
        // console.log(idx);
        if (idx >= 0) {
            // console.log(idx);
            let auxArr = [ ...socialSecuritySelected ];
            // console.log(auxArr);
            auxArr.splice(idx, 1);
            // console.log(auxArr);
            setSocialSecuritySelected(auxArr);
        }
    }

    const handleChange = () => {
        setChecked(!checked);
        handleSave({medical_security: ""})
    }

  return (
    <>
            <div className={`fixed w-full ${classHeader} px-[35px]`}>
                <h2 className="font-sans mt-3 pt-[100px] text-center text-2xl font-bold tracking-tight text-[#3388FF] leading-tight">Obras sociales</h2>
                <h2 className="font-sans text-center text-2xl font-bold tracking-tight text-[#3388FF] leading-tight">con las que trabajas</h2>
                <h4 className="font-sans leading-5 mt-2 text-center text-[17px] font-semibold tracking-tight text-gray-400">Luego podrás generar</h4>
                <h4 className="font-sans leading-5 text-center text-[17px] font-semibold tracking-tight text-gray-400">reportes y liquidaciones</h4>
            </div>
            <div className="fixed top-[67px] left-[33px]" onClick={ () => goToOnboardingTwo() }>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="black"/>
                </svg>
            </div>

            <Formik
                initialValues={{
                    medical_security: '',
                }}
                onSubmit={(valores, {resetForm})=>{
                    // resetForm();
                    handleSave({
                        medical_security: socialSecuritySelected, 
                    });
                }} 
            >
                {({values, errors}) => (
                    <Form className={`${ classContent } flex items-start justify-center px-4 sm:px-6 lg:px-8 font-sans`} action="#" method="POST">
                        <div className='shadow-sm px-[20px] w-full mt-4'>          
                            <div className="my-4">
                                <div className="flex w-full justify-center items-center border border-gray-300 rounded-2xl">
                                    
                                    <Field 
                                        type="text" 
                                        name="medical_security" 
                                        className="font-sans block flex-1 px-3 py-2 w-[95%] h-[48px] text-base font-semibold rounded-2xl bg-white focus:outline-none"
                                        as="select"
                                        onChange={ () => handleSelectChange() }
                                        innerRef={el => {elRef.current = el}}
                                    >
                                        <option  className="" value="-1">Elige las obras sociales</option>
                                        {
                                            socialSecurity && socialSecurity.map((el) => (
                                                <option key={`socialSecurity-${el.id}`} value={ el.id }>{ el.name }</option>
                                            ))
                                        }

                                    </Field>
                                    <div className="w-[22px]"></div>                                                                          
                                </div>
                            </div>

                            <div className="min-h-[400px] max-h-[400px] overflow-y-auto">
                                {
                                    socialSecuritySelected.map(el => (
                                            <SocialSecuritySelected key={`ooss-${el.id}`} item={el} remove={ handleSocialSecurityRemove } />
                                        )                                
                                    )
                                }   
                            </div>                        
                        </div>

                        {/* <div className="flex justify-center fixed bottom-[140px]">
                            <div className="flex justify-center items-center w-[327px] rounded-full border border-gray-300">
                                <div className="text base mr-4 font-semibold">No trabajo con obras sociales</div>
                                <div className="pt-2"><Switch onChange={ () => handleChange() } checked={checked} onColor={"#3388FF"} uncheckedIcon/></div>  
                            </div>
                        </div>                                           */}

                        <div className={`font-sans fixed w-full left-0 bottom-0 flex justify-between mt-0 py-2 px-5 ${classFooter}`}>
                            <div className="pt-[40px] px-2 flex">
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[24px] h-[8px] bg-[#3388FF] rounded-[100px] mr-2"></div>
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                            </div>
                            <div className="pt-[10px]">
                            <span className="mr-4 text-base text-gray-400 font-bold">Siguiente</span>
                            <span>
                                <button type="submit" className="justify-center rounded-full w-[60px] h-[60px] border border-transparent bg-[#3388FF] py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg className="ml-2 font-semibold" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z" fill="white"/>
                                    </svg>
                                </button>
                            </span>
                            </div>
                        </div>  
                        
                    </Form>
                )}
            </Formik>
    </>
  )
}