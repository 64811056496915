import React                   from 'react';
import { useEffect, useState } from "react";
import Grid                    from '@material-ui/core/Grid';
import Typography              from '@material-ui/core/Typography';
import Avatar                  from 'react-avatar';
import { makeStyles }          from '@material-ui/core/styles';
import { PatientTags }         from './PatientTags'

import convertToDni from '../middlewares/convertToDni';
import assignColor  from '../middlewares/assingColor';
import getYears     from '../middlewares/getYears';


const useStyles = makeStyles(theme => ({
    main: {
      backgroundColor: 'rgba(51, 136, 255, 0.1)',
      height: '40vh'
    },

  
  }));

  const PatientHeader = ({patient, headerSmall}) => {
    
    const classes = useStyles(); 

    const [colorAvatar, setColorAvatar] = useState("#8C8984");

    const handleClickMenu = (e, type) => {
      e.preventDefault();
      e.stopPropagation();
    
      let msgError = '';
  
      switch (type) {
        case 'phone':
          if (patient.telephone) {
            window.open (
              'tel://' + patient.telephone,
              '_blank'
            );
          } else {
            msgError = 'El paciente no tiene teléfono';
          }
          break;
        case 'whatsapp':
          if (patient.telephone) {
            window.open (
              'https://wa.me/' + patient.telephone,
              '_blank'
            );
          } else {
            msgError = 'El paciente no tiene teléfono';
          }
          break;
      }
  
      if (msgError) {
        // alert(msgError);
      }
  
    }

    useEffect(() => {
      setColorAvatar(assignColor(patient.id_number))
    }, []);

    return (
        <Grid container className={classes.main} style={{borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px', height: headerSmall ? '180px' : '333px' }} alignItems='center' justify='center'>
          <Grid container  item xs={12} alignItems='center' justify='center' direction='column'>
              {
                !headerSmall && (
                  <div className="pb-4">
                    {patient.photo ? (
                      <img
                        src={`https://cdn.meducar.com/small/${patient.photo}`}
                        width="94"
                        height="94"
                        className="rounded-full mt-1"
                        style={{ maxHeight: 94 }}
                        onError={(event) => {
                          event.target.src ="https://cdn.meducar.com/img/patient_notfound.png";
                          event.onerror = null;
                        }}
                      />
                    ) : (
                      <Avatar name={`${patient.surname} ${patient.name}`} size="94" round={true} color={colorAvatar} />
                    )}
                  </div>
                )
              }
              <Typography style={{fontWeight: 'bolder', fontSize:'20px'}}><div className="font-sans capitalize text-[#1C1243]">{patient.surname.toLowerCase()}, {patient.name.toLowerCase()}</div></Typography>
              <div className="font-sans" style={{ fontSize: '14px' }}>DNI {convertToDni(patient.id_number)} / {getYears(patient.born_date)} </div>
              <div className="font-sans" style={{color:'#A29EB6'}}>{(patient.medical_security && patient.medical_security_plan) ? `${patient.medical_security.medical_security_name}, ${patient.medical_security_plan}`  : (patient.medical_security && (patient.medical_security_plan == null || patient.medical_security_plan == "")) ? `${patient.medical_security.medical_security_name}` : "Particular"}</div>
              <div className="font-sans" style={{color:'#A29EB6'}}>{patient.medical_security_number ? `N° de Afiliado ${patient.medical_security_number}`  : ""}</div>
              <PatientTags patient={patient} />
              <div className="flex text-sm text-center mt-2 mb-2 pt-1 pl-1">
                <div className="flex mr-2">
                  <svg className="mr-1" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.36991 0.41472C3.71926 0.305635 4.08171 0.25 4.44661 0.25C6.20055 0.25 7.50001 1.79446 7.5 3.54107L7.5 7.45894C7.50001 9.20554 6.20055 10.75 4.44661 10.75C4.0817 10.75 3.71926 10.6944 3.36991 10.5853L3.10287 10.5019C3.01168 10.4734 2.92197 10.4418 2.83382 10.4073C4.22511 13.2328 6.51723 15.5249 9.34271 16.9162C9.30816 16.828 9.27658 16.7383 9.24811 16.6471L9.16472 16.3801C9.05564 16.0307 9 15.6683 9 15.3034C9 13.5494 10.5445 12.25 12.2911 12.25H16.2089C17.9555 12.25 19.5 13.5494 19.5 15.3034C19.5 15.6683 19.4444 16.0307 19.3353 16.3801L19.2519 16.6471C18.7662 18.2027 17.3768 19.3293 15.7148 19.6221C14.7472 19.7926 13.7528 19.7926 12.7852 19.6221C12.7471 19.6154 12.7091 19.6083 12.6714 19.6007C6.32975 18.3806 1.36938 13.4202 0.149306 7.07856C0.141731 7.0408 0.134588 7.00289 0.127883 6.96484C-0.0426279 5.99719 -0.0426274 5.00281 0.127883 4.03516C0.420739 2.37321 1.54728 0.983837 3.10286 0.498107C3.10286 0.498107 3.10287 0.498106 3.10287 0.498106L3.36991 0.414722L3.36991 0.41472ZM1.62147 6.7909C1.61574 6.76107 1.61011 6.73122 1.60456 6.70133C1.46496 5.9069 1.46515 5.08984 1.60512 4.29547C1.81162 3.12362 2.5876 2.23042 3.54995 1.92993L3.54996 1.92993L3.81699 1.84654C3.817 1.84654 3.817 1.84654 3.817 1.84654C4.0228 1.78228 4.23455 1.75 4.44661 1.75C5.23693 1.75 6.00001 2.48089 6 3.54106L6 7.45894C6 8.51911 5.23693 9.25 4.44661 9.25C4.23455 9.25 4.02279 9.21772 3.81699 9.15346L3.54995 9.07007C2.61138 8.77701 1.85009 7.92017 1.62147 6.7909ZM13.0486 18.1454C13.8431 18.285 14.6601 18.2849 15.4545 18.1449C16.6264 17.9384 17.5196 17.1624 17.8201 16.2001L17.9035 15.933C17.9677 15.7272 18 15.5155 18 15.3034C18 14.5131 17.2691 13.75 16.2089 13.75H12.2911C11.2309 13.75 10.5 14.5131 10.5 15.3034C10.5 15.5155 10.5323 15.7272 10.5965 15.933L10.6799 16.2C10.973 17.1386 11.8299 17.8999 12.9592 18.1285C12.9889 18.1343 13.0188 18.1399 13.0486 18.1454Z" fill= '#3388FF'/>
                  </svg>
                  
                  <span 
                    className="font-sans"
                    style={{ color: '#3388FF' , marginRight: '10px'}}
                    onClick={(e) =>
                      handleClickMenu(e, 'phone')
                    }
                  >
                    Teléfono
                  </span>
                </div>

                <div className="flex ml-2">
                  <svg className="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.4975 4.70231C6.70806 4.63656 6.92696 4.60291 7.14762 4.60291C8.2655 4.60291 9.04932 5.57853 9.04932 6.62344L9.04932 8.58237C9.04932 8.58237 9.04932 8.58237 9.04932 8.58237C9.04932 9.62729 8.2655 10.6029 7.14762 10.6029C7.12608 10.6029 7.10456 10.6026 7.08306 10.6019C7.5955 11.3863 8.26593 12.0567 9.05028 12.5692C9.04964 12.5477 9.04932 12.5261 9.04932 12.5046C9.04932 11.3867 10.0249 10.6029 11.0699 10.6029H13.0288C14.0737 10.6029 15.0493 11.3867 15.0493 12.5046C15.0493 12.7253 15.0157 12.9442 14.9499 13.1547L14.9082 13.2882C14.619 14.2143 13.8003 14.8653 12.8468 15.0333C12.3199 15.1261 11.7787 15.1261 11.2518 15.0333C11.2306 15.0295 11.2094 15.0255 11.1882 15.0213C7.86753 14.382 5.27011 11.7846 4.63089 8.46388C4.62667 8.44279 4.62269 8.42163 4.61895 8.4004C4.52611 7.87353 4.52611 7.33228 4.61895 6.80541C4.78697 5.85191 5.43788 5.03318 6.36397 4.744C6.36398 4.744 6.36397 4.744 6.36397 4.744L6.4975 4.70231ZM6.10304 8.17616C6.10053 8.1631 6.09807 8.15003 6.09563 8.13694C6.03369 7.78326 6.03388 7.41933 6.09619 7.06572C6.17785 6.60232 6.47819 6.27976 6.81106 6.17582L6.94458 6.13413C6.94458 6.13413 6.94459 6.13413 6.94458 6.13413C7.0116 6.11321 7.0798 6.10291 7.14762 6.10291C7.30188 6.10291 7.54932 6.26496 7.54932 6.62343L7.54932 8.58237C7.54932 8.94085 7.30188 9.10291 7.14762 9.10291C7.0798 9.10291 7.0116 9.0926 6.94458 9.07168L6.81106 9.02999C6.4869 8.92877 6.19358 8.62022 6.10304 8.17616ZM11.5152 13.5566C11.8689 13.6185 12.2329 13.6183 12.5865 13.556C13.0499 13.4744 13.3725 13.174 13.4764 12.8412L13.5181 12.7076C13.539 12.6406 13.5493 12.5724 13.5493 12.5046C13.5493 12.3503 13.3873 12.1029 13.0288 12.1029H11.0698C10.7114 12.1029 10.5493 12.3503 10.5493 12.5046C10.5493 12.5724 10.5596 12.6406 10.5805 12.7076C10.5805 12.7076 10.5805 12.7076 10.5805 12.7076L10.6222 12.8412C10.7235 13.1653 11.032 13.4587 11.4761 13.5492C11.4891 13.5517 11.5022 13.5542 11.5152 13.5566Z" fill="#25D366"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6283 2.08306C10.7351 1.63898 8.76488 1.63898 6.87171 2.08306C4.37161 2.6695 2.41951 4.6216 1.83306 7.12171C1.38898 9.01488 1.38898 10.9851 1.83306 12.8783C2.26173 14.7057 1.96091 15.9247 1.71138 16.7769C1.69793 16.8229 1.68518 16.8662 1.67312 16.9072C1.56671 17.2692 1.51462 17.4463 1.50924 17.5953L1.50922 17.5958C1.50677 17.6636 1.50535 17.7031 1.61363 17.8038C1.61556 17.8056 1.61742 17.8074 1.61926 17.8091C1.64615 17.8349 1.66785 17.8556 1.81518 17.8398C1.91309 17.8293 2.03527 17.8038 2.20968 17.7607C2.26042 17.7481 2.31804 17.7334 2.3802 17.7175C2.51066 17.6842 2.66112 17.6458 2.80999 17.6115C3.77302 17.3896 5.09484 17.2337 6.96718 17.9462C8.67239 18.5951 10.6521 18.3805 12.6283 17.9169C15.1284 17.3305 17.0805 15.3784 17.6669 12.8783C18.111 10.9851 18.111 9.01488 17.6669 7.12171C17.0805 4.62161 15.1284 2.6695 12.6283 2.08306ZM6.52916 0.622696C8.64764 0.125768 10.8524 0.125768 12.9708 0.622696C16.0256 1.33924 18.4108 3.72441 19.1273 6.77916C19.6242 8.89764 19.6242 11.1024 19.1273 13.2208C18.4108 16.2756 16.0256 18.6608 12.9708 19.3773C10.9354 19.8548 8.5796 20.1647 6.43369 19.3481C4.90504 18.7664 3.89756 18.9002 3.14678 19.0732C3.01338 19.1039 2.89712 19.1337 2.77798 19.1641C2.71077 19.1813 2.64264 19.1988 2.57 19.2168C2.38576 19.2623 2.18063 19.3091 1.97545 19.3312C1.52665 19.3794 1.0313 19.3108 0.591882 18.902C0.164714 18.5046 -0.00797049 18.0447 0.0102177 17.5412C0.0230103 17.187 0.137673 16.8042 0.232158 16.4888C0.245935 16.4428 0.259284 16.3982 0.271824 16.3554C0.483938 15.631 0.718898 14.6967 0.372699 13.2209C-0.124236 11.1024 -0.12423 8.89763 0.372699 6.77915C1.08925 3.72441 3.47441 1.33924 6.52916 0.622696Z" fill="#25D366"/>
                  </svg>
                  <span 
                    className="font-sans"
                    style={{ color: '#25D366' , marginRight: '10px'}}
                    onClick={(e) =>
                      handleClickMenu(e, 'whatsapp')
                    }
                  >
                    Whatsapp
                  </span>
                </div>  
              </div>
          </Grid>
      </Grid>
    )
}

export default PatientHeader;