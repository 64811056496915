import React from 'react';
import { Link } from "react-router-dom";
export default function SectionError({ message, actionMessage, actionLink }) {
    return (
        <>
            {
                (message) && (
                    <div className="h-screen bg-white scroll-none">
                        <div className="flex-col items-center justify-center my-[30vh] p-5 text-center">
                            <div className="text-xl">{ message }</div>
                            {
                                (actionMessage && actionLink) && (
                                    <div className='mt-4'>
                                        <Link className='link' to={ actionLink }>{ actionMessage }</Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>        
    );
}