import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiCall } from '../redux/api';
import { toast } from 'react-toastify';
import { Camera, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import SwitchCamera from '../assets/camera_switch.svg';
import UploadFromGallery from '../assets/upload_from_gallery.svg';
import session from "../utils/session";

export const UploadPhoto= () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const StorageId = JSON.parse(localStorage.CurrentUrl);
  //const StorageSection = JSON.parse(localStorage.CurrentUrl);
  const patient = useSelector((state) => state.patients.patient);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [file, setFile] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);

  const handleSwitchCamera = () => {
    setFacingMode(
      facingMode === FACING_MODES.ENVIRONMENT
        ? FACING_MODES.USER
        : FACING_MODES.ENVIRONMENT
    );
    console.log(facingMode)
  };

  const handleUploadFromGallery = () => {
    fileInputRef.current.click();
  };

  //TODO NANO: VER SI ESTO QUEDA ASI Y EN ESE CASO BORRAR PAGE UploadFile.js
  // const handleUploadFromGallery = (id) => {
  //   navigate(`/patients/${id}/hc/upload-file`);
  // }

  const handleClose = (id) => {

    if (StorageId.id.section == 'hc') {
      navigate(`/patients/${id}/hc`);
    } else if (StorageId.id.section == 'prescriptions') {
      navigate(`/patients/${id}/prescriptions`)
    } else if (StorageId.id.section == 'info') {
      navigate(`/patients/${id}/info`);
    } else if (StorageId.id.section == 'events') {
      navigate(`/patients/${id}/events`);
    }

  };

  const handleSelectFile = (e) => {
    setFileSelected(true)
    setFile(e.target.files)
    setSelectedFiles((images) => [...images, URL.createObjectURL(e.target.files[0])]);
    return URL.revokeObjectURL(e.target.files[0])
  }

  const handleDeleteImage = () => {
    setFileSelected(false)
    setSelectedFiles([]);
  };

  const uploadFiles = async() => {
    //console.log(file)
    //alert(`Subiendo imagen: ${file[0].name}`)

    try {

        const config = { 
            timeout: 10000,
            Authorization: `Bearer ${session.getToken()}`,
            headers: {"content-type": "application/json"} 
        };
        const imgRes = await toBase64(file[0]);
        const data = { image: imgRes };

        try {

            setLoading(true);

            const auxRes = await apiCall(`/api/patients/${patient.id}/hc/uploads/image`, data, config, 'POST');
            if (auxRes?.status != 200) {
                console.log(auxRes)
                toast.error("No se pudo enviar la imagen. ERROR: " + auxRes.statusText);
            } else {
                navigate(`/patients/${patient.id}/hc`);
            }

        } catch(error) {
            toast.error("No se pudo enviar la imagen. ERROR: " + error);
            return;
        } finally {
            setLoading(false);
        }           

    } catch(error) {
        toast.error("No se pudo enviar la imagen. ERROR: " + error);
        return;
    } finally {
        setLoading(false);
    } 
  }

  const handleTakePhoto = (dataUri) => {
    setCapturedPhoto(dataUri);
  };

  const handleRetakePhoto = () => {
    setCapturedPhoto(null);
  };

  const uploadPhoto = async () => {
    
    try {

        const config = { 
            timeout: 10000,
            Authorization: `Bearer ${session.getToken()}`,
            headers: {"content-type": "application/json"} 
        };

        const imgRes = await toBase64(capturedPhoto);
        const data = { image: imgRes };

        try {
            
            setLoading(true);
            const auxRes = await apiCall(`/api/patients/${patient.id}/hc/uploads/image`, data, config, 'POST');
            if (auxRes?.status != 200) {
                console.log(auxRes)
                toast.error("No se pudo enviar la imagen. ERROR: " + auxRes.statusText);
            } else {
                navigate(`/patients/${patient.id}/hc`);
            }

        } catch(error) {
            toast.error("No se pudo enviar la imagen. ERROR: " + error);
            return;
        } finally {
          setLoading(false);
        }                    

    } catch(error) {
        toast.error("No se pudo enviar la imagen. ERROR: " + error);
        return;
    } finally {
      setLoading(false);
    } 
  };

  const toBase64 = (fileOrDataUri) => {
    return new Promise((resolve, reject) => {
      if (typeof fileOrDataUri === 'string') {
        // Si es una cadena (data URI), no necesitamos leerlo, solo lo resolvemos
        resolve(fileOrDataUri);
      } else if (fileOrDataUri instanceof Blob) {
        // Si es un Blob, lo leemos y resolvemos con el resultado
        const reader = new FileReader();
        reader.readAsDataURL(fileOrDataUri);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      } else {
        reject(new Error('Invalid file type'));
      }
    });
  };

  return (
    <>
      <div className="flex flex-col h-screen justify-between items-center bg-black font-sans">
        
        <div className='flex w-full justify-between items-center mt-6 py-4 px-4'>
          <div className="text-lg text-white">
            <span className="">
              Paciente:{' '}
              <span className="font-bold capitalize">
                {patient.surname}, {patient.name}
              </span>
            </span>
          </div>
          <button className='flex justify-center items-center min-w-10 size-10' onClick={() => handleClose(StorageId.id.id)}>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z"
                fill="white"
              />
            </svg>
          </button>
        </div>


          
          {(capturedPhoto && !fileSelected) ? (
            <img src={capturedPhoto} alt="Captured" />
          ) : (!fileSelected) && (
            <div className="w-[100%]" style={{ position: 'relative' }}>
              <button
                onClick={handleUploadFromGallery}
                style={{
                  position: 'absolute',
                  bottom: '25px', 
                  left: '25px', 
                  zIndex: 2, 
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => handleSelectFile(e)}
                />

                <img src={UploadFromGallery} className="inline mr-1 -mt-1" alt="Switch Camera" />
              </button>
              <Camera
                onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                isImageMirror={false}
                idealFacingMode={facingMode}
              />
              <button
                onClick={handleSwitchCamera}
                style={{
                  position: 'absolute',
                  bottom: '25px', 
                  right: '25px', 
                  zIndex: 2, 
                }}
              >
                <img src={SwitchCamera} className="inline mr-1 -mt-1" alt="Switch Camera" />
              </button>
            </div>
          )}

          {fileSelected && 
          <>
          <div className=''>
            {selectedFiles.map((row, index) =>
            <>
            <div className='' key={index}>
                <img className={`max-h-[calc(100vh-220px)]`} src={row} alt={row} />
            </div>
            </>
            )}
          </div>
          <div className='flex mt-4 mb-20 justify-center font-sans'>
            <button className='h-[48px] w-[170px] text-xl text-white font-semibold mt-2 px-4 bg-black badge border-0' onClick={() => handleDeleteImage()}>Cancelar</button>
            <button
                className="h-[48px] w-[170px] rounded-lg text-xl text-white font-semibold mt-2 px-4 bg-blue-500 badge border-0"
                onClick={() => uploadFiles()}
                >
            {(loading) ? "Guardando..." : 'Guardar'}
            </button>
          </div>
          </>

          }

          {(capturedPhoto && !fileSelected) ? (
            <div className='flex mb-20 justify-center font-sans'>
              <button
                className="h-[48px] w-[170px] text-xl text-white font-semibold mt-2 px-4 bg-black badge border-0"
                onClick={handleRetakePhoto}
              >
                Cancelar
              </button>
              <button
                className="h-[48px] w-[170px] rounded-lg text-xl text-white font-semibold mt-2 px-4 bg-blue-500 badge border-0"
                onClick={uploadPhoto}
              >
                {(loading) ? "Guardando..." : 'Guardar'}
              </button>
            </div>
          ) : (!fileSelected) && (
            <div className='flex mb-20 justify-center bg-black w-full'>
              <button
                className="h-[48px] w-[178px] text-2xl mt-2 px-4 bg-black"
              >

              </button>
              <button
                className="h-[48px] w-[178px] rounded-lg text-2xl mt-2 ml-2 text-sm px-4"
              >

              </button>
            </div>
          )}
          
      </div>
    </>
  );
};