import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

export const themeAppDefault = {
    palette: {
      primary: { // used to represent primary interface elements for a user.
        // light: will be calculated from palette.primary.main,
        main: '#16A692',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: { // used to represent secondary interface elements for a user.
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      error: { // used to represent interface elements that the user should be made aware of.
        // light: will be calculated from palette.primary.main,
        main: '#f44336',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      warning: { // used to represent potentially dangerous actions or important messages.
        // light: will be calculated from palette.primary.main,
        main: '#ffc107',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      info: { // used to present information to the user that is neutral and not necessarily important.
        // light: will be calculated from palette.primary.main,
        main: '#2196f3',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: { // used to indicate the successful completion of an action that user triggered.
        // light: will be calculated from palette.primary.main,
        main: '#4caf50',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
        // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      type: 'light',
    },
};

const drawerWidth = 240;

export const layoutVars = {
  header: {
    height: 56,
    backgroundColor: '#FFF',
  },
  footer: {
    height: 56,
    backgroundColor: themeAppDefault.palette.primary.main,
  } 
}
export const useStylesApp = makeStyles(theme => ({
    root: {
      display: 'flex',
      backgroundColor: '#FFF'
    },
    gridRoot: {
      flexGrow: 1,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
  
    noMargin: {
      margin: '0px'
    },
    noPadding: {
      padding: '0px'
    },
  
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
  
    // appBar: {
    //   maxWidth: '444px',
    //   right: 'auto !important',
    //   zIndex: theme.zIndex.drawer - 1,
    //   transition: theme.transitions.create(['margin', 'width'], {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
    // appBarTransparent: {
    //   paddingTop: 5,
    //   paddingLeft: 10,
    //   paddingRight: 10,
    //   maxWidth: '444px',
    //   right: 'auto !important',
    //   zIndex: theme.zIndex.drawer - 1,
    //   // backgroundColor: 'transparent !important',
    //   backgroundColor: '#FFF !important',
    //   color: '#1C1243 !important',
    //   // textShadow: '1px 1px #000 !important',
    //   boxShadow: 'none !important',
    // },
  
    appBarShift: {
      // marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      // transition: theme.transitions.create(['margin', 'width'], {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    hidden: {
      display: 'none !important'
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonToolbarTransparent: {
      marginRight: 36,
      color: '#EEE !important',
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      /* background: '#7e848d' */
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // drawerHeader: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   padding: theme.spacing(0, 1),
    //   ...theme.mixins.toolbar,
    //   justifyContent: 'flex-end',
    // },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      marginLeft: -drawerWidth,
      // paddingBottom: '85px'
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    paperSchedule: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fixedHeight: {
      height: 240,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.3)',
    },

    footer: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      height: '85px', //'10vh',
      backgroundColor: '#FFF',
      '& li': {
        height: '65px', // 'calc(10vh - 20px)',
        // backgroundColor: 'rgba(0,0,0,.6)',
        '& a': {
          color: '#1C1243',
        },
        '& a:focus': {
          backgroundColor: 'unset',
        },
        '& a:active': {
          backgroundColor: 'unset',
        },
        '& a:hover': {
          backgroundColor: 'unset',
        },
        '& a.current': {
          color: '#3388FF',
        }
      }
    },

    sidebarCurrentOption: {
      color: '#FFF',
      backgroundColor: '#3d4451',
    }
}));