import React, { useState, useEffect } from "react";
import { useNavigate }                from "react-router-dom";
import Moment                         from "react-moment";
import moment                         from 'moment';
import { useSelector }                from "react-redux";

import { apiCall }             from "../redux/api";
import { prescriptionsResult } from "../redux/selectors";

import { toast } from 'react-toastify';

import session from "../utils/session";

import "moment/locale/es";

const Prescription = ({ data, surname, name }) => {
  const navigate = useNavigate();
  
  const { id, description, drugs, patient, professional, created, sent_whatsapp_at, sent_email_at } = data;
  const { offices } = session.getUser();

  const prescriptions = useSelector((state) => prescriptionsResult(state));

  const [ sendingWA, setSendingWA ]            = useState(false);
  const [ sendWA, setSendWA ]                  = useState(false);
  const [ sendingEmail, setSendingEmail ]      = useState(false);
  const [ sendEmail, setSendEmail ]            = useState(false);
  const [ cameFromPatient, setCameFromPatient] = useState(false);
  const [ showModal, setShowModal ]            = useState(false);
  const [ hasWhatsApp , setHasWhatsApp ]       = useState(true);  
  const [ existsAddons, setExistsAddons ]      = useState(true);

  const previosPage = JSON.parse(localStorage.CurrentUrl)

  useEffect(() => {
    fromPatient();

  }, []);

  useEffect(() => {
   
      if (prescriptions && prescriptions[0]) {
        for (let i=0 ; i < prescriptions[0].length ; i++) {
          if (prescriptions[0][i] && prescriptions[0][i].id == id ) {
   
              for (let j=0 ; j < offices.length ; j++) {
                if (offices[j].id == prescriptions[0][i].office_id) {
   
                  if (offices[j].addons == undefined) {
                    setExistsAddons(false)
                  } else if (offices[j].addons.whatsapp_notifications == 1) {
                    setHasWhatsApp(true)
                  } else if (offices[j].addons.whatsapp_notifications == 0) {
                    setHasWhatsApp(false)
                  }
                } 
              }
          }
        }
      }

 }, [prescriptions]);

  const handleClick = (e, id) => {
    navigate('/prescriptions/' + id);
  };

  const fromPatient = () => {
    if (previosPage.id != "") {
      setCameFromPatient(true)
    } else {
      setCameFromPatient(false)
    }
  }

  const handleShowMenu = (e, show, data) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(show);    
  };

  const handleClickMenu = (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    switch (type) {
      case 'view':
        handleClick(e, id)
        break;
      case 'whatsapp':
        sendPrescriptionWhatsapp(e, id)
        break;
      case 'email':
        sendPrescriptionEmail(e, id)
        break;
    }

    handleShowMenu(e, false);
  }

  const sendPrescriptionWhatsapp = async (e, id) => {

    if (hasWhatsApp) {
      e.preventDefault();
      e.stopPropagation();

      if (sendingWA) { return; }

      setSendingWA(true);
      
      const data = {
        id: id,
        type: 'whatsapp'
      }

      const auxRes = await apiCall(`/api/prescriptions/send`, data, null, 'POST');
      if (auxRes?.status != 200) {
          toast.error("No se pudo enviar la receta. ERROR: " + auxRes.statusText);
      } else if (auxRes?.status == 200) {
          toast.success("Envío de receta OK");
      }

      setSendingWA(false);
      setShowModal(false);
      setSendWA(true);
    } else {
      toast.info("Debes tener activo el complemento de Whatsapp para utilizar esta función");
    }
  };

  const sendPrescriptionEmail = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (sendingEmail) { return; }
   
    setSendingEmail(true);
    
    const data = {
      id: id,
      type: 'email'
    }

    const auxRes = await apiCall(`/api/prescriptions/send`, data, null, 'POST');
    if (auxRes?.status != 200) {
        toast.error("No se pudo enviar la receta. ERROR: " + auxRes.statusText);
    } else if (auxRes?.status == 200) {
        toast.success("Envío de receta OK");
    }

    setSendingEmail(false);
    setShowModal(false);
    setSendEmail(true);
  };

  return (
    <>
      <div
        key={`prescrioption-${id}`}
        className="items-center bg-white"
        onClick={(e) => handleClick(e, id)}
      >
        <>

          <div className="font-sans bg-white p-1 border shadow-lg rounded-lg p-3 mb-3">
            <div className="flex flex-col">
              <div>
                <div className="py-1 flex justify-between text-sm">
                  <div className="text-gray-400 font-medium flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#9ca3af" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    <div className="ml-1 inline text-sm font-semibold">
                      { moment(created, "YYYY-MM-DD hh-mm-ss").format("DD/MM/YYYY") }
                    </div>                      
                  </div>
                  <button className="pt-4 flex flex-col items-center" onClick={(e) => handleShowMenu(e, true, data) }>
                    <i
                      className="fa fa-ellipsis-h text-[#A29EB6] -mt-4 mr-2 mb-1"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </button>
                </div>
              </div>
              <div className="font-bold text-base capitalize text-[#1C1243]">
              {cameFromPatient ? 
                <div>{professional.title} {professional.surname}, {professional.name}</div> :
                patient && <div>{patient.surname}, {patient.name}</div>
              }     
              </div>
              <div className="text-sm font-normal py-2 text-gray-400">

                <div className="max-h-[5vh] overflow-hidden">
                  <p className="inline-block break-all text-[#1C1243]">
                    {description}
                  </p>

                </div>

                <div className="text-xs text-gray-400 truncate w-full">
                  {
                    sendWA
                    ?
                      <div className="pt-2 text-[#3388FF] font-semibold text-sm">
                        Enviada por whatsapp hace un instante
                      </div>
                    :
                      sent_whatsapp_at && (
                        <div className="pt-2 text-[#3388FF] font-semibold text-sm">
                          Enviada por whatsapp: <Moment fromNow>{sent_whatsapp_at}</Moment>
                        </div>
                      )
                  }
                  {
                    sendEmail
                    ?
                      <div className="pt-2 text-[#3388FF] font-semibold text-sm">
                        Enviada por email hace un instante
                      </div>
                    :
                      sent_email_at && (
                        <div className="pt-2 text-[#3388FF] font-semibold text-sm">
                          Enviada por email: <Moment fromNow>{sent_email_at}</Moment>
                        </div>
                      )
                  }
                  {
                    (!sendEmail && !sent_email_at & !sendWA && !sent_whatsapp_at) ?
                      <div className="pt-2">
                        No enviada al paciente
                      </div>
                    : 
                      <div></div>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      {showModal && (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
              <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={(e) => handleShowMenu(e, false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-56 py-1 px-2 mx-auto bg-white rounded-xl shadow-lg">
                      <div className="sm:flex">
                          <div className="text-center sm:ml-4 sm:text-left">
                              <div className="items-center gap-2 my-3 sm:flex text-[#1C1243]">
                               {cameFromPatient ? 
                                <h5 className="font-sans text-lg font-semibold mb-4">Receta para <span className="capitalize">{surname}, {name}</span> - {data.date}</h5>
                                :
                                <h5 className="font-sans text-lg font-semibold mb-4">Receta para <span className="capitalize">{data.patient.surname}, {data.patient.name}</span> - {data.date}</h5>
                               }
                                <button
                                  className="w-full pl-2 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={(e) =>
                                    handleClickMenu(e, 'view')
                                  }
                                >
                                  <div className="flex">
                                    <svg 
                                      className="mr-1 mt-1"
                                      xmlns="http://www.w3.org/2000/svg" 
                                      width="20" 
                                      height="20" 
                                      viewBox="0 0 24 24" 
                                      fill="none" 
                                      stroke="black" 
                                      strokeWidth="2" 
                                      strokeLinecap="round" 
                                      strokeLinejoin="round">
                                      
                                      <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/>
                                    </svg>
                                    <div className="font-sans text-base">Ver Receta</div>
                                  </div>                                      
                                </button>
                                { existsAddons &&   
                                  <button
                                    className={`${hasWhatsApp ? '' : 'text-gray-400'} w-full p-1.5 pl-2 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2`}
                                    onClick={(e) =>
                                      handleClickMenu(e, 'whatsapp')
                                    }
                                  >
                                    <div className="flex">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-4 h-4 mr-2 mt-1"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                      >
                                          {hasWhatsApp ?
                                          <>
                                          <path 
                                              fillRule="evenodd" 
                                              clipRule="evenodd" 
                                              d="M6.4975 4.70231C6.70806 4.63656 6.92696 4.60291 7.14762 4.60291C8.2655 4.60291 9.04932 5.57853 9.04932 6.62344L9.04932 8.58237C9.04932 8.58237 9.04932 8.58237 9.04932 8.58237C9.04932 9.62729 8.2655 10.6029 7.14762 10.6029C7.12608 10.6029 7.10456 10.6026 7.08306 10.6019C7.5955 11.3863 8.26593 12.0567 9.05028 12.5692C9.04964 12.5477 9.04932 12.5261 9.04932 12.5046C9.04932 11.3867 10.0249 10.6029 11.0699 10.6029H13.0288C14.0737 10.6029 15.0493 11.3867 15.0493 12.5046C15.0493 12.7253 15.0157 12.9442 14.9499 13.1547L14.9082 13.2882C14.619 14.2143 13.8003 14.8653 12.8468 15.0333C12.3199 15.1261 11.7787 15.1261 11.2518 15.0333C11.2306 15.0295 11.2094 15.0255 11.1882 15.0213C7.86753 14.382 5.27011 11.7846 4.63089 8.46388C4.62667 8.44279 4.62269 8.42163 4.61895 8.4004C4.52611 7.87353 4.52611 7.33228 4.61895 6.80541C4.78697 5.85191 5.43788 5.03318 6.36397 4.744C6.36398 4.744 6.36397 4.744 6.36397 4.744L6.4975 4.70231ZM6.10304 8.17616C6.10053 8.1631 6.09807 8.15003 6.09563 8.13694C6.03369 7.78326 6.03388 7.41933 6.09619 7.06572C6.17785 6.60232 6.47819 6.27976 6.81106 6.17582L6.94458 6.13413C6.94458 6.13413 6.94459 6.13413 6.94458 6.13413C7.0116 6.11321 7.0798 6.10291 7.14762 6.10291C7.30188 6.10291 7.54932 6.26496 7.54932 6.62343L7.54932 8.58237C7.54932 8.94085 7.30188 9.10291 7.14762 9.10291C7.0798 9.10291 7.0116 9.0926 6.94458 9.07168L6.81106 9.02999C6.4869 8.92877 6.19358 8.62022 6.10304 8.17616ZM11.5152 13.5566C11.8689 13.6185 12.2329 13.6183 12.5865 13.556C13.0499 13.4744 13.3725 13.174 13.4764 12.8412L13.5181 12.7076C13.539 12.6406 13.5493 12.5724 13.5493 12.5046C13.5493 12.3503 13.3873 12.1029 13.0288 12.1029H11.0698C10.7114 12.1029 10.5493 12.3503 10.5493 12.5046C10.5493 12.5724 10.5596 12.6406 10.5805 12.7076C10.5805 12.7076 10.5805 12.7076 10.5805 12.7076L10.6222 12.8412C10.7235 13.1653 11.032 13.4587 11.4761 13.5492C11.4891 13.5517 11.5022 13.5542 11.5152 13.5566Z" 
                                              fill="black"
                                          />
                                          <path 
                                              fillRule="evenodd" 
                                              clipRule="evenodd" 
                                              d="M12.6283 2.08306C10.7351 1.63898 8.76488 1.63898 6.87171 2.08306C4.37161 2.6695 2.41951 4.6216 1.83306 7.12171C1.38898 9.01488 1.38898 10.9851 1.83306 12.8783C2.26173 14.7057 1.96091 15.9247 1.71138 16.7769C1.69793 16.8229 1.68518 16.8662 1.67312 16.9072C1.56671 17.2692 1.51462 17.4463 1.50924 17.5953L1.50922 17.5958C1.50677 17.6636 1.50535 17.7031 1.61363 17.8038C1.61556 17.8056 1.61742 17.8074 1.61926 17.8091C1.64615 17.8349 1.66785 17.8556 1.81518 17.8398C1.91309 17.8293 2.03527 17.8038 2.20968 17.7607C2.26042 17.7481 2.31804 17.7334 2.3802 17.7175C2.51066 17.6842 2.66112 17.6458 2.80999 17.6115C3.77302 17.3896 5.09484 17.2337 6.96718 17.9462C8.67239 18.5951 10.6521 18.3805 12.6283 17.9169C15.1284 17.3305 17.0805 15.3784 17.6669 12.8783C18.111 10.9851 18.111 9.01488 17.6669 7.12171C17.0805 4.62161 15.1284 2.6695 12.6283 2.08306ZM6.52916 0.622696C8.64764 0.125768 10.8524 0.125768 12.9708 0.622696C16.0256 1.33924 18.4108 3.72441 19.1273 6.77916C19.6242 8.89764 19.6242 11.1024 19.1273 13.2208C18.4108 16.2756 16.0256 18.6608 12.9708 19.3773C10.9354 19.8548 8.5796 20.1647 6.43369 19.3481C4.90504 18.7664 3.89756 18.9002 3.14678 19.0732C3.01338 19.1039 2.89712 19.1337 2.77798 19.1641C2.71077 19.1813 2.64264 19.1988 2.57 19.2168C2.38576 19.2623 2.18063 19.3091 1.97545 19.3312C1.52665 19.3794 1.0313 19.3108 0.591882 18.902C0.164714 18.5046 -0.00797049 18.0447 0.0102177 17.5412C0.0230103 17.187 0.137673 16.8042 0.232158 16.4888C0.245935 16.4428 0.259284 16.3982 0.271824 16.3554C0.483938 15.631 0.718898 14.6967 0.372699 13.2209C-0.124236 11.1024 -0.12423 8.89763 0.372699 6.77915C1.08925 3.72441 3.47441 1.33924 6.52916 0.622696Z" 
                                              fill="black"
                                          />
                                          </>
                                          :
                                          <>
                                          <path 
                                              fillRule="evenodd" 
                                              clipRule="evenodd" 
                                              d="M6.4975 4.70231C6.70806 4.63656 6.92696 4.60291 7.14762 4.60291C8.2655 4.60291 9.04932 5.57853 9.04932 6.62344L9.04932 8.58237C9.04932 8.58237 9.04932 8.58237 9.04932 8.58237C9.04932 9.62729 8.2655 10.6029 7.14762 10.6029C7.12608 10.6029 7.10456 10.6026 7.08306 10.6019C7.5955 11.3863 8.26593 12.0567 9.05028 12.5692C9.04964 12.5477 9.04932 12.5261 9.04932 12.5046C9.04932 11.3867 10.0249 10.6029 11.0699 10.6029H13.0288C14.0737 10.6029 15.0493 11.3867 15.0493 12.5046C15.0493 12.7253 15.0157 12.9442 14.9499 13.1547L14.9082 13.2882C14.619 14.2143 13.8003 14.8653 12.8468 15.0333C12.3199 15.1261 11.7787 15.1261 11.2518 15.0333C11.2306 15.0295 11.2094 15.0255 11.1882 15.0213C7.86753 14.382 5.27011 11.7846 4.63089 8.46388C4.62667 8.44279 4.62269 8.42163 4.61895 8.4004C4.52611 7.87353 4.52611 7.33228 4.61895 6.80541C4.78697 5.85191 5.43788 5.03318 6.36397 4.744C6.36398 4.744 6.36397 4.744 6.36397 4.744L6.4975 4.70231ZM6.10304 8.17616C6.10053 8.1631 6.09807 8.15003 6.09563 8.13694C6.03369 7.78326 6.03388 7.41933 6.09619 7.06572C6.17785 6.60232 6.47819 6.27976 6.81106 6.17582L6.94458 6.13413C6.94458 6.13413 6.94459 6.13413 6.94458 6.13413C7.0116 6.11321 7.0798 6.10291 7.14762 6.10291C7.30188 6.10291 7.54932 6.26496 7.54932 6.62343L7.54932 8.58237C7.54932 8.94085 7.30188 9.10291 7.14762 9.10291C7.0798 9.10291 7.0116 9.0926 6.94458 9.07168L6.81106 9.02999C6.4869 8.92877 6.19358 8.62022 6.10304 8.17616ZM11.5152 13.5566C11.8689 13.6185 12.2329 13.6183 12.5865 13.556C13.0499 13.4744 13.3725 13.174 13.4764 12.8412L13.5181 12.7076C13.539 12.6406 13.5493 12.5724 13.5493 12.5046C13.5493 12.3503 13.3873 12.1029 13.0288 12.1029H11.0698C10.7114 12.1029 10.5493 12.3503 10.5493 12.5046C10.5493 12.5724 10.5596 12.6406 10.5805 12.7076C10.5805 12.7076 10.5805 12.7076 10.5805 12.7076L10.6222 12.8412C10.7235 13.1653 11.032 13.4587 11.4761 13.5492C11.4891 13.5517 11.5022 13.5542 11.5152 13.5566Z" 
                                              fill="#9ca3af"
                                          />
                                          <path 
                                              fillRule="evenodd" 
                                              clipRule="evenodd" 
                                              d="M12.6283 2.08306C10.7351 1.63898 8.76488 1.63898 6.87171 2.08306C4.37161 2.6695 2.41951 4.6216 1.83306 7.12171C1.38898 9.01488 1.38898 10.9851 1.83306 12.8783C2.26173 14.7057 1.96091 15.9247 1.71138 16.7769C1.69793 16.8229 1.68518 16.8662 1.67312 16.9072C1.56671 17.2692 1.51462 17.4463 1.50924 17.5953L1.50922 17.5958C1.50677 17.6636 1.50535 17.7031 1.61363 17.8038C1.61556 17.8056 1.61742 17.8074 1.61926 17.8091C1.64615 17.8349 1.66785 17.8556 1.81518 17.8398C1.91309 17.8293 2.03527 17.8038 2.20968 17.7607C2.26042 17.7481 2.31804 17.7334 2.3802 17.7175C2.51066 17.6842 2.66112 17.6458 2.80999 17.6115C3.77302 17.3896 5.09484 17.2337 6.96718 17.9462C8.67239 18.5951 10.6521 18.3805 12.6283 17.9169C15.1284 17.3305 17.0805 15.3784 17.6669 12.8783C18.111 10.9851 18.111 9.01488 17.6669 7.12171C17.0805 4.62161 15.1284 2.6695 12.6283 2.08306ZM6.52916 0.622696C8.64764 0.125768 10.8524 0.125768 12.9708 0.622696C16.0256 1.33924 18.4108 3.72441 19.1273 6.77916C19.6242 8.89764 19.6242 11.1024 19.1273 13.2208C18.4108 16.2756 16.0256 18.6608 12.9708 19.3773C10.9354 19.8548 8.5796 20.1647 6.43369 19.3481C4.90504 18.7664 3.89756 18.9002 3.14678 19.0732C3.01338 19.1039 2.89712 19.1337 2.77798 19.1641C2.71077 19.1813 2.64264 19.1988 2.57 19.2168C2.38576 19.2623 2.18063 19.3091 1.97545 19.3312C1.52665 19.3794 1.0313 19.3108 0.591882 18.902C0.164714 18.5046 -0.00797049 18.0447 0.0102177 17.5412C0.0230103 17.187 0.137673 16.8042 0.232158 16.4888C0.245935 16.4428 0.259284 16.3982 0.271824 16.3554C0.483938 15.631 0.718898 14.6967 0.372699 13.2209C-0.124236 11.1024 -0.12423 8.89763 0.372699 6.77915C1.08925 3.72441 3.47441 1.33924 6.52916 0.622696Z" 
                                              fill="#9ca3af"
                                          />
                                          </>
                                          } 
                                      </svg>
                                      <div className="font-sans text-base">Enviar por WhatsApp</div>
                                    </div>                                      
                                  </button>
                                }

                                <button
                                  className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={(e) =>
                                    handleClickMenu(e, 'email')
                                  }
                                >
                                  <div className="flex">
                                    <svg 
                                      className="mr-1 mt-1"
                                      xmlns="http://www.w3.org/2000/svg" 
                                      width="18" 
                                      height="18" 
                                      viewBox="0 0 24 24" 
                                      fill="none" 
                                      stroke="black" 
                                      strokeWidth="1.8" 
                                      strokeLinecap="round" 
                                      strokeLinejoin="round">
                                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    <div className="pl-1 font-sans text-base">Enviar por Email</div>
                                  </div>                                      
                                </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
      )}
    </>
  );
};

export default Prescription;
