import { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { apiCall } from "../redux/api";

import {register as userRegister} from '../redux/actions/register';

import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from '../logo-meducar-app.png';

import { useAuth } from "../components/AuthProvider";

import { toast } from 'react-toastify';

export const Register = () => {
    const { handleLogin } = useAuth();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ specialities, setSpecialities ] = useState([]);
    const [ countries, setCountries ] = useState([]);

    const user = useSelector(state => state.register.userData);
    const token = useSelector(state => state.register.userToken);
    const registerLoading = useSelector(state => state.register.isLoading);
    const msgError = useSelector(state => state.register.msgError);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ invalidData, setInvalidData ] = useState(false);

    const formikRef = useRef(null);

    useEffect(() => {
        getSpecialities();
        getCountries();
    }, []);

    useEffect(() => {
        // alert(user);
        // alert(token);
        if(user && token) {
            //alert('aaaa');
            console.log('user', user);
            console.log('token', token)
            if (token) {
                //console.log('token', token)
                handleLogin(token, user, true);
            }
            // history.push('/');
        }        
    }, [user, token]);

    useEffect(() => {
        if(!registerLoading && user) {
            //TODO_G: Avisar con una animación
            // alert('LOGIN OK');
            console.log('user', user)
            // handleLogin();
            // history.push('/');
        }
    }, [registerLoading]);

    useEffect(() => {
        if (msgError) {

            let err = {}
            // Adapto errores
            if (msgError.message) {
                if (msgError.message = 'The username already exists') {
                    err.username = 'El Email ingresado ya existe en Meducar';
                }
            }

            if (msgError.name) {
                err.name = msgError.name[0].replace('name', 'Nombre');
            }
            if (msgError.name) {
                err.surname = msgError.surname[0].replace('surname', 'Apellido');
            }
            if (msgError.username) {
                err.username = msgError.username[0].replace('username', 'Email');
            }
            if (msgError.password) {
                err.password = msgError.password[0].replace('password', 'Contraseña');
            }
            if (msgError.speciality_id) {
                err.speciality = msgError.speciality_id[0].replace('speciality id', 'Especialidad');
            }
            if (msgError.country_id) {
                err.country = msgError.country_id[0].replace('country id', 'País');
            }
            console.log(err);
            formikRef.current.setErrors(err);
            
            setInvalidData(true);
            setIsSubmitting(false);
        }        
    }, [msgError]);

    const resetInvalidData = () => {
        formikRef.current.setErrors({});
        setInvalidData(false);
    }

    const buttonSubmit = useMemo(() => {
        const loading = (isSubmitting && !invalidData);
        return(
            <button type="submit" disabled={ loading } className="w-full h-[48px] justify-center rounded-2xl border border-transparent bg-[#3388FF] px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                { loading && <div className="inline-block mr-2"><div className="w-3 h-3 border-l-2 border-b-2 border-white rounded-full animate-spin"></div></div> }
                { loading ? 'Registrando ... ' : 'Registrarse' }
            </button>
        );
    }, [isSubmitting, invalidData])

    const getSpecialities = async () => {
        const auxRes = await apiCall(`/api/specialities`, null, null, 'GET');
        if (auxRes?.status == 200) {
            console.log(auxRes);
            setSpecialities(auxRes.data.data);
        } else {
            toast.error("No se pudo obtener la lista de especialidades. ERROR: " + auxRes.statusText);
        }
    }
    const getCountries = async () => {
        const auxRes = await apiCall(`/api/countries`, null, null, 'GET');
        if (auxRes?.status == 200) {
            console.log(auxRes);
            setCountries(auxRes.data.data);
        } else {
            toast.error("No se pudo obtener la lista de paises. ERROR: " + auxRes.statusText);
        }
    }
    
    const goToOnboarding = (data) => {
        navigate(`/onboarding-one`)
    }

    const handleRegister = (data) => {        
        
        resetInvalidData(); 

        dispatch(userRegister({ 
            name: data[0],
            surname: data[1],
            username: data[2],
            password: data[3],
            speciality: data[4],
            country: data[5],
        }));

        setIsSubmitting(true);

        // if (data) {
        //     goToOnboarding();
        // } else {
        //     alert("la data no está llegando")
        // }
        
    }

  return (
    <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8 font-sans">
        <div className={`w-full max-w-md`}>
            <div className="flex justify-center">
                <div className="mt-[110px] pb-4"><img src={logo} /></div>
            </div>   
            <div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1C1243]">Crear Cuenta</h2>
            </div>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    name: '',
                    surname: '',
                    username: '',
                    password: '',
                    speciality: '',
                    country: '',
                }}
                validate={(valores) =>{
                    let errores = {}
                    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    
                    if (!valores.name) {
                        errores.name ='El campo Nombre no puede estar vacío'
                    }

                    if (!valores.surname) {
                        errores.surname ='El campo Apellido no puede estar vacío'
                    } 

                    if (!valores.username) {
                        errores.username ='El campo Email no puede estar vacío'
                    } else if (!regexEmail.test(valores.username)) {
                        errores.username ='Debes escribir un Email válido'
                    }

                    if (!valores.password) {
                        errores.password ='La Contraseña no puede estar vacía'
                    } else if (valores.password.length < 6) {
                        errores.password ='Debes escribir al menos 6 caracteres'
                    }

                    return errores;
                }}
                onSubmit={(valores, {resetForm})=>{
                    // resetForm();
                    handleRegister([ 
                        valores.name, 
                        valores.surname,
                        valores.username, 
                        valores.password, 
                        valores.speciality, 
                        valores.country
                    ]);
                }}
            >
                {({errors}) => (
                    <Form className="mt-8 " action="#" method="POST">
                        {/* <input type="hidden" name="remember" value="true" /> */}
                                        
                        <div className={`rounded-md shadow-sm ${ (isSubmitting && !invalidData) ? 'pointer-events-none opacity-50' : '' }`} onMouseOver={() => resetInvalidData()}>
                                            
                        <div className="my-4 px-[15px]">
                                <label htmlFor="name" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Nombre</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.75002 1.75C5.88313 1.75 4.41669 3.20257 4.41669 4.9375C4.41669 6.67243 5.88313 8.125 7.75002 8.125C9.61691 8.125 11.0834 6.67243 11.0834 4.9375C11.0834 3.20257 9.61691 1.75 7.75002 1.75ZM2.91669 4.9375C2.91669 2.32319 5.10659 0.25 7.75002 0.25C10.3935 0.25 12.5834 2.32319 12.5834 4.9375C12.5834 7.55181 10.3935 9.625 7.75002 9.625C5.10659 9.625 2.91669 7.55181 2.91669 4.9375ZM10.4162 12.9684C8.64987 12.6772 6.85013 12.6772 5.08382 12.9684L4.87605 13.0027L4.75404 12.2627L4.87604 13.0027C2.94882 13.3204 1.5 15.0477 1.5 17.1115C1.5 17.7634 2.00549 18.25 2.57813 18.25H12.9219C13.4945 18.25 14 17.7634 14 17.1115C14 15.0477 12.5512 13.3204 10.624 13.0027L10.746 12.2627L10.624 13.0027L10.4162 12.9684ZM10.6602 11.4884L10.5382 12.2284L10.6602 11.4884L10.868 11.5226C13.5542 11.9655 15.5 14.349 15.5 17.1115C15.5 18.5455 14.3685 19.75 12.9219 19.75H2.57813C1.13147 19.75 0 18.5455 0 17.1115C0 14.349 1.94583 11.9655 4.63203 11.5226L4.83981 11.4884L4.96182 12.2284L4.83982 11.4884C6.7677 11.1705 8.73229 11.1705 10.6602 11.4884Z" fill="#6b7280"/>
                                        </svg>
                                    </span>
                                    <Field 
                                        type="text" 
                                        name="name" 
                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                    />
                                </div>
                                <ErrorMessage name="name" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.name}</span>
                                )} />
                            </div>

                            <div className="my-4 px-[15px]">
                                <label htmlFor="surname" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Apellido</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.75002 1.75C5.88313 1.75 4.41669 3.20257 4.41669 4.9375C4.41669 6.67243 5.88313 8.125 7.75002 8.125C9.61691 8.125 11.0834 6.67243 11.0834 4.9375C11.0834 3.20257 9.61691 1.75 7.75002 1.75ZM2.91669 4.9375C2.91669 2.32319 5.10659 0.25 7.75002 0.25C10.3935 0.25 12.5834 2.32319 12.5834 4.9375C12.5834 7.55181 10.3935 9.625 7.75002 9.625C5.10659 9.625 2.91669 7.55181 2.91669 4.9375ZM10.4162 12.9684C8.64987 12.6772 6.85013 12.6772 5.08382 12.9684L4.87605 13.0027L4.75404 12.2627L4.87604 13.0027C2.94882 13.3204 1.5 15.0477 1.5 17.1115C1.5 17.7634 2.00549 18.25 2.57813 18.25H12.9219C13.4945 18.25 14 17.7634 14 17.1115C14 15.0477 12.5512 13.3204 10.624 13.0027L10.746 12.2627L10.624 13.0027L10.4162 12.9684ZM10.6602 11.4884L10.5382 12.2284L10.6602 11.4884L10.868 11.5226C13.5542 11.9655 15.5 14.349 15.5 17.1115C15.5 18.5455 14.3685 19.75 12.9219 19.75H2.57813C1.13147 19.75 0 18.5455 0 17.1115C0 14.349 1.94583 11.9655 4.63203 11.5226L4.83981 11.4884L4.96182 12.2284L4.83982 11.4884C6.7677 11.1705 8.73229 11.1705 10.6602 11.4884Z" fill="#6b7280"/>
                                        </svg>
                                    </span>
                                    <Field 
                                        type="text" 
                                        name="surname" 
                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                    />
                                </div>
                                <ErrorMessage name="surname" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.surname}</span>
                                )} />
                            </div>
                            
                            
                            <div className="my-4 px-[15px]">
                                <label htmlFor="email-address" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Email</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </span>
                                    <Field 
                                        type="text" 
                                        name="username" 
                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                    />
                                </div>
                                <ErrorMessage name="username" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.username}</span>
                                )} />
                            </div>

                            <div className="my-4 px-[15px]">
                                <label htmlFor="password" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Contraseña</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                    </span>
                                    <Field 
                                        type="password" 
                                        name="password" 
                                        className="text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                        placeholder="" 
                                    />
                                </div>
                                <ErrorMessage name="password" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.password}</span>
                                )} /> 
                            </div>                           

                            <div className="my-4 px-[15px]">
                                <label htmlFor="speciality" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">Especialidad</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 17.1115C1 14.6984 2.69732 12.643 5.00404 12.2627L5.21182 12.2284C7.05892 11.9239 8.94108 11.9239 10.7882 12.2284L10.996 12.2627C13.3027 12.643 15 14.6984 15 17.1115C15 18.1545 14.1815 19 13.1719 19H2.82813C1.81848 19 1 18.1545 1 17.1115Z" stroke="#6b7280" strokeWidth="1.5"/>
                                            <path d="M12.0834 4.9375C12.0834 7.11212 10.2552 8.875 8.00002 8.875C5.74486 8.875 3.91669 7.11212 3.91669 4.9375C3.91669 2.76288 5.74486 1 8.00002 1C10.2552 1 12.0834 2.76288 12.0834 4.9375Z" stroke="#6b7280" strokeWidth="1.5"/>
                                            <path d="M9.78299 11.8889V14.1942C9.04067 14.4731 8.50732 15.2232 8.50732 16.1038V16.779C8.50732 17.1525 8.79253 17.4541 9.14501 17.4541C9.49748 17.4541 9.78269 17.1522 9.78269 16.779V16.1038C9.78269 15.7309 10.0685 15.4287 10.4204 15.4287C10.7722 15.4287 11.058 15.7313 11.058 16.1038V16.779C11.058 17.1525 11.3433 17.4541 11.6957 17.4541C12.0482 17.4541 12.3334 17.1522 12.3334 16.779V16.1038C12.3334 15.2232 11.8004 14.4731 11.0577 14.1942V11.8889H9.78209H9.78299Z" fill="#6b7280"/>
                                            <path d="M5.02886 14.2493C4.61281 14.4819 4.33337 14.9162 4.33337 15.4138C4.33337 16.1566 4.95583 16.7585 5.72468 16.7585C6.49353 16.7585 7.11598 16.1569 7.11598 15.4138C7.11598 14.9162 6.83622 14.4819 6.42049 14.2493V11.8889H5.02886V14.2493Z" fill="#6b7280"/>
                                        </svg>

                                    </span>
                                    <Field 
                                        type="text" 
                                        name="speciality" 
                                        className="bg-white text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                                        as="select"
                                    >
                                        <option value="-1"></option>
                                        {
                                            specialities && specialities.map((el) => (
                                                <option key={`speciality-${el.id}`} value={ el.id }>{ el.name }</option>
                                            ))
                                        }
                                    </Field>
                                </div>
                                <ErrorMessage name="speciality" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.speciality}</span>
                                )} /> 
                            </div>

                            <div className="mt-4 px-[15px]">
                                <label htmlFor="emailcountry" className="pt-3 pb-2 pl-2 block text-[16px] font-semibold text-[#1C1243]">País</label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-2xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                                        <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.78571C0 3.62779 3.35309 0.25 7.49809 0.25C11.6431 0.25 14.9962 3.62779 14.9962 7.78571C14.9962 9.80439 13.7463 12.3513 11.5163 15.3562L11.5116 15.3625L10.9746 16.0703C10.3202 16.9152 9.58595 17.803 8.77263 18.7337L8.76912 18.7377L8.0593 19.5383C7.91755 19.6982 7.71433 19.79 7.50066 19.7908C7.28699 19.7915 7.08315 19.7011 6.94031 19.5421C5.83624 18.3138 4.86291 17.1564 4.02157 16.0702L4.01695 16.0643L3.47985 15.3562C1.24984 12.3513 0 9.80439 0 7.78571ZM7.49809 1.75C4.18936 1.75 1.5 4.44835 1.5 7.78571C1.5 9.27782 2.47088 11.4789 4.68209 14.4591L5.20973 15.1547C5.881 16.0211 6.64328 16.9399 7.49726 17.9112L7.64317 17.7466C7.64374 17.746 7.6443 17.7453 7.64487 17.7447C8.4401 16.8346 9.15374 15.9714 9.78641 15.1547L10.3141 14.4591C12.5253 11.4789 13.4962 9.27783 13.4962 7.78571C13.4962 4.44835 10.8068 1.75 7.49809 1.75Z" fill="#6b7280"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.49811 5.68639C6.35946 5.68639 5.4364 6.60945 5.4364 7.7481C5.4364 8.88674 6.35946 9.8098 7.49811 9.8098C8.63676 9.8098 9.55981 8.88674 9.55981 7.7481C9.55981 6.60945 8.63676 5.68639 7.49811 5.68639ZM3.9364 7.7481C3.9364 5.78102 5.53103 4.18639 7.49811 4.18639C9.46518 4.18639 11.0598 5.78102 11.0598 7.7481C11.0598 9.71517 9.46518 11.3098 7.49811 11.3098C5.53103 11.3098 3.9364 9.71517 3.9364 7.7481Z" fill="#6b7280"/>
                                        </svg>
                                    </span>
                                    <Field 
                                        type="text" 
                                        name="country"
                                        className="bg-white text-base block w-full flex-1 rounded-r-2xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none"
                                        as="select"
                                    >
                                        <option value="-1"></option>
                                        {
                                            countries && countries.map((el) => (
                                                <option key={`country-${el.id}`} value={ el.id }>{ el.name }</option>
                                            ))
                                        }
                                    </Field>
                                </div>
                                <ErrorMessage name="country" component={() => (
                                    <span className="mx-1 mt-1 text-red-600" >{errors.country}</span>
                                )} />
                            </div>                                       
                        </div>

                        

                        <div className="mb-8 w-full bg-white">


                            <div className="mb-4 text-red-600 text-center">    
                                { invalidData ? 'Existen errores en los datos ingresados' : <span>&nbsp;</span> }
                            </div>
                            
                            <div className="px-[15px]">
                            { buttonSubmit }
                            </div>


                            <div className="mt-[10px] flex items-center justify-center">
                                <div className="text-base font-sans font-semibold">
                                    <a href="login" className="">¿Ya estás registrado?<span className="text-[#FF5555]"> Ingresá</span></a>
                                </div>
                            </div>
                            
                        </div>                        
                    </Form>
                )}
            </Formik>
        </div>
    </div>
  )
}