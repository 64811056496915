import React, { useRef, useCallback } from "react";

import Patient from "./Patient";

import SectionLoading from '../components/SectionLoading';
import SectionEmpty   from '../components/SectionEmpty';

const InfiniteList = ({
  name,
  data,
  hasMore,
  error,
  loading,
  isSearch,
  setPageNumber,
  handleClickPatient,
  totalPatients
}) => {

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );


  if (!data) {
    return <SectionLoading loading={ true } smaller={ true } />;
  }

  return (
    <>

      <div className="flex justify-center">
        {loading && isSearch && (
          <li className="flex items-center p-5">
            <div className="flex">
              <SectionLoading loading={ true } smaller={ true } />
            </div>
          </li>
        )}
      </div>

      { !error && !loading && !isSearch && !name && totalPatients == 0 ?

      <div className="flex justify-center">
      <SectionEmpty show title={ 'Aún no tienes pacientes creados.' } subtitle={ `Presiona el botón + para crear tu primer paciente` } fullscreen={ false } />
      </div>

      :
      !error ? (
        data.map((data, index) => {
          return data.map((d, i) => {
            if (10 === i + 1) {
              return (
                <div key={i} ref={lastBookElementRef}>
                  <Patient
                    key={d.id}
                    data={d}
                    button="Ver"
                    handleClickPatient={(id) => {
                      handleClickPatient(id);
                    }}
                  />
                </div>
              );
            } else {
              return (
                <Patient
                  key={i}
                  data={d}
                  button="Ver"
                  handleClickPatient={(id) => {
                    handleClickPatient(id);
                  }}
                />
              );
            }
          });
        })
      ) : (
        <div className="flex justify-center">
          <li className="flex items-center p-5">
            <div className="flex">
              <h2 className="font-sans font-gray-100">No se encontraron resultados</h2>
            </div>
          </li>
        </div>
      )}

      <div className="flex justify-center">
        {loading && !isSearch && (
          <SectionLoading loading={ loading } smaller={ true } />
        )}
      </div>

    </>
  );
};

export default InfiniteList;
