import React, { useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector}             from 'react-redux';
import { useNavigate }                        from 'react-router-dom';
import Avatar                                 from 'react-avatar';
import moment                                 from 'moment';
import 'moment/locale/es';

import { useAuth }     from "../components/AuthProvider";
import SectionEmpty    from '../components/SectionEmpty';
import SectionLoading  from '../components/SectionLoading';

import {setPageTitle, setShowHeader}                                    from '../redux/actions/layout';
import {getSchedule, getTags, changeSchemaRangeChange, getEventOptions} from '../redux/actions/offices';
import { cleanInfoPatient }                                             from '../redux/actions/patients';
import { apiCall }                                                      from "../redux/api";

import {getFullDate} from '../utils/dates';
import {getDate}     from '../utils/dates';
import session       from '../utils/session';

import getYears     from '../middlewares/getYears';
import convertToDni from '../middlewares/convertToDni';
import assignColor  from '../middlewares/assingColor';
import sendSwal     from '../middlewares/sendSwal';

import InfoAppointment from './InfoAppointment';

import TurnosSVG from '../assets/calendar.svg';

 export default function Events() { 
    
  const userData = session.getUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Storage = JSON.parse(localStorage.CurrentUrl);
 
    const date = new Date()

    const { setUrl, handleSaveAppoinmentData } = useAuth();

    const { professional: professionalData, secretary: secretaryData, offices } = session.getUser();

    const schedule = useSelector(state => state.offices.schedule);
    const totalEvents = useSelector(state => state.offices.totalEvents);
    const loading = useSelector(state => state.offices.isLoading);

    const extraDays = useSelector(state => state.offices.date);
    const schemaRange = useSelector(state => state.offices.schemaRange);

    const tags = useSelector(state => state.offices.tags);

    const [ loadCompleted, setLoadCompleted ]                 = useState(false);
    const [ appointment, setAppointment ]                     = useState(null);
    const [ eventsTodayPending, setEventsTodayPending ]       = useState([]);
    const [ eventsTodayFinished, setEventsTodayFinished ]     = useState([]);
    const [ eventsWeekPending, setEventsWeekPending ]         = useState([]);
    const [ eventsWeekFinished, setEventsWeekFinished ]       = useState([]);
    const [ showModal, setShowModal ]                         = useState(false);
    const [ dataModal, setDataModal ]                         = useState(null);
    const [ showStatesAppointment, setShowStatesAppointment ] = useState(false);
    const [ goPatientDetail, setGoPatientDetail ]             = useState(false);
    const [ goPatientCreate, setGoPatientCreate ]             = useState(null);
    const [ updateOK, setUpdateOK ]                           = useState(null);
    const [ fromState, setFromState ]                         = useState(false);

    const elRef            = useRef(null);
    const elRef2           = useRef(null);
    const elRefFinalizados = useRef(null);

    useEffect(() => {

      // Limpio por las dudas persistente de NUEVO TURNO
      handleSaveAppoinmentData(null);

      if(!tags) {
        dispatch(getTags());
      }
      dispatch(getEventOptions());
      
      if (loadCompleted) {
        console.log('getAllEvents 3 SI', loadCompleted);
        getAllEvents();
      } else {
        console.log('getAllEvents 3 NO');
      }
            
    }, []);

    useEffect(() => {
      dispatch(setPageTitle(getTitle()));
      dispatch(setShowHeader(true));
    }, [totalEvents]);

    useEffect(() => {
      if (!loading && !loadCompleted) {
        setLoadCompleted(true);
      }
    }, [loading]);

    useEffect(() => {
      console.log('updateOK', updateOK);
      if (updateOK) {
        setShowModal(false);
        setShowStatesAppointment(false);
        console.log('getAllEvents 2 NO');
        getAllEvents(); 
      }      
    }, [updateOK]);

    useEffect(() => {
      if (goPatientDetail) {
        navigate(`/patients/${Storage.id}`);
      }      
    }, [goPatientDetail]);

    useEffect(() => {
      if (goPatientCreate) {
        navigate(`/patients/new/${goPatientCreate}`);
      }      
    }, [goPatientCreate]);

    useEffect(() => {
      const today = moment().format('YYYY-MM-DD');

      let auxEvTodayPend = [];
      let auxEvTodayFin = [];
      let auxEvWeekPend = [];
      let auxEvWeekFin = [];

      if (schedule) {
        schedule.map((el, index) => {
          if (moment(el.date_from, 'YYYY-MM-DD hh-mm-ss').format('YYYY-MM-DD') == today) {
            if (el.state >= 50) {
              auxEvTodayFin.push(el);
            } else {
              auxEvTodayPend.push(el);
              auxEvWeekPend.push(el);
            }            
          } else {
            if (el.state >= 50) {
              auxEvWeekFin.push(el);
            } else {
              auxEvWeekPend.push(el);
            }            
          }                               
        });
      }

      setEventsTodayPending(auxEvTodayPend);
      setEventsTodayFinished(auxEvTodayFin);
      setEventsWeekPending(auxEvWeekPend);
      setEventsWeekFinished(auxEvWeekFin);

    }, [schedule]);

    useEffect(() => {

      if (loading) return;
      

      // Si la vista es la del día
      if (schemaRange.type == 'today') {
        if ((eventsTodayPending.length == 0) && (eventsTodayFinished.length > 0)) {
          if (!elRefFinalizados.current) return;
          if (!elRefFinalizados.current.checked) {
            elRefFinalizados.current.click();
          }
        } else {
          if (eventsTodayPending.length > 0) {
            if (elRef.current) {
             if (!elRef.current.checked) {
                elRef.current.click();
             }
            }          
          }
        }          
      }

      // Si la vista es la de la semana
      if (schemaRange.type == 'week') {
        if ((eventsWeekPending.length == 0) && ((eventsTodayFinished.length > 0) || (eventsWeekFinished.length > 0))) {            
          if (!elRefFinalizados.current) return;
          if (!elRefFinalizados.current.checked) {
            elRefFinalizados.current.click();
          }
        } else {
          if (eventsWeekPending.length > 0) {
            if (elRef2.current) {
              if (!elRef2.current.checked) {
                elRef2.current.click();
              }
            }          
          }
        }        
      }

    }, [elRef.current, elRef2.current, elRefFinalizados.current, eventsTodayPending, eventsTodayFinished, eventsWeekPending, eventsWeekFinished]);


    useEffect(() => {
      console.log('getAllEvents 1');
      getAllEvents();
      
    }, [schemaRange]);

    useEffect(() => {
      if (dataModal && dataModal.state != 50) {
        handleUpdateAppointment(true); 
      }

    }, [fromState]);

    const getAllEvents = () => {
      console.log('getAllEvents:::');
      const data = {
        initialDate: (schemaRange.init ? schemaRange.init : getDate()),
        lastDate: (schemaRange.finish ? schemaRange.finish : ((schemaRange.type == 'today') ? getDate() : getDate(7)))
      }
      dispatch(getSchedule(data));
    }

    const getTitle = () => {
      return (
        <div className="px-0 font-sans text-xl">
          Turnos
          {totalEvents > 0 && (
            <span>{` (${totalEvents})`}</span>
          )}
        </div>
      );
    };

    const onChangeRange = (type) => {
      const aux = {
        type: type,
        init: getDate(),
        finish: ((type == 'today') ? getDate() : getDate(7))
      }

      dispatch(changeSchemaRangeChange(aux));
    }

     const handleCloseAppointment = () => {
      setAppointment(null);
      dispatch(setShowHeader(true));
      dispatch(cleanInfoPatient());
      console.log('getAllEvents 4');
      getAllEvents();
    }

    const handleGoToNewAppointment = () => {
      navigate(`/appointments/new`);

    }

    const handleShowMenu = (e, show, data=dataModal) => {
      e.preventDefault();
      
      console.log('dataModal', data);

      setDataModal(data);
      setShowModal(show);  
    };

    const handleUpdateFromState = (e, show, data=dataModal) => {
      e.preventDefault();
      setDataModal(data);
      setFromState(!fromState) 
  
    };

    const handleCreatePat = (e, data) => {
      setUrl({event_id: data.id, type: 'create-pat', fromAppointmet: true});
      const objB64 = btoa(JSON.stringify(
        {
          name: data.patient_name,
          dni: data.patient_id_number,
          event_id: data.id,
          office_id: data.office_id
        }
      ));
      setGoPatientCreate(objB64);
    }

    const handleUpdateAppointment = (show) => {
      setShowStatesAppointment(show);
      setShowModal(false);

    }

    const handleClickMenu = (e, type) => {
      e.preventDefault();
      
      switch (type) {
        case 'update':
          handleUpdateAppointment(true);
          break;
        case 'patient':
          setUrl({id: dataModal.patient.id, fromAppointmet: true});
          setGoPatientDetail(true);
          break;
        case 'create-patient':
          setUrl({event_id: dataModal.id, type: 'create-pat', fromAppointmet: true});
          const objB64 = btoa(JSON.stringify(
            {
              name: dataModal.patient_name,
              dni: dataModal.patient_id_number,
              event_id: dataModal.id,
              office_id: dataModal.office_id
            }
          ));
          setGoPatientCreate(objB64);
          break;
      }
  
      handleShowMenu(e, false);
    }

    const handleUpdate = async (data) => {
      
      try {
          const auxRes = await apiCall(`/api/events/${dataModal.id}/state`, data, null, 'PATCH');
          if (auxRes?.status == 200) {  
              setUpdateOK(moment());
                    
          } else {
              toast.error("No se pudo finalizar la modificación del turno. ERROR: " + auxRes.statusText);
          }
      } catch (error) {
          alert("error")          
      }

    };

    const handleClickUpdateAppointment = (e, type, dataModal) => {
      e.preventDefault();

      let mainText = "";
      let textConfirmButtom = "Confirmar";
      let textCancelButtom ="Cancelar";
      let data = {};

      if (secretaryData) {
        data.professional_id = dataModal.professional_id
      }
  
      switch (type) {
        case 'Confirmado':
          data.state = 1;
          handleUpdate(data);
          break;
        case 'Sala de Espera':
          data.state = 2;
          handleUpdate(data);
          break;
        case 'Atendido':
          mainText = '¿Cambiar el estado del turno a "Atendido"?'
          data.state = 50;

          let handleAttended = () => {
            handleUpdate(data);
          }
          sendSwal(mainText, textConfirmButtom, textCancelButtom, () => handleAttended() , true);
          break;
        case 'No Asistio':
          mainText = `¿Cambiar el estado del turno a "No Asistio"?. No podrás modificarlo luego`
          data.state = 80;

          let handleDidNotAssist = () => {
            handleUpdate(data);
          }

          sendSwal(mainText, textConfirmButtom, textCancelButtom, () => handleDidNotAssist() , true);
          break;
        case 'Cancelar':
          mainText = `¿Estás seguro que quieres cancelar el turno?. No podrás modificarlo luego`
          data.state = 90;

          let handleCancel = () => {
            handleUpdate(data);
          }

          sendSwal(mainText, textConfirmButtom, textCancelButtom, () => handleCancel() , true);
          break;
      }
    }

    const showStatus = (event_state, hour = null, patientId = null) => {
      if (hour) {
        hour = moment(hour, "YYYY-MM-DD hh-mm-ss").format("HH:mm")
      }
      return (
        <div className={`font-sans text-[${patientId ? event_state.color : "#000"}] font-semibold`}>
          <div className="inline ml-1 text-base">{ hour }<span className='text-xs'> hs.</span></div>
          <div className={`badge inline text-sm text-white ml-2 bg-[${patientId ? event_state.color : "#89C4F4"}] rounded-xl border-transparent`}>
            {
              patientId
              ?
                event_state.id == 3 ? "Demanda esp." : event_state.value
              :
                "Crear ficha"
            }
          </div>
        </div>        
      )
    }

    const showItem = (el, index) => {
      let patientName = '';
      let colorAvatar = ''
      if (el.patient) {
        colorAvatar = assignColor(el.patient.id_number);
        patientName = el.patient.surname+', '+el.patient.name;
      } else {
        patientName = el.patient_name;
      }

      return(
        <>
          <div className='font-sans bg-white p-1 border shadow-lg rounded-lg p-3 mb-3'>
            <div
              className="flex justify-between items-center"
            >
              <div className='flex flex-col w-full'> 
                <div className='flex'>
                  <div className="flex w-full"> 
                    {el.patient?.photo ? (
                      <img
                        src={`https://cdn.meducar.com/small/${el.patient.photo}`}
                        width="50"
                        height="50"
                        className="rounded-full max-h-50px mt-2"
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.meducar.com/img/patient_notfound.png";
                          event.onerror = null;
                        }}
                        alt={el.patient.photo}
                        style={{ maxHeight: 50 }}
                      />
                    ) : (
                      <>
                      {/* TODO_Nano: Revisar porqué dejó de andar patient_name */}
                      {patientName &&
                      <div className="flex flex-col">
                        <Avatar className="mt-1" name={ patientName } size="50" round={true} color={colorAvatar} />
                      </div>
                                        
                      }
                      </>

                    )}

                    <div className="flex flex-col ml-2">
                      {patientName && 
                      <span className="text-lg font-bold text-black truncate w-full capitalize">
                        { patientName.toLowerCase() }
                      </span>
                      }

                      <span className="text-sm text-black truncate w-full">
                        <div className='inline'>
                          {
                            el.patient
                            ?
                              el.patient.id_number && (`DNI: ${convertToDni(el.patient.id_number)}`)
                            :
                              el.patient_id_number && (`DNI: ${convertToDni(el.patient_id_number)}`)
                          }
                        </div>
                        {el.patient && <div className='inline mx-1'>/</div>}
                        <div className='inline'>
                          {
                            el.patient
                            ?
                              el.patient.born_date && (getYears(el.patient.born_date))
                            :
                              el.patient_born_date && (getYears(el.patient_born_date))
                          }
                        </div>                    
                      </span>
                      <div>
                        <span className="text-sm text-gray-400 truncate w-full">
                          {el.ctype ? el.ctype.name : 'Consulta general'}
                        </span>               
                      </div>
                    </div>
                  
                  </div>
                  <button className="flex flex-col items-center" onClick={(e) => handleShowMenu(e, true, el) }>
                    <i
                      className="fa fa-ellipsis-h text-[#A29EB6]"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </button>
                </div> 
                
                <div className='border-t border-gray-300 mt-2 pt-4'>
                  <div className='flex justify-between items-center'>

                    <div>
                      <img src={ TurnosSVG } className="inline mr-1 -mt-1" />
                      <div className='inline text-black font-semibold pt-2 text-base'>
                        { moment(el.date_from, "YYYY-MM-DD hh-mm-ss").format("DD/MM/YYYY") }
                      </div>                    
                    </div>

                    <button onClick={(e) => el.patient ? handleUpdateFromState(e, true, el) : handleCreatePat(e, el) }>
                      { showStatus(el.state_color, el.date_from, el.patient?.id) }
                    </button>

                  </div>
                </div>

              </div>    
            </div>

            {el.tags && el.tags?.length > 0 && (
              <div className="flex text-sm text-center pt-2 pl-1">
                {el.tags.map((element) => (
                  <div
                    style={{
                      backgroundColor: '#A29EB6', // element.tag.color,
                      border: "none",
                    }}
                    key={element.tag.id}
                    className="mr-1 mb-1 badge uppercase rounded"
                  >
                    {element.tag.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )
    }

    const showEmptyDay = () => {
      if (professionalData) {
        return (
          <SectionEmpty 
            show 
            title={ 'Estás Libre!' } 
            subtitle={` ${ eventsTodayFinished.length > 0 ? 'Ya no tienes más pacientes en el día' : 'No tienes pacientes agendados para hoy...' } `} 
            fullscreen={ false }
          />
        )
      } else {
        return (
          <SectionEmpty 
            show 
            title={ 'Todos libres!' } 
            subtitle={` ${ eventsTodayFinished.length > 0 ? 'Los profesionales ya no tienen más pacientes en el día' : 'Los profesionales no tienen pacientes agendados para hoy...' } `} 
            fullscreen={ false }
          />
        )                   
      }
    }

    const orderByProf = (events) => {
      let auxEvents = []
     
      events.map((el) => {
        if (!auxEvents[el.professional_id]) {
          auxEvents[el.professional_id] = []
        }
        auxEvents[el.professional_id].push(el)
      })
      return auxEvents
    }

    const showNextTodayPending = () => {
      return(
        professionalData
          ?
            eventsTodayPending && (
              eventsTodayPending.map((el, index) => (
                <div key={`events-key-pending-${index}`}>
                  { showItem(el, index) }
                </div>                        
              ))
            )                          
          :
            eventsTodayPending && (
              orderByProf(eventsTodayPending).map((el, index) => (
                <div key={`events-key-pending-${index}`}>                  
                  {
                    el.map((el1, index1) => (
                      <>
                        {
                          index1 == 0 && (
                            <div className='flex justify-between'>
                              <div className="text-base text-[#979797] p-2 pt-5 font-sans font-bold">{ el1.professional.title } { el1.professional.name } { el1.professional.surname } <span className='text-xs font-normal'>({ `${el.length} turno${el.length > 1 ? 's' : ''}`})</span></div>
                              <img className="w-[25px] h-[25px] rounded-full mt-4 mr-3" alt="Perfil" src={`${el1.professional.user.photo ? el1.professional.user.photo : 'https://cdn.meducar.com/img/profile_notfound.png'}`} />
                            </div>
                          )
                        }
                        <div key={`events-key-pending-k${index1}`}>
                          <div>
                            { showItem(el1, index1) }
                          </div>
                        </div>  
                      </>                      
                    ))
                  }
                </div>                                        
              ))
            )
      )
    }

    const showTodayFinished = () => {
      return(
        professionalData
          ?
            eventsTodayFinished && (
              eventsTodayFinished.map((el, index) => (
                <div key={`events-key-pending-${index}`}>
                  { showItem(el, index) }
                </div>                        
              ))
            )                          
          :
            eventsTodayFinished && (
              orderByProf(eventsTodayFinished).map((el, index) => (
                <div key={`events-key-pending-${index}`}>                  
                  {
                    el.map((el1, index1) => (
                      <>
                        {
                          index1 == 0 && (
                            <div className='flex justify-between'>
                              <div className="text-base text-[#979797] p-2 pt-5 font-sans font-bold">{ el1.professional.title } { el1.professional.name } { el1.professional.surname } <span className='text-xs font-normal'>({ `${el.length} turno${el.length > 1 ? 's' : ''}`})</span></div>
                              <img className="w-[25px] h-[25px] rounded-full mt-4 mr-3" alt="Perfil" src={`${el1.professional.user.photo ? el1.professional.user.photo : 'https://cdn.meducar.com/img/profile_notfound.png'}`} />
                            </div>

                          )
                        }
                        <div key={`events-key-pending-k${index1}`}>
                          <div>
                            { showItem(el1, index1) }
                          </div>
                        </div>  
                      </>                      
                    ))
                  }
                </div>                                        
              ))
            )
      )
    }

    const showNextWeekPending = () => {
      return(
        professionalData
          ?
            eventsWeekPending && (
              eventsWeekPending.map((el, index) => (
                <div key={`events-key-pending-${index}`}>
                  { showItem(el, index) }
                </div>                        
              ))
            )                          
          :
            eventsWeekPending && (
              orderByProf(eventsWeekPending).map((el, index) => (
                <div key={`events-key-pending-${index}`}>                  
                  {
                    el.map((el1, index1) => (
                      <>
                        {
                          index1 == 0 && (
                            <div className='flex justify-between'>
                              <div className="text-base text-[#979797] p-2 pt-5 font-sans font-bold">{ el1.professional.title } { el1.professional.name } { el1.professional.surname } <span className='text-xs font-normal'>({ `${el.length} turno${el.length > 1 ? 's' : ''}`})</span></div>
                              <img className="w-[25px] h-[25px] rounded-full mt-4 mr-3" alt="Perfil" src={`${el1.professional.user.photo ? el1.professional.user.photo : 'https://cdn.meducar.com/img/profile_notfound.png'}`} />
                            </div>
                          )
                        }
                        <div key={`events-key-pending-k${index1}`}>
                          <div>
                            { showItem(el1, index1) }
                          </div>
                        </div>  
                      </>                      
                    ))
                  }
                </div>                                        
              ))
            )
      )
    }


    return (
       <>
        <div className="mt-[80px] pt-[0px] bg-white px-2">
          <div className="font-sans flex justify-between pb-4 px-4">
            <div>
              <button className={`${schemaRange.type == 'today' ? 'bg-[#3388FF] text-white' : 'text-gray-400'} font-semibold text-base w-20 h-10 rounded-xl`} onClick={ () => onChangeRange('today') }>Día</button>
              <button className={`${schemaRange.type == 'week' ? 'bg-[#3388FF] text-white' : 'text-gray-400'} font-semibold text-base w-20 h-10 rounded-xl`} onClick={ () => onChangeRange('week') }>Semana</button>
            </div>
            <button onClick={handleGoToNewAppointment} className={`${showModal || showStatesAppointment ? 'hidden' : 'bg-[#3388FF] text-white text-lg w-14 h-14 rounded-full fixed right-[30px] bottom-[105px] z-50 shadow-xl'}`}>
              <div className="pl-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" viewBox="0 0 42 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line><line x1="30" y1="5" x2="30" y2="13"></line><line x1="26" y1="9" x2="34" y2="9"></line></svg>
              </div>
            </button>
          </div>

          {
            (loadCompleted ) && (
              <div className="max-h-full max-w-md mx-auto bg-white overflow-scroll md:max-w-lg">
                <div className="md:flex">
                  <div className="w-full p-0">                    
                    
                    { 
                      schemaRange.type == "today" 
                      ?

                        !loading && eventsTodayPending.length < 1 
                        ? 
                          showEmptyDay()
                        : 
                          <div className="">
                            <div className="collapse collapse-arrow">
                              <input ref={el => {elRef.current = el}} type="checkbox" className="peer" /> 
                              <div style={{paddingLeft: "15px"}} className="font-sans ml-0 collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg text-gray-400">
                                Próximos turnos
                              </div>
                              <div className="collapse-content p-0 mb-[20px!important]"> 
                                { showNextTodayPending() }
                              </div>
                              {!(eventsTodayFinished.length > 0 || eventsWeekFinished.length > 0) &&
                                <div className='h-[100px]'></div>
                              }
                            </div>
                          </div>
                      : 
                        !loading && eventsWeekPending.length < 1 
                        ?
                          <SectionEmpty show title={ 'No hay turnos' } subtitle={ `No tienes turnos tomados hasta el ${getFullDate(extraDays)}` } fullscreen={ false } />
                        : 
                          professionalData || true
                          ?
                            <div className="">
                              <div className="collapse collapse-arrow">
                                <input ref={el => {elRef2.current = el}} type="checkbox" className="peer" /> 
                                <div style={{paddingLeft: "15px"}} className="font-sans ml-0 collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg text-gray-400">
                                  Próximos turnos
                                </div>
                                <div className="collapse-content p-0 mb-[80px!important]"> 

                                <div>
                                  { showNextWeekPending() }
                                </div>   
                                <div className='h-[100px]'></div>                     
                                </div>
                              </div>
                            </div>
                          :
                            <div>Turnos ordenados por profesional 1</div>                      
                    }
                    

                    { schemaRange.type == "today" &&
                    (eventsTodayFinished.length > 0 || eventsWeekFinished.length > 0 ? (
                      <div>
                        <div className="collapse collapse-arrow">
                          <input ref={el => {elRefFinalizados.current = el}} type="checkbox" className="peer" /> 
                          <div style={{paddingLeft: "15px"}} className="font-sans ml-0 collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg text-gray-400">
                            Turnos finalizados
                          </div>
                          <div className="collapse-content p-0 mb-[80px!important]"> 

                          <div>
                            { showTodayFinished() }
                          </div>                        

                            {
                              eventsWeekFinished && (
                                eventsWeekFinished.map((el, index) => (
                                  <div key={`events-key-finished-${index}`}>
                                    { showItem(el, index) }
                                  </div>                        
                                ))
                              )
                            }
                          </div>
                          <div className='h-[100px]'></div>
                        </div>
                      </div>
                    
                    ) : (
                      <div></div>                      
                    ))
                  }  
                                        
                  </div>
                </div>
              </div>
            )
          }

          <SectionLoading loading={ loading } smaller={ true }/>
          
        </div>

         {
          appointment && (
           <div
             className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
             id="my-modal"
           >
             <InfoAppointment
               appointment={appointment}
               handleCloseAppointment={handleCloseAppointment}
             />
           </div>
          )
         }

        {showModal && (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={(e) => handleShowMenu(e, false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-64 py-1 px-2 mx-auto bg-white rounded-xl shadow-lg">
                        <div className="sm:flex">
                            <div className="text-center sm:ml-4 sm:text-left">
                                <div className="font-sans items-center gap-2 my-3 sm:flex text-[#1C1243]">
                                  
                                  <div className="text-base leading-tight mb-4">
                                    <span>Turno asignado a</span>
                                    <br />
                                    {dataModal.patient_id? 
                                      <>
                                        <span className='font-bold'>{dataModal.patient.surname}, {dataModal.patient.name}</span>                                            
                                      </>
                                    :
                                      <>
                                        <span className='font-bold'>{dataModal.patient_name}</span>
                                        <span className='text-xs'>{` `}(sin ficha)</span>
                                      </>
                                    }
                                    <br />
                                    {`el día `}<span className='font-bold'>{ moment(dataModal.date_from, "YYYY-MM-DD hh-mm-ss").format("DD/MM/YYYY")}</span>{`.`}
                                  </div>

                                  {
                                    dataModal.observations && (
                                      <div className='mb-4'><span className="font-bold">Motivo de consulta: </span>{ dataModal.observations }</div>
                                    )
                                  }
                                  
                                  <hr className="mb-4"></hr>

                                  {
                                    dataModal.patient_id 
                                    ?
                                      <>
                                        {
                                          dataModal.state != 50 && (
                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickMenu(e, 'update')
                                              }
                                            >
                                              <div className="flex">
                                                <img className="mr-2" src={ TurnosSVG } alt="Turnos" />
                                                <div className="font-sans text-base">Modificar Estado Turno</div>
                                              </div>                                      
                                            </button>
                                          )                                                            
                                        }
                                        <button
                                          className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                          onClick={(e) =>
                                            handleClickMenu(e, 'patient')
                                          }
                                        >
                                          <div className="flex">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-5 h-5 mr-2 mt-1"
                                                viewBox="0 0 16 20"
                                                fill="currentColor"
                                            >
                                                <path 
                                                    fillRule="evenodd" 
                                                    clipRule="evenodd" 
                                                    d="M7.75002 1.75C5.88313 1.75 4.41669 3.20257 4.41669 4.9375C4.41669 6.67243 5.88313 8.125 7.75002 8.125C9.61691 8.125 11.0834 6.67243 11.0834 4.9375C11.0834 3.20257 9.61691 1.75 7.75002 1.75ZM2.91669 4.9375C2.91669 2.32319 5.10659 0.25 7.75002 0.25C10.3935 0.25 12.5834 2.32319 12.5834 4.9375C12.5834 7.55181 10.3935 9.625 7.75002 9.625C5.10659 9.625 2.91669 7.55181 2.91669 4.9375ZM10.4162 12.9684C8.64987 12.6772 6.85013 12.6772 5.08382 12.9684L4.87605 13.0027L4.75404 12.2627L4.87604 13.0027C2.94882 13.3204 1.5 15.0477 1.5 17.1115C1.5 17.7634 2.00549 18.25 2.57813 18.25H12.9219C13.4945 18.25 14 17.7634 14 17.1115C14 15.0477 12.5512 13.3204 10.624 13.0027L10.746 12.2627L10.624 13.0027L10.4162 12.9684ZM10.6602 11.4884L10.5382 12.2284L10.6602 11.4884L10.868 11.5226C13.5542 11.9655 15.5 14.349 15.5 17.1115C15.5 18.5455 14.3685 19.75 12.9219 19.75H2.57813C1.13147 19.75 0 18.5455 0 17.1115C0 14.349 1.94583 11.9655 4.63203 11.5226L4.83981 11.4884L4.96182 12.2284L4.83982 11.4884C6.7677 11.1705 8.73229 11.1705 10.6602 11.4884Z" 
                                                    fill="black"
                                                />
                                            </svg>
                                            <div className="font-sans text-base">Ver paciente</div>
                                          </div>                                      
                                        </button>
                                      </>
                                    : 
                                      <>
                                        <button
                                          className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                          onClick={(e) =>
                                            handleClickMenu(e, 'create-patient')
                                          }
                                        >
                                          <div className="flex">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-5 h-5 mr-2 mt-1"
                                                viewBox="0 0 16 20"
                                                fill="currentColor"
                                            >
                                                <path 
                                                    fillRule="evenodd" 
                                                    clipRule="evenodd" 
                                                    d="M7.75002 1.75C5.88313 1.75 4.41669 3.20257 4.41669 4.9375C4.41669 6.67243 5.88313 8.125 7.75002 8.125C9.61691 8.125 11.0834 6.67243 11.0834 4.9375C11.0834 3.20257 9.61691 1.75 7.75002 1.75ZM2.91669 4.9375C2.91669 2.32319 5.10659 0.25 7.75002 0.25C10.3935 0.25 12.5834 2.32319 12.5834 4.9375C12.5834 7.55181 10.3935 9.625 7.75002 9.625C5.10659 9.625 2.91669 7.55181 2.91669 4.9375ZM10.4162 12.9684C8.64987 12.6772 6.85013 12.6772 5.08382 12.9684L4.87605 13.0027L4.75404 12.2627L4.87604 13.0027C2.94882 13.3204 1.5 15.0477 1.5 17.1115C1.5 17.7634 2.00549 18.25 2.57813 18.25H12.9219C13.4945 18.25 14 17.7634 14 17.1115C14 15.0477 12.5512 13.3204 10.624 13.0027L10.746 12.2627L10.624 13.0027L10.4162 12.9684ZM10.6602 11.4884L10.5382 12.2284L10.6602 11.4884L10.868 11.5226C13.5542 11.9655 15.5 14.349 15.5 17.1115C15.5 18.5455 14.3685 19.75 12.9219 19.75H2.57813C1.13147 19.75 0 18.5455 0 17.1115C0 14.349 1.94583 11.9655 4.63203 11.5226L4.83981 11.4884L4.96182 12.2284L4.83982 11.4884C6.7677 11.1705 8.73229 11.1705 10.6602 11.4884Z" 
                                                    fill="black"
                                                />
                                            </svg>
                                            <div className="font-sans text-base">Crear ficha</div>
                                          </div>                                      
                                        </button>
                                      </>
                                  }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
        )}

        {showStatesAppointment && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={() => handleUpdateAppointment(false)}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-64 py-1 px-2 mx-auto bg-white rounded-xl shadow-lg">
                              <div className="sm:flex">
                                  <div className="font-sans text-center sm:ml-4 sm:text-left">
                                      <div className="items-center gap-2 my-3 sm:flex text-[#1C1243]">
                                      
                                      <div className="text-base leading-tight mb-4">
                                        <span>Turno asignado a</span>
                                        <br />
                                        {dataModal.patient_id? 
                                          <>
                                            <span className='font-bold'>{dataModal.patient.surname}, {dataModal.patient.name}</span>                                            
                                          </>
                                        :
                                          <>
                                            <span className='font-bold'>{dataModal.patient_name}</span>
                                            <span className='text-xs'>{` `}(sin ficha)</span>
                                          </>
                                        }
                                        <br />
                                        {`el día `}<span className='font-bold'>{ moment(dataModal.date_from, "YYYY-MM-DD hh-mm-ss").format("DD/MM/YYYY")}</span>{`.`}
                                      </div>

                                      <div className="text-base mb-4">Estado actual: <span className={`font-semibold text-[${dataModal.state_color.color}]`}>{dataModal.state_color.value}</span> </div>
                                        
                                        <hr className="mb-4"></hr>

                                        <div className="font-sans text-lg font-bold mb-2">Nuevo estado</div>
                                          <div className='px-[44px] text-center'>
                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickUpdateAppointment(e, 'Confirmado', dataModal)
                                              }
                                            >
                                              <div className="flex items-center">
                                                <div className="mr-2 h-[9px] w-[9px] rounded-full bg-[#34d4be]"></div>
                                                <div className="font-sans text-lg">Confirmado</div>
                                              </div>                                      
                                            </button>
                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickUpdateAppointment(e, 'Sala de Espera', dataModal)
                                              }
                                            >
                                              <div className="flex items-center">
                                                <div className="mr-2 h-[9px] w-[9px] rounded-full bg-[#3badf2]"></div>
                                                <div className="font-sans text-lg">Sala de Espera</div>
                                              </div>                                      
                                            </button>
                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickUpdateAppointment(e, 'Atendido', dataModal)
                                              }
                                            >
                                              <div className="flex items-center">
                                                <div className="mr-2 h-[9px] w-[9px] rounded-full bg-[#444444]"></div>
                                                <div className="font-sans text-lg">Atendido</div>
                                              </div>                                      
                                            </button>
                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickUpdateAppointment(e, 'No Asistio', dataModal)
                                              }
                                            >
                                              <div className="flex items-center">
                                                <div className="mr-2 h-[9px] w-[9px] rounded-full bg-[#FF7373]"></div>
                                                <div className="font-sans text-lg">No Asistio</div>
                                              </div>                                      
                                            </button>

                                            <button
                                              className="w-full p-1.5 flex-1 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
                                              onClick={(e) =>
                                                handleClickUpdateAppointment(e, 'Cancelar', dataModal)
                                              }
                                            >
                                              <div className="flex items-center">
                                                <div className="mr-2 h-[9px] w-[9px] rounded-full bg-[#FF0000]"></div>
                                                <div className="font-sans text-lg">Cancelar</div>
                                              </div>                                      
                                            </button>
                                          </div>                                                                                        
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </>
              )}
       </>
     );
     
 }