const removeAreaCode = (phoneNumber, countryCode) => {
    
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    if (cleanedPhoneNumber.startsWith(countryCode)) {

      const phoneNumberWithoutAreaCode = cleanedPhoneNumber.slice(countryCode.length);
      
      return phoneNumberWithoutAreaCode;

    } else {

      return cleanedPhoneNumber;
    }

  }

  export default removeAreaCode;