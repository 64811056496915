import React from 'react';

export default function SectionLoading({ loading, smaller = false }) {
    return (
        <>
            {
                (loading && !smaller) && (
                    <div className="h-screen bg-white">
                        <div className="flex items-center justify-center py-20">
                            <div className="my-20 w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                        </div>
                    </div>
                )
            }

            {
                (loading && smaller) && (
                    <>
                        <div className="flex items-center justify-center py-2">
                            <div className="w-8 h-8 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                        </div>
                    </>
                )
            }
        </>        
    );
}