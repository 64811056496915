import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const OfficesWrapper = (offices) => {

  const list = offices.offices;

  return (
    <>
      <Carousel 
        className="-mx-4"
        showStatus={0}>

        { list.map((el, index) => (
          <div key={`office-${index}`} className="min-h-[180px] my-2 mx-4 max-w-md mx-auto text-left">
            <div className="min-h-[140px] bg-white rounded-xl shadow-lg text-xl px-6 py-4 font-sans">
                <div className="font-bold text-base">{el.name}</div>
                <div className="text-gray-400 text-xs leading-5"><span className="text-gray-700">{el.address}</span></div>
                <div className="text-gray-400 text-xs leading-5">Teléfono: <span className="text-gray-700">{el.phone ? el.phone : '--'}</span></div>
                <div className="text-gray-400 text-xs leading-5">Whatsapp: <span className="text-gray-700">{el.whatsapp ? el.whatsapp : '--'}</span></div>
                <div className="text-gray-400 text-xs leading-5">Email: <span className="text-gray-700">{el.email ? el.email : '--'}</span></div>
              </div>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default OfficesWrapper;