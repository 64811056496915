import  { get } from 'lodash';

export const userData = state => get(state, 'login.loginData.data');
export const userToken = state => get(state, 'login.loginData.token');



export const patientsResult = state => get(state, 'patients.patientResults');
export const patientsCurrentPage = state => get(state, 'patients.current_page');
export const patientsLastPage = state => get(state, 'patients.last_page');

export const prescriptionsResult = state => get(state, 'prescriptions.prescriptionResults');
export const prescriptionsCurrentPage = state => get(state, 'prescriptions.current_page');
export const prescriptionsLastPage = state => get(state, 'prescriptions.last_page');