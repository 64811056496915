import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { toast } from 'react-toastify';

import { apiCall } from "../redux/api";

import CoverLoading from '../components/CoverLoading';

import moment from 'moment';
import 'moment/locale/es';

export const NewConsultation = () => {
  
  const navigate = useNavigate();
  const params = useParams();

  const [ loading, setLoading ] = useState(false);
  const [ timeStart, setTimeStart ] = useState(new Date());
  const [ data, setData ] = useState({
    event_observations: '',
    observations: ''
  });
  const [ patientName, setPatientName ] = useState(null);
  const [ todayEvent, setTodayEvent ] = useState(null);

  useEffect(() => {

    // Busco turnos del día
    if (params?.id) {
      getEventDay(params.id);
    }

    if (params?.data) {
      console.log('params', params);
      try {
        const auxData = JSON.parse(atob(params.data));
        console.log(auxData);
        if (auxData.name) {
          setPatientName(auxData.name);        
        }        
      } catch(ex) {
        //Nada
      }      
    }

  }, []);

  const handleChange = (e) => {
    console.log('handleChange', e.target.value);    
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleClick = () => {
    if (params?.id) {

      if (!data.observations) {
        // toast.error("Debe completar al menos un dato.");
        toast.error("Debe completar las observaciones.");
        return;
      }

      saveConsultation(params.id);   
    } else {
      toast.error("No se identificó el paciente para la evolución.");
    }
  }
  const handleClose = () => {
    if (params?.id) {
      navigate(`/patients/${params.id}`);
    } else {
      navigate('/patients');
    }
  }

  const getEventDay = async (patientId) => {

    const auxRes = await apiCall(`/api/patients/${patientId}/pending-events`, null, null, 'GET');
    console.log(auxRes);
    if (auxRes?.status == 200) {      
      if (auxRes.data?.data[0]) {
        console.log(moment(auxRes.data.data[0].date_from).format('YYYY-MM-DD'));
        console.log(moment().format('YYYY-MM-DD'));
        // Verifico si el primer evento pendiente es del día de hoy
        if (moment(auxRes.data.data[0].date_from).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
          setTodayEvent(auxRes.data.data[0]);
        }
      }
    }

  }

  const saveConsultation = async (patientId) => {

    const end = new Date();
    const timeElapsed = end - timeStart; // Tiempo en milisegundos
    console.log(`Tiempo transcurrido: ${timeElapsed} ms`);

    setLoading(true);

    let dataPost = {
      // event_observations: data.event_observations,
      text: data.observations,
      duration: timeElapsed/1000,
    }
    if (todayEvent) {
      dataPost.consultation_type_id = todayEvent.consultation_type_id;
      dataPost.event_id = todayEvent.id;
    }
    // console.log(dataPost); return;

    const auxRes = await apiCall(`/api/patients/${patientId}/consultations`, dataPost, null, 'POST');
    
    setLoading(false);

    if (auxRes?.status == 200) {   
      navigate(`/patients/${patientId}/hc`);   
    } else {
      toast.error("No se pudo crear la evolución. ERROR: " + auxRes.statusText);
    }
  }

  return (
    <div>
      <CoverLoading loading={ loading } />

      <div className="z-[10000] bg-[#1C124350] absolute top-0 h-screen w-screen font-sans">
        <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white'}} className="mt-[40px] sm:mt-0 overflow-x-auto h-full">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="flex justify-between px-4 sm:px-0">
                <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900"></h3>
                <h3 className="p-4 text-lg text-center font-bold leading-6 text-[#1C1243]">Evolucionar Paciente</h3>
                  <h3 onClick={ () => handleClose() } className="mt-2 p-4 text-lg text-center font-medium leading-6 text-gray-900">
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                    </svg>              
                  </h3>                
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0 px-4">

              <div className='text-base mb-2'>
                {
                  patientName && (
                    <div className=''><span className="font-semibold">Paciente:</span> { patientName }</div>
                  )
                }
              </div>

              {
                todayEvent 
                ?
                  <div className='text-sm'>
                    <div className='text-base font-semibold'>Datos del turno</div>
                    <div className=''><span className="font-semibold">Día y hora:</span> <span className='capitalize'>{ moment(todayEvent.date_from).format('dddd D - H:mm') }</span> hs</div>
                    <div className=''><span className="font-semibold">Práctica:</span> { todayEvent.ctype ? todayEvent.ctype.name : 'General' }</div>
                    {
                      todayEvent.observations && (
                        <div className=''><span className="font-semibold">Motivo de consulta:</span> { todayEvent.observations }</div>
                      )
                    }
                    
                  </div>
                :
                  <></>
              }

              <hr className='mt-4'/>

              <div className="mb-6">

                {/* <div className="pt-4">
                  <label htmlFor="eventObservations" className="text-base font-semibold">
                    Motivo de consulta <small className='text-xs text-gray-400 font-thin'> - Ingresado al crear el turno</small>
                  </label>
                </div>
                <textarea 
                  id="eventObservations" 
                  className="textarea-style"
                  name="event_observations"
                  value={data.event_observations}
                  onChange={(e) => handleChange(e)}
                  rows="3"
                  disabled={false}
                /> */}
              
                <div className="pt-4">
                  <label htmlFor="observations" className="text-base font-semibold">
                    Observaciones
                  </label>
                </div>
                <textarea 
                  id="observations" 
                  className="textarea-style"
                  name="observations"
                  value={data.observations}
                  onChange={(e) => handleChange(e)}
                  rows="8"
                  disabled={false}
                />
                
              </div>

              <div className="mt-4">
                <button onClick={handleClick} className="submit-button">{(loading) ? "Guardando..." : 'Guardar'}</button>
              </div>

            </div>
          </div>
        </div>        
      </div>
    </div>
  )
}