import { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate }   from "react-router-dom";
import { useSelector } from "react-redux";
import useRecorder from "../hooks/useRecorder";
import useFormat from "../hooks/useFormat";
import { useAudio } from "../components/AudioProvider";
import sendSwalWithCancel from '../middlewares/swalWithCancel'

import '../styles/audio.css'

export const UploadAudioRecord = () => {

    const MAX_TIME = 120; // Segundos

    const navigate   = useNavigate();
    const StorageId = JSON.parse(localStorage.CurrentUrl);
    const StorageAudio = localStorage.getItem("MeducarPWA::audioRecord");

    const patient = useSelector(state => state.patients.patient);
    const audioRef = useRef(null);
    const  {handleStoreAudio}  = useAudio();
    let [audioURL, setAudioURL, isRecording, startRecording, stopRecording, audioData] = useRecorder();

    const isRecordingRef = useRef(isRecording);
    const [elapsed, setElapsed] = useState(0); 

    useEffect(() => {
      isRecordingRef.current = isRecording;
    }, [isRecording]);
    useEffect(() => {
      if (elapsed > MAX_TIME) {
        stopRecording();
        setElapsed(0);
      }
    }, [elapsed]);

    useEffect(() => {

      if (StorageAudio != "null") {
        let mainText = "Deseas descartar el audio que has grabado?"
        sendSwalWithCancel(mainText, "Confirmar", "Cancelar", () => localStorage.setItem("MeducarPWA::audioRecord", null) , () => navigate(`/patients/${StorageId.id.id}/hc/upload-audio/manage-audio`), true);
      }

      const intervalId = setInterval(() => {
        if (isRecordingRef.current) {
          // Usar la versión con función de la actualización del estado
          setElapsed((elapsed) => elapsed + 1);          
        }
      }, 1000);
  
      // Limpiar el intervalo cuando el componente se desmonte
      return () => clearInterval(intervalId);

    }, []);
  
    const thereIsAudio = useMemo(() => {
    let _thereIsAudio = false;

    if (audioURL.length >0) {
      _thereIsAudio = true;
    }
    
    return _thereIsAudio;
  }, [ audioURL ])

  useEffect((id) => {
    if (thereIsAudio) {
      handleGoToManageAudio(id)
    }
  }, [thereIsAudio]);

    const handleClose = (id) => {
        if (StorageId.id.section == 'hc') {
            navigate(`/patients/${id}/hc`);
          } else if (StorageId.id.section == 'prescriptions') {
            navigate(`/patients/${id}/prescriptions`)
          } else if (StorageId.id.section == 'info') {
            navigate(`/patients/${id}/info`);
          } else if (StorageId.id.section == 'events') {
            navigate(`/patients/${id}/events`);
          }
    }

    const handleGoToManageAudio = async (id) => {

      const b64Audio = await audioData();
      console.log(b64Audio);

      const data = {
        audio: b64Audio.base64,
        type: b64Audio.mimeType
      }
      handleStoreAudio(data)
      navigate(`/patients/${id}/hc/upload-audio/manage-audio`);

    }

  return (
    <>
      <div className="flex flex-col h-screen justify-between items-center bg-black font-sans">
        
        <div className='flex w-full justify-between items-center mt-6 py-4 px-4'>
          <div className="text-lg text-white">
            <span className="">
              Evolución de Paciente:{' '}
              <div className="font-bold capitalize">
                {patient.surname}, {patient.name}
              </div>
            </span>
          </div>
          <button className='flex justify-center items-center min-w-10 size-10' onClick={() => handleClose(StorageId.id.id)}>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        
        {!isRecording && !thereIsAudio ? 
          <>
          <div className="equalizer">
            <div className="barStoped"></div>
            <div className="barStoped"></div>
            <div className="barStoped"></div>
            <div className="barStoped"></div>
            <div className="barStoped"></div>
          </div>
          <div className='text-white'>Comience a grabar</div>
          <div className='text-white'>
            <svg width="35" height="22" viewBox="0 0 13 8" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04289 0.792893C1.43342 0.402369 2.06658 0.402369 2.45711 0.792893L6.75 5.08579L11.0429 0.792893C11.4334 0.402369 12.0666 0.402369 12.4571 0.792893C12.8476 1.18342 12.8476 1.81658 12.4571 2.20711L7.45711 7.20711C7.26957 7.39464 7.01522 7.5 6.75 7.5C6.48478 7.5 6.23043 7.39464 6.04289 7.20711L1.04289 2.20711C0.652369 1.81658 0.652369 1.18342 1.04289 0.792893Z" fill="white"/>
            </svg>
          </div>
          </>
          : isRecording && !thereIsAudio &&
          <>
            <div className="equalizer">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <div className='text-white text-center'>Grabando...<br /><span className='text-lg'>{ useFormat(elapsed) } / 2:00</span></div>
            <div className='text-white' >
              <svg width="35" height="22" viewBox="0 0 13 8" fill="black" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04289 0.792893C1.43342 0.402369 2.06658 0.402369 2.45711 0.792893L6.75 5.08579L11.0429 0.792893C11.4334 0.402369 12.0666 0.402369 12.4571 0.792893C12.8476 1.18342 12.8476 1.81658 12.4571 2.20711L7.45711 7.20711C7.26957 7.39464 7.01522 7.5 6.75 7.5C6.48478 7.5 6.23043 7.39464 6.04289 7.20711L1.04289 2.20711C0.652369 1.81658 0.652369 1.18342 1.04289 0.792893Z" fill="black"/>
              </svg>
            </div>
          </>

        }
        

        <div className="pt-4 mb-[80px] flex justify-around w-full">
            
            {isRecording && !thereIsAudio?
            <button className={`${isRecording ? 'bg-red-600' : 'bg-[#374151]'} h-20 w-20  rounded-full text-white m-1 py-1 px-2 pointer shadow-xl`} onClick={() => stopRecording()} disabled={!isRecording}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" stroke="white" fill="white" class="w-8 h-8 ml-4">
                <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
              </svg>
            </button>
            : !thereIsAudio &&
            <button className={`${isRecording ? 'bg-[#374151]' : 'bg-[#3388FF]'} h-20 w-20 rounded-full text-white m-1 py-1 px-2 pointer shadow-xl`} onClick={() => startRecording()} disabled={isRecording}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" class="w-10 h-10 ml-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
              </svg>
            </button>
            }
        </div>

      </div>
  </>
  )
}