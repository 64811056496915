import React, { useEffect, useRef, useState } from 'react';

export default function CanvasPDF({ url }) {
  const [ pdfJS, setPdfJS ] = useState(null);
  const [ pdf, setPdf ] = useState(null);
  const stateRefPDF = useRef(pdf);
  const [ error, setError ] = useState('');
  const [ numPages, setNumPages ] = useState(0);

  const [ currentPage, setCurrentPage ] = useState(1);
	const canvasRef = useRef(null);

  // We import this here so that it's only loaded during client-side rendering.
  // let pdfJS = null; // await import('pdfjs-dist/build/pdf');
  // pdfJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';

  // let pdf = null; // await pdfJS.getDocument('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf').promise;

	useEffect(() => {
		(async function () {
      if (url) {
                
        setTimeout(() => {
          if (!stateRefPDF.current) {
            setError('No se pudo cargar la receta');
          }          
        }, 10000);
        
        // We import this here so that it's only loaded during client-side rendering.
        let auxPdfJS = await import('pdfjs-dist/build/pdf');
        auxPdfJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
        let auxPdf = await auxPdfJS.getDocument(url).promise;
        
        setPdf(auxPdf);
        setPdfJS(auxPdfJS);                
      }			
		})();
	}, [url]);

  useEffect(() => {
    if (pdf) {
      stateRefPDF.current = pdf;
      setNumPages(pdf.numPages);
      changePage(currentPage);
    }
  }, [pdf]);

  const changePage = async (currentPage) => {
    const page = await pdf.getPage(currentPage);
    const viewport = page.getViewport({ scale: 1.5 });

    // Prepare canvas using PDF page dimensions.
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context.
    const renderContext = { canvasContext, viewport };
    page.render(renderContext);
  }

  const handleChangePage = (page) => {
    if (page < 1) return;
    if (page >numPages) return;

    changePage(page);
    setCurrentPage(page);
  } 

	return (
    <div className='mt-4'>
      {/* <div>{ currentPage } / { numPages }</div> */}
      <div className={`btn btn-xs rounded-full fixed top-[calc(50vh-8px)] right-1 ${(currentPage>=numPages) ? 'hidden' : ''}`} onClick={ () => handleChangePage(currentPage + 1) }>{`>`}</div>
      <div className={`btn btn-xs rounded-full fixed top-[calc(50vh-8px)] left-1 ${(currentPage<=1) ? 'hidden' : ''}`} onClick={ () => handleChangePage(currentPage - 1) }>{`<`}</div>
      
      {
        error
        ?
          <div className='flex w-full h-60 text-lg justify-center items-center text-red-400'>
            <div>{ error }</div>
          </div>
        :
          pdf
          ?
            <canvas ref={canvasRef} style={{ width: 'calc(100vw - 16px)', minHeight: 'calc(100vw - 16px)', overflowY: 'scroll', border: '1px solid #DDD' }} />
          :
            <div className='flex w-full h-60 text-lg justify-center items-center'>
              <div>Cargando receta...</div>
            </div>
      }
      
    </div>
  )
}