import React, {useContext, Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ReceiptIcon from '@material-ui/icons/Receipt';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { Link } from 'react-router-dom';
import { useStylesApp } from '../appStyles';

// Icons
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';

import {openSidebar} from '../redux/actions/layout';
import { Typography } from '@material-ui/core';

export default function Sidebar({history}) {
    const dispatch = useDispatch();
    const [state, setState] = React.useState(false);
    const sidebarOpen = useSelector(state => state.layout.sidebar);
    const pageTitle = useSelector(state => state.layout.title);
    const classes = useStylesApp();

    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, open });
    };

    const handleSidebar = () => {
        dispatch(openSidebar(sidebarOpen))
    }

    const handlePage = (route) => {
        handleSidebar();
  
    }


    const pages = [
        {
            name: "Principal",
            link: "",
            icon: <HomeIcon />
        },
        {
            name: "Pacientes",
            link: "patients",
            icon: <PeopleIcon />
        },
        {
            name: "Turnos",
            link: "appointments",
            icon: <AssignmentIcon />
        },
        {
            name: "Recetas",
            link: "prescriptions",
            icon: <ReceiptIcon />
        },
        {
            name: "Mi perfil",
            link: "settings",
            icon: <SettingsIcon />
        },
        {
            name: "Cerrar sesion",
            link: "logout",
            icon: <AssignmentIcon />
        }
    
    ]




    return (
        <Drawer
            variant="persistent"
            anchor="left"
            className={ classes.drawer }
            classes={{
                paper: clsx(classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose),
            }}
            open={ sidebarOpen } >
            
            <div className={classes.drawerHeader}>
                <IconButton onClick={ handleSidebar }>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>

                {pages.map((page, index) => (
                    <Fragment key={index} >
                        <div className={pageTitle === page.name ? classes.sidebarCurrentOption : null}>
                            <Link to={page.link} className='link' onClick = {handleSidebar} key={page.name}>
                                <ListItem button>
                                    <ListItemIcon>
                                        {page.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={page.name} />
                                </ListItem>
                            </Link>                      
                        </div>
                        {page.name === 'Profesionales' ? (
                            <Fragment>
                                <Divider />
                                <ListSubheader inset>Saved reports</ListSubheader>
                            </Fragment>
                            ) : null}      
                    </Fragment>
                
))}

                {/* <Link to="/" className={`link ${(current === 'home' ? 'link-current' : '')}`} onClick={ handleDrawer}>
                    <ListItem button>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItem>
                </Link>

                <Link to="/staff" className="link" onClick={ handleDrawer }>
                    <ListItem button>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profesionales" />
                    </ListItem>
                </Link>                 */}
                  
            {/*</List>*/}


            {/*<List>*/}

                

                {/* <Link to="/events" className="link" onClick={ handleDrawer }>
                  <ListItem button>
                      <ListItemIcon>
                          <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Mis Turnos" />
                  </ListItem>
                </Link>

                <Link to="/profile" className="link" onClick={ handleDrawer }>
                    <ListItem button>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Mis Datos" />
                    </ListItem>
                </Link>

                <Link to="/settings" className={`link ${(current === 'home' ? 'link-current' : '')}`} onClick={ handleDrawer }>
                    <ListItem button>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configuración" />
                    </ListItem>
                </Link> */}
                
            </List>
        </Drawer>
    );
}