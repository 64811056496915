import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate }                        from "react-router-dom";
import { useDispatch, useSelector }           from "react-redux";

import { getPatients }                 from "../redux/actions/patients";
import { setPageTitle, setShowHeader } from "../redux/actions/layout";
import { getTags }                                               from "../redux/actions/offices";
import { patientsCurrentPage, patientsLastPage, patientsResult } from "../redux/selectors";

import InfiniteList   from "../components/InfiniteList";
import SectionLoading from '../components/SectionLoading';
import SectionEmpty   from '../components/SectionEmpty';
import { useAuth }    from "../components/AuthProvider";

import session from "../utils/session";

export default ({ loggedIn }) => {

  const { token, setUrl } = useAuth();
  const navigate          = useNavigate();
  const dispatch          = useDispatch();
  const inputSearch       = useRef(null);

  const patients        = useSelector((state) => patientsResult(state));
  const totalPatients   = useSelector((state) => state.patients.totalPatients);
  const dataCurrentPage = useSelector((state) => patientsCurrentPage(state));
  const lastPage        = useSelector((state) => patientsLastPage(state));
  const loading         = useSelector((state) => state.patients.isLoading);
  const tags            = useSelector((state) => state.offices.tags);
  
  const [ firstLoad, setFirstLoad ]         = useState(true);
  const [ loadCompleted, setLoadCompleted ] = useState(false);
  const [pageNumber, setPageNumber]         = useState(1);
  const [name, setName]                     = useState("");
  const [hasMore, setHasMore]               = useState(false);
  const [isSearch, setIsSearch]             = useState(false);

  const { professional: professionalData, secretary: secretaryData, offices } = session.getUser();

  let time = 0;

  useEffect(() => {

    if (!offices || (!professionalData && !secretaryData) ) {
      // TODO_G: Ver por qué el Cerrar Sesión viene a parar a Pacientes antes de ir a Login
      window.location.href = '/login';
      return;
    }

    if(offices.length == 0) {
      if (token) {
        if (professionalData) {
          navigate('/onboarding-one');
          return;
        } else {
          navigate('/login');
          return;
        }
      } else {
        navigate('/login');
        return;
      }    
    }

    setUrl({id:"" ,fromAppointmet: false});
  }, []);

  const items = useMemo(() => {
    if (!patients) return [];
    const _items = patients.filter(element => {
      return element !== null && element !== undefined;
    });
    return _items;
  }, [ patients ])

  const getData = (pageNumber = 1, newName = "", reset = false) => {

    let Data = {
      name: newName,
      limit: 50,
      pageNumber,
      reset
    };
    dispatch(getPatients(Data));

  };

  const getTitle = () => {
    return (
      <div className="px-0 font-sans text-xl">
        Pacientes
        {totalPatients > 0 && (
          <span>{` (${totalPatients})`}</span>
        )}
      </div>
    );
  };

  useEffect(() => {

      getData(1, '', true);
      dispatch(setPageTitle(getTitle()));

    if(!tags) {
      dispatch(getTags());
    }

  }, []);

  useEffect(() => {
    dispatch(setPageTitle(getTitle()));
    dispatch(setShowHeader(true));
    }, [totalPatients]);
  
  useEffect(() => {
    setHasMore(dataCurrentPage < lastPage);
  }, [patients]);

  useEffect(() => {
    if (pageNumber > 1) {
      if (patients.length > 0) {
        getData(pageNumber);
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    if (name !== "") {
      getData(1, name);
    }
  }, [name]);

  useEffect(() => {
    if (!loading && isSearch) {
      setIsSearch(false);
    }
    if (loading && !loadCompleted) {
      setLoadCompleted(true);
      setFirstLoad(false);
    }
  }, [loading]);

  const handleChange = (e) => {
    let finalValue = "";
    const value = e.target.value;
    if (time) clearTimeout(time);

    time = setTimeout(() => {
      finalValue = value.replace(/ /g, "_");
      if (finalValue == "") {
        getData();
        setIsSearch(false);
      } else {
        setIsSearch(true);
      }
      setName(finalValue);
    }, 250);
  };

  const handleClearName = () => {
    inputSearch.current.value = "";
    setName("");
    setIsSearch(false);
    getData();
  };

  //--- Patient Detail -->
  const handleClickPatient = (id) => {
    navigate(id.toString())
  };

  const handleGoToNewPatient = () => {

    navigate('/patients/new')
  }

  return (
    <>
      {
        loadCompleted && (
          <div className="mt-[80px] pt-[0px] bg-white px-2">
            <div className="flex pb-4 px-4">
              {items.length > 0 && <div className="grow">
                <input
                  ref={inputSearch}
                  type="text"
                  className="font-sans text-lg w-full h-12 rounded-[16px] focus:outline-none px-6 border border-gray-300"
                  placeholder="Buscar"
                  onChange={(e) => handleChange(e)}
                />
                {name ? (
                  <i
                    className="far text-lg fa-times-circle mt-3 -ml-8 text-red-400 cursor-pointer"
                    onClick={() => handleClearName()}
                  ></i>
                ) : (
                  <i className="fas text-lg fa-search mt-3 -ml-8 text-gray-300 cursor-pointer"></i>
                )}
              </div>}
              <div className="flex-none ml-2">
                <button onClick={() => handleGoToNewPatient()} className="bg-[#3388FF] text-white text-lg w-14 h-14 rounded-full fixed right-[30px] bottom-[105px] shadow-xl">
                <div className="pl-[15px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                </div>
                </button>
              </div>
            </div>

            <div className="max-h-full max-w-md mx-auto overflow-scroll md:max-w-lg mt-2 pb-[85px]">
              <div className="md:flex">
                <div className="w-full p-0">
                  <div className="relative">

                    { !isSearch && !loading && name && totalPatients > 0 && (
                      <div className="flex justify-center">
                        <div className="font-sans flex mb-2 text-gray-400">
                          {`${totalPatients} resultado${
                            totalPatients > 1 ? "s" : ""
                          } para la búsqueda`}
                        </div>
                      </div>
                    )}

                    { isSearch && loading && name && (
                      <div className="flex justify-center">
                        <div className="font-sans flex mt-2 text-gray-400">
                          Buscando{" "}
                          <span className="font-sans font-bold text-black ml-1">{name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <ul>
                    <InfiniteList
                      name={name}
                      data={items}
                      hasMore={hasMore}
                      setPageNumber={setPageNumber}
                      loading={ (loadCompleted && loading) }
                      isSearch={isSearch}
                      getData={getData}
                      handleClickPatient={(id) => { navigate(`${id.toString()}/info`); /* handleClickPatient(id) */} }
                      totalPatients={totalPatients}
                    />
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        )
      }      

      <SectionLoading loading={ (!loadCompleted && loading) }/>
        
      <SectionEmpty show={ (!firstLoad && loadCompleted && name == '' && items.length === 0) } title={ 'No hay pacientes' } />

      <SectionEmpty show={ (!firstLoad && loadCompleted && name != '' && totalPatients == 0) } title={ 'No se encontraron resultados' } subtitle={ `al buscar "${name}"` } />
      <SectionEmpty show={ (!firstLoad && !loadCompleted && name == '' && totalPatients == 0) } title={ 'No hay pacientes' } />

    </>
  );
};

