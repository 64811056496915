import { put, call, takeLatest } from 'redux-saga/effects'; 
import { OPEN_SIDEBAR_START, OPEN_SIDEBAR_COMPLETE } from '../../consts/actionTypes';






export function* openSidebar({payload}) {
    yield put({type: OPEN_SIDEBAR_COMPLETE, payload})
}






export default function* layout() {

    yield takeLatest(OPEN_SIDEBAR_START, openSidebar)
}
