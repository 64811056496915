import moment from 'moment';

const getYears = (date) => {
    let result = moment().diff(date, 'years');
    if (result < 1) {
      result = moment().diff(date, 'months');
      if (result == 1) {
        return result + ' mes';
      }
      return result + ' meses';
    } 
    if (result == 1) {
      return result + ' año';
    }
    return result + ' años';    
  }

  export default getYears;