import { apiCall } from "../redux/api";
import { toast }   from 'react-toastify';

const sendPrescriptionWhatsapp = async (id) => {
    
    const data = {
        id: id,
        type: 'whatsapp'
    }

    const auxRes = await apiCall(`/api/prescriptions/send`, data, null, 'POST');
    if (auxRes?.status != 200) {
        toast.error("No se pudo enviar la receta. ERROR: " + auxRes.statusText);
    } else if (auxRes?.status == 200) {
        toast.success("Envío de receta OK");
    }

};

export default sendPrescriptionWhatsapp;